
.field-type-vud-field {
  display: inline-block;
  width: 100%;
}

.field-type-vud-field .field-label{
  display: none;

}

.up-score, .down-score {
  display: inline-block;
  flex-grow: 1;
  text-align: center;
  height: 40px;
}

.up-label{
  font-size: 16px;
  color: #939393;
  font-weight: bold;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

.vote-thumb {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-size: 100% 100%;
}

.vote-thumb.up-active{
  background-image: url(../../images/svg/thumbs_up.svg);
}
.vote-thumb.up-inactive {
  background-image: url(../../images/svg/thumbs_up_off.svg);
}

.vud-widget-thumbs:hover .vote-thumb.up-inactive {
  background-image: url(../../images/svg/thumbs_up.svg);
}

.vud-widget-thumbs:hover .up-label, .up-label.up-active{
  color: #0a549a;
}

.vote-thumb.down-active,
.vote-thumb.down-inactive {
  /*background-image: url(../../images/svg/thumbs_down.svg);*/
}

.vud-widget .ajax-progress{
  display: none;
}

.vud-widget-helpful{
  display: flex;
  border-top: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
  padding: 20px 0;
  h4 {
    line-height: 40px;
    font-size: 20px;
    margin-right: 20px;
  }
  margin: 10px 0;
}

.btn-helpful {
  width: 70px;
  border: 1px solid #0179D1;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  color: #0179D1;
  border-radius: 3px;
}

.btn-helpful a{
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  color: #0179D1;
  width: 70px;
  height: 40px;
  display: inline-block;
  text-align: center;
}

.btn-helpful:hover, .btn-helpful.up-active{
  border: 1px solid #023C91;
  color: #023C91;
  a {
    color: #023C91;
  }
}

.btn-helpful:first-of-type{
  margin-right: 22px;
}

@media all and (min-width: 400px) {
  .vud-widget-helpful{
    h4 {
      margin-right: 24px;
    }
  }

  .btn-helpful, .btn-helpful a {
    width: 76px;
  }

  .btn-helpful:first-of-type{
    margin-right: 24px;
  }
}

@media all and (min-width: 640px) {
  .up-current-score, .down-current-score {
    display: inline-block;
    text-align: left;
    font-size: 18px;
    vertical-align: 60%;
    margin-top:0;
  }

  .vud-widget-helpful{
    padding: 24px 0;
    h4 {
      font-size: 24px;
      margin-right: 30px;
    }
  }

  .btn-helpful, .btn-helpful a {
    width: 100px;
    font-size: 18px;
  }

  .btn-helpful:first-of-type{
    margin-right: 30px;
  }
}

@media all and (min-width: 1024px) {
  .vud-widget-helpful{
    padding: 30px 0;
  }
}
