
/**
Styles for the autocomplete react component.
*/
.mobile-expanding-autocomplete-container
.mobile-expanding-autocomplete-inner{
    width: 100%;
    > div, input {
      width: 100%;

    }
},

.mobile-expanding-autocomplete-container
  .mobile-expanding-autocomplete-inner{
    > div {
    input{
       border: $grey_border;
       height: 50px;
       color: $input_grey;
       font-size: 16px;
       background: url(../../images/map/icons/search.svg) 10px 50%/23px 23px no-repeat;
       background-color: $white;
       padding: 0 35px;
    }

  }
  .autocomplete-items{
    font-size: 16px;
    color: $body_text;
    line-height: 2;
    padding-left: 12px;
    margin: 20px 0;
    z-index: 9;
    div {
      margin: 6px 0;
    }
  }
  .autocomplete-items:empty{
    height: 0;
    margin: 0;
  }

  .cancel-autocomplete, .cancel-autocomplete-icon{
    display: none;
  }
}

.autocomplete-no-results{
  font-size: 16px;
  color: $body_text;
  line-height: 1.2;
  padding: 10px 12px;
  margin-top: 12px;
}

.school-registration-modal-inner{
  background-color: $white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 44px;
  h2{
    font-size: 24px;
    font-weight: normal;
    line-height: 1.25;
    margin: 20px 0;
    span {
      font-weight: bold;
    }
  }
  p{
    font-size: 16px;
    color: $body_text;
    line-height: 1.5;
    margin: 10px 0 16px;
  }
}

@media all and (min-width: 768px){
  .mobile-expanding-autocomplete-container{
    position: relative;
  }
  .mobile-expanding-autocomplete-container
    .mobile-expanding-autocomplete-inner{
      width: 260px;
      position: absolute;
      > div {
      input{
         height: 34px;
         padding: 0 35px;
         width: 260px;
      }
    }
    .autocomplete-items{
      width: 100%;
      position: absolute;
      background-color: $white;
      margin-top: 0;
      border-bottom: 1px solid #d5d5d5;
      border-left: 1px solid #d5d5d5;
      border-right: 1px solid #d5d5d5;
      box-shadow: 0 5px 12px 0 rgba(0,0,0,0.50);
      max-height: 250px;
      overflow-y: scroll;
    }

  }
  .autocomplete-no-results{
    top: 22px;
    width: 260px;
    position: absolute;
    background-color: $white;
    border-bottom: 1px solid #d5d5d5;
    border-left: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    box-shadow: 0 5px 12px 0 rgba(0,0,0,0.50);
  }
}

@media all and (min-width: 1024px){
  .mobile-expanding-autocomplete-container
    .mobile-expanding-autocomplete-inner{
      width: 400px;
      > div {
      input{
         height: 40px;
         width: 400px;
         margin-top: 6px;
      }
    }
  }

  .autocomplete-no-results{
    top: 34px;
    width: 400px;
  }
}

@media all and (min-width: 1280px){
  .mobile-expanding-autocomplete-container
    .mobile-expanding-autocomplete-inner{
      width: 450px;
      > div {
      input{
         height: 50px;
         width: 450px;
      }
    }
  }

  .autocomplete-no-results{
    width: 450px;
    top: 44px;
  }
}

/**
Mobile only
*/
@media all and (max-width: 767px) {

  .mobile-expanding-autocomplete-container.expanded{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    z-index: 20;
    padding: 0 10px;
    overflow-y: scroll;
    .mobile-expanding-autocomplete-inner{
      display: flex;
      position: relative;
      .cancel-autocomplete, .cancel-autocomplete-icon{
        display: block;
      }
      .cancel-autocomplete{
        font-size: 16px;
        line-height: 1.5;
        color: $input_grey;
        padding-left: 10px;
        margin-top: 12px;
      }

      .cancel-autocomplete-icon{
        position: absolute;
        width:20px;
        height: 20px;
        background: url(../../images/svg/cancel_x.svg) 0 0/100% 100% no-repeat;
        right: 10px;
        top: 15px;
        
      }

      .autocomplete-items{
          line-height: 20px;
         > div {
           margin: 12px;
         }
      }

      .location-autocomplete{
        .autocomplete-items{
         > div {
           background: no-repeat url(../../images/map/icons/search.svg) 2px 50%/20px 20px;
         }
        }
      }
      .centre-name-autocomplete{
        .autocomplete-items{
          > div {
            background: no-repeat url(../../images/map/icons/map_pin.svg) 2px 50%/20px 20px;
          }
         }
      }
    }

  }

  #school-ambassadors-autocomplete{
    .mobile-expanding-autocomplete-container.expanded{
      padding-top: 20px;
    }
  }


}
