.article-accordion-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
  h3.category-header {
    font-style: normal;
    font-weight: 700;
    font-size: 32px !important;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: #02295d;

    @media (max-width: 1023px) {
      font-size: 24px !important;
      line-height: 32px;
    }
    @media (min-width: 1024px) {
      font-size: 32px;
      line-height: 36px;
    }
  }
  .accordion {
    .js-accordion-item {
      .js-accordion-trigger {
        .accordion__heading {
          font-style: normal;
          font-weight: 700;
          color: #01285e;

          @media (max-width: 1439px) {
            font-size: 18px;
            line-height: 24px;
          }
          @media (min-width: 1440px) {
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }
}
.content.mobile-side-padding.ml-mr-0 {
  margin-left: 0;
  margin-right: 0;
}

.Article-page {
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #02295d;
    margin: 16px auto !important;
    font-family: "filson-soft", Arial;

    @media (max-width: 1023px) {
      font-size: 32px;
      line-height: 40px;
      max-width: 100%;
    }
    @media (min-width: 1024px) and (max-width: 1919px) {
      font-size: 48px;
      line-height: 52px;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      max-width: 700px;
    }
    @media (min-width: 1440px) and (max-width: 1919px) {
      max-width: 800px;
    }
    @media (min-width: 1920px) {
      font-size: 72px;
      line-height: 76px;
    }
  }
  h4 {
    margin-top: 32px;
    a {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #01285e;

      @media (max-width: 1023px) {
        font-size: 14px;
        line-height: 22px;
      }
      @media (min-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
      span {
        display: none;
      }
    }
  }
  .subheader-text {
    .field {
      padding-top: 0;
      .field-items {
        .field-item {
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #070707;

            @media (max-width: 1023px) {
              font-size: 20px;
              line-height: 28px;
            }
            @media (min-width: 1024px) {
              font-size: 24px;
              line-height: 36px;
            }
          }
        }
      }
    }
    .field-type-text-with-summary {
      padding-top: 0;
    }
  }
  .adaptable-content-container.no-top-padding {
    padding-top: 0px;
    margin-bottom: 16px;
  }
  .header-image-container {
    @media (max-width: 767px) {
      max-width: 100%;
      margin: 32px 0px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      max-width: 100%;
      margin: 32px 34px;
    }
    @media (min-width: 1024px) and (max-width: 1439px) {
      max-width: 100%;
      margin: 32px 46px;
    }
    @media (min-width: 1440px) {
      max-width: 1280px;
      margin: 32px auto;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .adaptable-content-container {
    .contents-generating-section {
      .contents-items-container {
        .contents-generating-item {
          a.btn-blue {
            padding: 10px 24px;
            width: fit-content;
            background: #023c91;
            box-shadow: 0px 2px 8px rgba(2, 60, 145, 0.25);
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            margin-top: 1rem;
            margin-bottom: 1rem;
            @media (max-width: 1023px) {
              margin: 1rem auto;
            }
          }
          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            color: #02295d;
            @media (max-width: 1023px) {
              font-size: 24px;
              line-height: 32px;
            }
            @media (min-width: 1024px) {
              font-size: 32px;
              line-height: 36px;
            }
          }
          .contents-generating-item-body {
            p {
              margin-bottom: 0;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 32px;
              color: #070707;
              a {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 32px;
                text-decoration-line: underline;
                color: #015cda;
                background: transparent;
                @media (max-width: 1023px) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                }
              }
              @media (max-width: 1023px) {
                font-size: 16px;
                line-height: 24px;
              }
              @media (min-width: 1024px) {
                font-size: 18px;
                line-height: 32px;
              }
            }

            h4 {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              color: #02295d;

              @media (max-width: 1023px) {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
  .resources-listings-section {
    hr.overline {
      display: none;
    }
  }
  .max-width-page {
    .resources-listings-section {
      .curriculum-listings-container {
        .long-activities-row-block {
          margin-left: 0;
          margin-right: 0;

          .long-activities-row-viewport {
            .long-activities-row-inner {
              .activity-block {
                &:first-of-type {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.article-type-page-class {
  margin-top: 3.5rem;
  .long-activities-row-block {
    margin-left: 0;
    margin-right: 0;
    a.round-scroller-button {
      display: none;
    }
    .long-activities-row-viewport {
      min-height: 390px;
      height: auto;

      .long-activities-row-inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: unset;

        .activity-block {
          margin-left: 0;
          @media (max-width: 639px) {
            width: 100%;
          }
          @media (min-width: 640px) and (max-width: 767px) {
            width: 48%;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            width: 31%;
            margin-right: 16px;
          }
          @media (min-width: 1024px) and (max-width: 1919px) {
            width: calc(25vw - 41px);
            margin: 0px 10px;
          }
          @media (min-width: 1920px) {
            width: 425px;
            min-width: 400px;
            margin: 0 16px;
          }
          &:nth-child(odd) {
            @media (min-width: 640px) and (max-width: 767px) {
              margin-left: 0 !important;
            }
          }
          &:nth-child(even) {
            @media (min-width: 640px) and (max-width: 767px) {
              margin-right: 0 !important;
            }
          }
        }
        @media (min-width: 640px) and (max-width: 767px) {
          justify-content: space-between;
        }
      }
    }
  }
}

.auskick-activity-list-section-header.article-category-header-new a {
  float: right;
  margin-top: 21px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #023c91;
}

.article-type-page-class {
  .long-activities-row-block {
    .long-activities-row-viewport {
      .long-activities-row-inner {
        .activity-block {
          .activity-footer {
            .resource-text p {
              height: 56px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

.article-type-page-class
  .long-activities-row-block
  .long-activities-row-viewport
  .long-activities-row-inner
  .activity-block
  .activity-footer
  .resource-text
  p {
  height: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article-category-header-new .category-header {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #02295d;
}
@media screen (min-width: 1440px) {
  .article-category-header-new .category-header {
    font-size: 32px;
    line-height: 36px;
  }
}
@media screen (max-width: 540px) {
  .auskick-activity-list-section-header.article-category-header-new {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.auskick-activity-list-section-header.article-category-header-new {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

@media (min-width: 1920px) {
  .all-categories-section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
  }
}

@media (min-width: 640px) {
  .all-categories-section {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.all-categories-section {
  padding: 0 20px;
}
.all-categories-section h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #02295d;
  margin-bottom: 25px;
}

.all-categories-section {
  .content {
    .view-all-category-section-in-article {
      .view-content {
        .item-list {
          ul {
            li {
              list-style: none;
              margin: 0;
              .views-field.views-field-title {
                .field-content {
                  display: none;
                }
              }

              .views-field.views-field-field-category-display {
                position: relative;
                .views-label.views-label-field-category-display {
                  display: none;
                }
                .field-content {
                  display: flex;
                  flex-wrap: nowrap;
                  width: 100%;
                  @media (min-width: 640px) {
                    overflow: hidden;
                  }
                  @media (max-width: 639px) {
                    overflow: auto;
                  }
                  &::-webkit-scrollbar {
                    height: 0;
                  }
                }
                .entity .entity-paragraphs-item.paragraphs-item-category {
                  margin: 8px;
                }
                .content {
                  .field.field-name-field-image {
                    .field-label {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.all-categories-section
  .content
  .view.view-all-category-section-in-article
  .view-content
  .item-list
  ul
  li
  .views-field.views-field-title
  .views-field.views-field-field-category-display
  .field-content
  .entity.entity-paragraphs-item
  .content
  .field.field-name-field-image
  .field-label
  .field-items
  .field-item.even
  img {
  width: 437.25px;
  height: 245.96px;
  object-fit: cover;
}

.all-categories-section
  .content
  .view.view-all-category-section-in-article
  .view-content
  .item-list
  ul
  li
  .views-field.views-field-title
  .views-field.views-field-field-category-display
  .field-content
  .entity.entity-paragraphs-item
  .content
  .field.field-name-field-image
  .field.field-name-field-resource-link
  .field-label {
  display: none;
}

.all-categories-section
  .content
  .view.view-all-category-section-in-article
  .view-content
  .item-list
  ul
  li
  .views-field.views-field-title
  .views-field.views-field-field-category-display
  .field-content
  .entity.entity-paragraphs-item
  .content
  .field.field-name-field-image
  .field-label
  .field-items
  .field-item.even
  .field.field-name-field-resource-link.field-type-link-field.field-label-above
  .field.field-name-field-category-title
  .field-label {
  display: none;
}
.all-categories-section
  .content
  .view.view-all-category-section-in-article
  .view-content
  .item-list
  ul
  li
  .views-field.views-field-title
  .views-field.views-field-field-category-display
  .field-content
  .entity.entity-paragraphs-item
  .content
  .field.field-name-field-image
  .field-label
  .field-items
  .field-item.even
  .field.field-name-field-resource-link.field-type-link-field.field-label-above
  .field.field-name-field-category-title
  .field-label
  .field-items
  .field-item.even {
  display: none;
}

.all-categories-section {
  .content {
    .view.view-all-category-section-in-article {
      .view-content {
        .item-list {
          ul {
            padding: 0;
            li {
              .views-field.views-field-field-category-display {
                .field-content {
                  transform: translate3d(0px, 0px, 0px);
                  transition: transform 500ms ease 0s;
                  .activity-block.resource-item {
                    text-align: left;
                    box-sizing: border-box;
                    margin: 0 0 10px;
                    padding: 0;
                    position: relative;
                    left: 0;
                    /* transform: translate3d(0px, 0px, 0px);
                    transition: transform 500ms ease 0s; */
                    transition: 0.5s;

                    .activity-footer {
                      a {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        color: #02295d;
                        text-transform: capitalize;
                      }
                    }
                    a {
                      .activity-image-container.resource-image {
                        @media (min-width: 640px) and (max-width: 1023px) {
                          width: 270px;
                        }
                        @media (max-width: 1023px) {
                          width: 270px;
                          /* height: 152px; */
                        }
                        @media (min-width: 1024px) and (max-width: 1279px) {
                          width: calc(25vw - 33px);
                          width: calc(25vw - 33.33333px);
                        }
                        @media (min-width: 1024px) and (max-width: 1439px) {
                          /* height: 180px; */
                        }
                        @media (min-width: 1440px) and (max-width: 1919px) {
                          /* height: 240px; */
                        }
                        @media (min-width: 1280px) and (max-width: 1919px) {
                          width: calc(25vw - 45px);
                        }
                        @media (min-width: 1920px) {
                          min-width: 458px;
                          width: 458px;
                          /* height: 258px; */
                        }
                        margin-bottom: -20px;
                        position: relative;
                        overflow: hidden;

                        img {
                          height: 100%;
                          max-width: 100%;
                          min-width: 100%;
                          vertical-align: middle;
                          object-fit: cover;

                          @media (min-width: 640px) {
                            height: auto;
                            position: relative;
                            transform: translateZ(0) scale(1);
                            transition: transform 0.25s ease-in-out;
                            width: 100%;
                            max-width: 100%;
                            min-width: 100%;
                            vertical-align: middle;

                            &::before {
                              content: " ";
                              display: block;
                              padding-bottom: calc(65.8% - 16px);
                            }
                            &::after {
                              align-items: center;
                              background-color: #f8f8f8;
                              content: " " attr(alt);
                              display: flex;
                              font-weight: 700;
                              height: 100%;
                              justify-content: center;
                              left: 0;
                              position: absolute;
                              top: 0;
                              width: 100%;
                            }
                          }
                        }
                      }
                    }
                    .field-name-field-resource-link {
                      margin: 10px 0;
                    }
                    .field.field-name-field-category-title.field-type-entityreference.field-label-above {
                      .field-label {
                        display: none;
                      }
                      .field-items {
                        display: none;
                      }
                    }
                    @media (min-width: 640px) and (max-width: 1023px) {
                      width: 270px;
                      box-sizing: border-box;
                      margin-right: 20px;
                      padding: 0;
                    }
                    @media (max-width: 1023px) {
                      box-sizing: content-box;
                      flex: 0 0 auto;
                      float: left;
                      width: 270px;
                      padding-right: 20px;
                      margin: 0 0 20px;
                    }
                    @media (min-width: 1024px) and (max-width: 1279px) {
                      width: calc(25vw - 33px);
                      width: calc(25vw - 33.33333px);
                      margin-right: 20px;
                    }
                    @media (min-width: 1280px) and (max-width: 1919px) {
                      width: calc(25vw - 45px);
                      margin-right: 30px;
                    }
                    @media (min-width: 1920px) {
                      min-width: 458px;
                      width: 458px;
                      margin-right: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 1980px) {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 639px) {
  button#previous {
    display: none !important;
  }
  button#next {
    display: none !important;
  }
}

@media (min-width: 640px) {
  button#previous {
    background: #023c91;
    height: 80px;
    width: 46px;
    color: #fff;
    position: absolute;
    top: 35%;
    left: 0;
    transform: translateY(-50%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button#previous:hover {
    background-color: #0452c3;
  }
  button#next:hover {
    background-color: #0452c3;
  }
  button#previous::after {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: "";
    display: inline-block;
    height: 0.5em;
    left: 0;
    position: relative;
    top: 0.15em;
    transform: rotate(225deg);
    vertical-align: top;
    width: 0.5em;
  }
  button#next {
    background: #023c91;
    height: 80px;
    width: 46px;
    color: #fff;
    position: absolute;
    top: 35%;
    right: 0;
    transform: translateY(-50%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button#next::after {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: "";
    display: inline-block;
    height: 0.5em;
    left: 0;
    position: relative;
    top: 0.15em;
    transform: rotate(45deg);
    vertical-align: top;
    width: 0.5em;
  }
}

.insta_feed_section {
  position: relative;
  margin-bottom: 2rem;
  .insta_title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #02295d;
    /* position: absolute; */
    margin: 16px 0px;
  }
  .field-name-field-form-description {
    .field-label {
      display: none;
    }
    .field-items {
      .field-item {
        .social {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          position: absolute;
          top: -16px;
          right: 0;
          a {
            color: #9b9b9b;
            cursor: pointer;
          }
        }
      }
    }
  }
  .field-name-field-raw-html {
    .field-label {
      display: none;
    }
    .field-items {
      .field-item {
        .eapps-instagram-feed {
          a.eapps-link {
            display: none !important;
          }
        }
      }
    }
  }
}
.resources-listings-section {
  hr.overline {
    display: none;
  }
}

.all-categories-section-row {
  position: relative;
  div {
    span {
      display: none;

      &:nth-child(2) {
        display: block !important;
      }

      a.see-all-class {
        display: block;
        color: #023c91;
        float: right;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        position: absolute;
        top: -47px;
        right: 0;

        &::after {
          border-style: solid;
          border-width: 0.2em 0.2em 0 0;
          content: "";
          display: inline-block;
          height: 0.35em;
          left: 0;
          position: relative;
          top: 0.15em;
          transform: rotate(45deg);
          vertical-align: top;
          width: 0.35em;
          margin-left: 5px;
        }
      }
    }
  }
}

.node-article-listing-page {
  h1 {
    width: fit-content;
    max-width: fit-content !important;
    font-size: 48px !important;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #02295d;
    margin: 20px auto 40px auto !important;

    @media (max-width: 1023px) {
      font-size: 32px !important;
      line-height: 40px;
    }
  }
}

.article-arrow-link {
  color: #023c91 !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.article-accordion__heading {
  font-size: 24px !important;
  line-height: 32px !important;
}

.explore-auskick-columns-section {
  a.more-to-explore-see-all-class {
    display: block;
    color: #023c91;
    float: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    position: absolute;
    top: 6.5%;
    right: 0;

    &::after {
      border-style: solid;
      border-width: 0.2em 0.2em 0 0;
      content: "";
      display: inline-block;
      height: 0.35em;
      left: 0;
      position: relative;
      top: 0.15em;
      transform: rotate(45deg);
      vertical-align: top;
      width: 0.35em;
      margin-left: 5px;
    }
  }
}
