/**
Styles related to image hover blocks - eg the program blocks on the home page
*/

.blue-hover-block,
.image-hover-block {
  overflow: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0) scale(1, 1);
}

.hover-image {
  cursor: pointer; cursor: hand;
  transform: translateZ(0px);
  transition: transform 0.25s ease-in-out;
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
}

.program-type-block .hover-image{
  width: 100%;
}

.hover-image img {
  width:100%;
}

.gradient-cover{
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 99.96%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.blue-hover-block .hover-text{
  bottom: 0;
  transform: translateY(120%);
  transition: transform 0.25s;
}

.blue-hover-block .hover-block-header{
  position: absolute;
  bottom: 100%;
  margin-bottom: 20px;
  transition: all 0.25s;
}

.blue-hover-block .hover-text p{
  font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ffffff;
  overflow: hidden;
  margin-top: -6px;
}

.blue-hover-block.hover .hover-image,
.image-hover-block.hover .hover-image {
  transform: scale(1.1) translateZ(0px);
  transition: transform 0.25s;
}

.blue-hover-block .hover-hide {
  opacity: 1;
  transition: opacity 0.25s ease 0.1s;
}

.blue-hover-block.hover .hover-hide {
  opacity: 0;
  transition: opacity 0.25s, height 0.1 ease 0.25s;
}

.blue-hover-block::before{
  content:' ';
  width:100%;
  height:100%;
  opacity: 0.9;
  overflow: hidden;
  transition: all 0.5s ease;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 7000px solid #006BCB;
	border-left: 4216px solid transparent;
  z-index: 2;
  width: 0;
	height: 0;
  transform: translateX(4230px);
  
}

.blue-hover-block.hover::before{
  transition: all 0.5s ease-in-out;
  transform: translateX(0);
}


.blue-hover-block .hover-description {
  font-weight: normal;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height .5s,opacity .5s;
  margin: 5px 0 5px 0;
  color: #fff;

  /** TODO: Really keen to refactor these media queries into separate mixins as duplicating these values too much*/
  @media all and (min-width: 640px){
    font-size: 13px;
    line-height: 17px;
  }
  @media all and (min-width: 832px){
    font-size: 17px;
    line-height: 22px;
  }
  @media all and (min-width: 1024px){
    font-size: 19px;
    line-height: 25px;
  }
  @media all and (min-width: 1380px){
    font-size: 26px;
    line-height: 36px;
  }

  padding-right: 45%;
}
.blue-hover-block .hover-description.wide{
  color: #fff;
  padding-right: 10px;
}

@media all and (min-width: 640px){

  .blue-hover-block:hover .hover-image,
  .image-hover-block:hover .hover-image{
    transform: scale(1.1) translateZ(0px);
    transition: transform 0.25s;
  }

  .blue-hover-block:hover .hover-block-header,
  .image-hover-block:hover .hover-block-header{
    margin-bottom: 10px;
    transition: all 0.25s;
  }

  .blue-hover-block:hover .hover-text,
  .image-hover-block:hover .hover-text {
    transform: translateY(0);
    transition: transform 0.25s;
  }

  .blue-hover-block:hover::before,
  .image-hover-block:hover::before{
    transition: all 0.5s ease-in-out;
    transform: translateX(0);
  }

  .blue-hover-block:hover .hover-hide
  .image-hover-block:hover .hover-hide {
    opacity: 0;
    transition: opacity 0.25s, height 0.1 ease 0.25s;
  }

  .blue-hover-block:hover .hover-description,
  .image-hover-block:hover .hover-description {
    max-height: 500px;
    opacity: 1;
    
  }
}
