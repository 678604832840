/**
Styles for our Program Subtype Page types
*/
.field-name-field-css-class{
  display: none !important;
}

.node-program-subtype-page, .node-curriculum-landing{
  
  .hero-special-text{
    h2{
      font-weight: 900;
      line-height: 1.1;
      font-size: 36px;
      max-width: 13ch;
    }

    p{
      font-size: 18px; /* min-size, max-size */
      font-weight: normal;
      line-height: 1.36;
    }
  }

  .paragraphs-item-content-sections,
  .paragraphs-item-content-section-container{
    .white-background{
      .paragraphs-items-field-sections .field-items .field-item {
        background-color: white;
        padding: 40px 0 60px;
        @media all and (min-width: 768px) {
          padding: 50px 0;
        }
        @media all and (min-width: 1280px) {
          padding: 80px;
        }
      }
    }
    .smaller-text-first-section{
      .paragraphs-items-field-sections .field-items .field-item:nth-of-type(1) {
        .activity-section-text{
          p {
            font-size: responsive 16px 20px; /* min-size, max-size */
            font-range: 640px 1800px;
            line-height: 1.7
          }
        }

      }
    }
    .no-padding-second-section{
      .paragraphs-items-field-sections .field-items .field-item:nth-of-type(2) {
        @media all and (min-width: 1024px) {
          padding: 120px 0 120px 60px;
        }
        @media all and (min-width: 1800px) {
          padding: 130px 0 130px 120px;
        }
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        .activity-section-image{
          padding: 50px 0;
        }
      }
    }
    .smaller-text {
      .paragraphs-items-field-sections .field-items .field-item {
        .activity-section-text{
          p {
            font-size: responsive 16px 20px; /* min-size, max-size */
            font-range: 640px 1800px;
            line-height: 1.67;
          }
        }

      }
    }
  }

  .auskick-video-section{
    p{
      font-size: responsive 16px 26px; /* min-size, max-size */
      font-range: 1024px 1920px;
      line-height: 1.62;
      letter-spacing: normal;
    }
  }
}

span.delta-container{
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-weight: bold;
  color: white;
  background-color: #4990e2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

@media all and (min-width: 768px) {
  .node-program-subtype-page, .node-curriculum-landing{
    .half-image-hero{
      .hero-special-text{
        h2{
          font-weight: 900;
          line-height: 1.1;
          font-size: responsive 30px 72px; /* min-size, max-size */
          font-range: 768px 1800px;
          margin-bottom: 4px;
          max-width: 14ch;
        }
        p {
          font-size: responsive 16px 28px; /* min-size, max-size */
          font-range: 768px 1800px;
          padding-right: 10px;
          line-height: 1.45;
        }
      }
    }

  }
}

@media all and (min-width: 1480px) {
  .node-program-subtype-page, .node-curriculum-landing{
    .hero-special-text{
      p {
        padding-right: 90px;
        max-width: 32ch;
      }
    }
  }
}

@media all and (min-width: 1800px) {
  .paragraphs-item-content-sections, .paragraphs-item-content-section-container{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item{
          .activity-section-text{
            .activity-section-text-inner {
                padding: 0 100px;
            }
          }
        }
      }
    }
  }
}


@media all and (max-width: 768px) {
  .node-program-subtype-page, .node-curriculum-landing{
    .half-image-hero{
      text-align: center;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      h2 {
        margin: 16px auto;
      }
      p {
        margin-bottom: 10px;
      }
      
    }

    .paragraphs-item-content-sections {
        .paragraphs-items-field-sections{
          .field-items{
            .field-item{
              text-align: left;
              .activity-section-text{
                padding-bottom: 10px;
              }
              p{
                line-height: 1.63;
              }
            }
          }
        }
    }

    .auskick-video-section,
    .auskick-activity-list-section{
      text-align: left;
      h2 {
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.63;
      }
      .long-activities-row-viewport{
        margin-left: 0;
      }
      .activity-footer{
        p {
          padding: 0;
          margin-bottom: 4px;
          line-height: 1.4;
        }
      }
    }

  }
}

@media all and (max-width: 640px) {
  .node-program-subtype-page, 
  .node-curriculum-landing, 
  .node-school-ambassadors-registration{
    .half-image-hero{
      margin-left:-20px;
      margin-right: -20px;
      img.hide-for-desktop{
        display: block;
        margin-bottom: 0;
      }
    }
  }
}
