$accordion-padding-right: 100px;

.accordion {

  &.accordion--has-margin-bottom {
    margin-bottom: 60px;
  }

  .accordion__item {
    padding: 10px 0;
  }
  .accordion__trigger {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    &:before {
      content: '';
      background: no-repeat url(../../images/arrow-down.svg);
      background-size: cover;
      width: 19px;
      height: 19px;
      float: left;
      margin-right: 14px;
      margin-top: 2px;
    }
    &.active {
      &:before {
        background-image: url(../../images/arrow-up.svg);
      }
    }
  }

  .accordion__heading {
    font-size: 20px;
    color: #02295D;
    font-weight: 600;
    line-height: 1.4;
    width: 95%;
  }

  .accordion__text,
  .accordion__text p,
  .accordion__text li {
    font-size: 18px;
    line-height: 1.56;
    color: $body_text;
    @media all and (max-width: 831px){
      font-size: 16px;
    }
  }

  .accordion__text a{
    font-size: 18px;
  }

  .accordion__content {
    display: none;
    margin-top: 10px;
    padding-bottom: 20px;
    transition: height .3s ease-in-out;
    overflow: hidden;
    @media all and (min-width: 832px){
      padding-right: $accordion-padding-right;
    }
    @media all and (min-width: 1024px){
      padding-bottom: 40px;
    }

    &.expanded {
      display:block;
    }
  }

}
