

.image-gallery{
  position: relative;
  width: 100%;
  margin: 50px 0;
}

.image-gallery .prev,
.image-gallery .next{
  z-index: 2;
  position: absolute;
  display:  block;
  color: #fff;
  text-align: center;
  top: 41%;
  font-size: 40px;
  transform: translateY(-50%);
  width: 40px;
  height:  40px;
  text-shadow: 0px 0px 2px rgba(0,0,0,0.8);
}
.image-gallery a{
  cursor: pointer;
  opacity: 0.5;
  transition: opacity .2s;
}
.image-gallery .paging a.current,
.image-gallery a:hover{
  opacity: 1;
}
.image-gallery a.prev{
  left:  20px;
}
.image-gallery a.next{
  right:  20px;
}

.image-gallery .paging{
  text-align: center;
  margin-top: 10px;
  /*
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);*/
}

.image-gallery .paging a {
  background-color: #D8D8D8;
  border-radius: 50%;
  width: 12px;
  height:  12px;
  display: inline-block;
  cursor: pointer;
  margin: 0 8px;
  box-shadow:  0px 0px 2px rgba(0,0,0,0.8);
}

.image-gallery .paging a.current {
  background-color: #000000;
}

.image-gallery .image-slider{
  min-height: 240px;
}

.image-gallery .image-slider .image-slide {
  width:  100vw;
}
.image-gallery .image-slider .image-slide img{
  width:  100%;
  height:  auto;
}

.image-gallery a:hover {
  text-decoration: none;
}

.caption-container{
  font-weight: normal;
  text-align: center;
  margin: 12px;
}

@media all and (min-width: 640px) {

  .image-gallery .image-slider{
    min-height: 360px;
  }

  .image-gallery .prev,
  .image-gallery .next{
    top: 45%;
  }

}

@media all and (min-width: 832px) {
  .image-gallery .image-slider{
    min-height: 470px;
  }
}

@media all and (min-width: 1024px) {
  .image-gallery .image-slider{
    min-height: 570px;
  }

  .image-gallery a.prev{
    left:  30px;
  }
  .image-gallery a.next{
    right:  30px;
  }

  .image-gallery .prev,
  .image-gallery .next{
    top: 50%;
  }
}

@media all and (min-width: 1480px){
  .image-gallery .image-slider{
    min-height: 760px;
  }
}
