/** General styles related to page layout */
html, body
{
    height: 100%;
}

body {
  margin:0;
}

body.noscroll{
  overflow: hidden;
}

.up-thirty{
  transform: translateY(-30px);
}

.width100{
  width: 100%;
}

.width50{
  width: 50%;
}

.width60{
   width: 60%;
 }

.width80{
  width: 80%;
}

.height100 {
  height: 100%;
}

.vspacer {
  margin-bottom: 40px;
}

.vspacer2 {
  margin-bottom: 80px;
}

.vspacer3 {
  margin-bottom: 160px;
}

.vspacer4 {
  margin-bottom: 320px;
}

.max-width-5 { max-width: 80rem }

.page-file {
  .file {
    max-width: 1920px;
    margin: 100px auto 50px;
    display: flex;
    justify-content: center;
  }
}

.adaptable-content-container{
  padding: 20px 20px 0;
  margin-left:auto;
  margin-right: auto;
  @media all and (min-width: 640px){
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
  }
  @media all and (min-width: 1024px){
    padding-top: 40px;
    max-width: 930px;
  }
  @media all and (min-width: 1920px){
    padding-top: 60px;
    max-width: 1080px;
  }
}


.adaptable-content-container.no-contents{
  @media all and (min-width: 1024px){
    max-width: 700px;
  }
  @media all and (min-width: 1920px){
    max-width: 850px;
  }
}

/* Temporary styles for old resources page style */
.max-width-wide .adaptable-content-container,
.max-width-wide .adaptable-content-container.no-contents
{
  p.large{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
  @media all and (min-width: 1024px){
    max-width: 930px;
  }
  @media all and (min-width: 1280px){
    padding-top: 10px;
    max-width: 1200px;
    p.large{
      max-width: 900px;
    }
  }
  @media all and (min-width: 1480px){
    max-width: 1280px;
  }
}

.adaptable-content-container.no-top-padding{
  padding-top: 0;
}

.faq-container {
  padding-left: 20px;
  padding-right:20px;
  margin-left: auto;
  margin-right: auto;
  @media all and (min-width: 640px){
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
  }
  @media all and (min-width: 1024px){
    max-width: 680px;
  }
  @media all and (min-width: 1280px){
    max-width: 830px;
  }
}

@media (min-width: 1368px){

  .xl-col{
    float:left;
    box-sizing:border-box;
  }

  .xl-col-right{
    float:right;
    box-sizing:border-box;
  }

  .xl-col-1{
    width:8.33333%;
  }

  .xl-col-2{
    width:16.66667%;
  }

  .xl-col-3{
    width:25%;
  }

  .xl-col-4{
    width:33.33333%;
  }

  .lg-col-5{
    width:41.66667%;
  }

  .xl-col-6{
    width:50%;
  }

  .xl-col-7{
    width:58.33333%;
  }

  .xl-col-8{
    width:66.66667%;
  }

  .xl-col-9{
    width:75%;
  }

  .xl-col-10{
    width:83.33333%;
  }

  .xl-col-11{
    width:91.66667%;
  }

  .xl-col-12{
    width:100%;
  }

}

/** Navigation **/

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 50px;
}

/**
If it's logged in, provide some space at the top so the admin toolbar can render
*/
body.logged-in .fixed-header {
  position: fixed;
  top: 42px;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: #fff;
}


body.logged-in.node-type-home-page .main-wrapper {
  margin-top: 0;
}

.content-body{
  padding:16px;
}

.centered-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bounds-text{
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 250px;
}

.centered-relative{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.hero-centered-text {
  position: absolute;
  top: 62%;
}

.bottom-text {
  position: absolute;
  bottom: 0;
}

.promo-bottom{
  position: absolute;
  bottom: 0;
  left: 18px;
}

.centered-item{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.square, .small-square, .vertical-rectangle, .horizontal-rectangle, .mobile-square-desk-rectangle  {
  position: relative;
}

.square {
  width:100%;
}

.vertical-rectangle{
  width:100%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.vertical-rectangle:after {
  content: "";
  display: block;
  padding-bottom: 200%;
}

.horizontal-rectangle:after{
  content: "";
  display: block;
  padding-bottom: 50%;
}

.text-container {
  width: 100%;
  position: absolute;
  top: 0;
}

.mobile-side-padding,
.responsive-side-padding{
  margin-left: 20px;
  margin-right: 20px;
}

.full-height{
  height:  100%;
}

.mobile-tablet-margin{
  margin-left: 20px;
}

.large-body-copy {
  padding: 20px 20px 10px;
  text-align: center;
}

/********    HOME PAGE   *********/
.home-content{
  margin:0;
  padding:0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
  }
}

.home-intro-text, .home-intro-text p {
  text-align: center;
  padding: 0 30px;
}

.home-get-involved{
  margin: 40px auto 30px;
  text-align: center;
  padding: 0 30px;
  h2 {
    margin-bottom: 10px;
  }
}

.assist-links {
  padding:0;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 5px;
  > div {
    width: 50%;
    padding: 5px;
  }
}

/********    PROGRAM TYPE BLOCKS   *********/
.program-type-block{
  margin:0;
  position:relative;
  overflow: hidden;
}

/******** header video ******/
.hero-video-overlay{
  position: absolute;
  top:  0px;
  left: 0px;
  width:  100%;
  height:  100%;
}

.all-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.isolated-form-container{
  width: 100%;
  height: 100%;
  padding: 0 30px;
  margin-top: $header_height_mobile;
  box-sizing: border-box;
  background-color: #ffffff;
}


.flex-vertical-align{
  display: flex;
  align-items: center;
}

.max-width-page{
  padding: 0 20px;
  .hero, .home-hero {
    margin-left: -20px;
    margin-right: -20px;
    @media all and (min-width: 1024px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.login-portals-container{
  .field-items {
    display: flex;
    flex-direction: column;
    margin: 10px -10px;
    @media all and (min-width: 640px) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 20px -30px;
    }
    @media all and (min-width: 1024px) {
      margin: 30px -10px;
    }
    @media all and (min-width: 1280px) {
      margin: 40px -20px;
    }
    .field-item {
      padding: 10px 0;
      @media all and (min-width: 640px) {
        width: 50%;
        padding: 10px;
      }
      @media all and (min-width: 1024px) {
        width: 33.33%;
      }
      @media all and (min-width: 1280px) {
        padding: 15px;
      }
      .login-portal-item{
        background-color: #F8F8F8;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 30px 0 35px;
        height: 100%;
        @media all and (min-width: 1280px) {
          padding: 40px 0 45px;
        }
        .responsive-logo-img{
          width: 330px;
          height: auto;
          @media all and (min-width: 640px) {
            width: 280px;
          }
          @media all and (min-width: 1280px) {
            width: 335px;
          }
        }
        a {
          color: $blue_button_color;
          font-weight: 700;
          display: block;
          margin: 10px 0 18px;
          font-size: 18px;
        }
      }
    }
  }
}


@media all and (min-width: 640px) {
  body.logged-in .fixed-header {
    top: 42px;
  }

  body.logged-in.navbar-tray-open .fixed-header {
    top: 84px;
  }


  body.logged-in.node-type-home-page .main-wrapper {
    margin-top: 0;
  }

  .home-content{
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -5px 20px;
    > div {
      width: 50%;
      padding: 5px;
      margin: 0;
    }
  }

  .assist-links {
    margin: 0 -5px 15px;
    > div {
      width: 25%;
      padding: 5px;
    }
  }

  .slider-container{
    margin-top:58px;
    padding-bottom: calc(50% - 66px);
  }

  .title-container{
    top: 200px;
  }

  .desktop-item{
    margin-right: 25px;
  }

  .mobile-side-padding{
    margin-left: auto;
    margin-right: auto;
  }

  .bounds-text{
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    max-width: 60%;
  }

  .large-body-copy {
    padding: 40px 30px 20px;
  }

  .max-width-page{
    padding-left: 30px;
    padding-right: 30px;
    .hero {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .node-type-home-page{
    .max-width-page{
      padding-left: 10px;
      padding-right: 10px;
      .home-hero {
        margin: 10px 0 0 0;
      }
      .map-promo {
        margin: 0;
      }
    }
  }

  .vertical-content-spacer{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  
}



@media all and (min-width: 1024px) {
  

  .home-get-involved{
    margin-bottom: 40px;
  }

  .responsive-content-container {
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }

  .responsive-side-padding{
    margin-left: auto;
    margin-right: auto;
  }

  .vertical-content-spacer{
    margin-top: 60px;
    margin-bottom: 100px;
  }

  .home-content{
    margin: 0 -10px 20px;
    > div {
      padding: 10px;
    }
  }

  .assist-links {
    margin: 0 -10px 15px;
    > div {
      padding: 10px;
    }
  }

}

@media all and (min-width: 1280px) {
  .mobile-tablet-margin{
    margin-left: 0;
  }

  .responsive-content-container{
    padding: 0;
    max-width: 1280px;
  }

  .vertical-content-spacer{
    margin-top: 80px;
    margin-bottom: 150px;
  }

}

@media all and (min-width: 1920px) {
  
  .max-width-page{
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .vertical-content-spacer{
    margin-top: 100px;
    margin-bottom: 180px;
  }
}

@media all and (min-width: 1980px) {
  .max-width-page{
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (max-width: 80rem){
  .mobile-side-padding.max-width-5{
    margin-left: 20px;
    margin-right: 20px;
  }

  .audience-block.mobile-side-padding{
    margin: 18px 20px 10px;
  }
}

.view-top-content {
  max-width: 1280px;
  margin: 50px auto;
  padding: 0 20px;
}

