/***
Font families, colors and sizes go here
*/


/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/montserrat/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/montserrat/montserrat-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat/montserrat-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat/montserrat-v15-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/montserrat/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat/montserrat-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/montserrat/montserrat-v15-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/montserrat/montserrat-v15-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/montserrat/montserrat-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/montserrat/montserrat-v15-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/montserrat/montserrat-v15-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'PODIUMSharp-211';
  src: url('../../fonts/podium/podium.woff2') format('woff2'), url('../../fonts/podium/podium.woff') format('woff');
}

html, body, h1,h2,h3,h4,h5,h6, p, li, a, .btn, span {
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
}

html{
  text-rendering: optimizeLegibility;
}

body{
  color: #00529C;
}

h1,h2,h3,h4,h5,h6{
  color: #00529C;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:none;
  font-weight: 700;
  font-style: normal;
	font-stretch: normal;
}

input, button, select {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:none;
}

h1 {
  color: #02295D;
  font-size: 32px;
  line-height: 40px;
	font-stretch: normal;
  @media all and (min-width: 768px){
    font-size: 60px;
    line-height: 60px;
  }
  @media all and (min-width: 1280px){
    font-size: 90px;
    line-height: 90px;
  }
}

h1.auskick{
  font-size: 34px;
  line-height: 40px;
  font-family: 'filson-soft', Arial;  
  @media all and (min-width: 1024px){
    font-size:52px;
    line-height: 0.9;
  }
  @media all and (min-width: 1480px){
    font-size: 76px;
  }
  @media all and (min-width: 1920px){
    font-size: 100px;
  }
}

h1.auskick-title{
  font-family: 'filson-soft', Arial;  
  margin: 10px auto;
  @media all and (min-width: 1024px){
    line-height: 1;
    max-width: 20ch;
    margin: 20px auto;
  }
  @media all and (min-width: 1480px){
    font-size: 62px;
    margin: 30px auto;
  }
  @media all and (min-width: 1920px){
    font-size: 70px;
    line-height: 74px;
  }
}

h1.no-header-title {
  margin: 10px auto 34px;
  font-family: 'PODIUMSharp-211', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media all and (min-width: 1024px){
    margin: 30px auto 40px;
    max-width: 21ch;
  }
  @media all and (min-width: 1280px){
    margin: 40px auto 60px;

  }
}

.fixed-header .menu-header h1.logo a{
  color: #00529C
}


h1.hero-title,
.left-bottom-text h2.hero-title {
  font-size: 42px; /* min-size, max-size */
  color: white;
  @media all and (min-width: 1024px) {
    font-size: 70px;
  }
  @media all and (min-width: 1480px) {
    font-size: 90px;
  }
  @media all and (min-width: 1920px) {
    font-size: 110px;
  }

}

h1.hero-title.podium{
  font-family: 'PODIUMSharp-211', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  letter-spacing: 1px;
  font-weight: normal;
  text-transform: uppercase;
  max-width: 13ch;
  line-height: 1;
  
  @media all and (min-width: 1024px) {
    max-width: 13ch;
  }
}

.mobile-top h1.hero-title.podium {
  margin: 4px auto 4px;
  @media all and (min-width: 640px) {
    margin: 6px 0 6px;
  }
  @media all and (min-width: 1024px) {
    margin: 20px 0 10px;
  }
}

h2{
  font-size: responsive 26px 42px; /* min-size, max-size */
  font-range: 640px 1920px;
	line-height: 1.08;
  margin-bottom: 10px;
	color: $blue;
}


h2.block {
  font-family: 'PODIUMSharp-211', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: normal;
  font-size: 38px; 
  line-height: 36px;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
  @media all and (min-width: 640px) {
    font-size: 42px;
    line-height: 38px;
  }
  @media all and (min-width: 1024px) {
    font-size: 60px;
    line-height: 55px;
  }
  @media all and (min-width: 1280px) {
    font-size: 70px;
    line-height: 60px;
  }
  @media all and (min-width: 1480px) {
    font-size: 80px;
    line-height: 70px;
  }
}

h2.cta-title{
  font-family: 'PODIUMSharp-211', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-weight: normal;
  font-size: 48px;
  line-height: 1;
  letter-spacing: 1.14px;
  color: #ffffff;
  text-transform: uppercase;
  max-width: 12ch;
  margin: 10px 0 15px;
  @media all and (min-width: 1024px) {
    max-width: none;
    font-size: 54px;
  }
  @media all and (min-width: 1280px) {
    font-size: 60px;
  }
  @media all and (min-width: 1480px) {
    font-size: 70px;
  }
}

h2.activities-header{
  margin-bottom: 22px;
}

h2.tag-line{
  text-align:  center;
  font-size: responsive 30px 62px; /* min-size, max-size */
  font-range: 420px 1800px;
  line-height: 1;
  margin-top: 20px;

}

h3 {
  color: $blue;
  font-size: 20px;
  line-height: 28px;
  @media all and (min-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media all and (min-width: 1280px) {
    font-size: 32px;
    line-height: 40px;
  }
}

h3.listing{
  color: #022E68;
  span{
    padding-left: 8px;
    font-weight: normal;
  }
}

h3.card-title{
  font-size: 18px;
  line-height: 22px;
  margin: 12px 0 6px;
}

h3.smaller, .cms-content h3.smaller {
  font-size: 30px;
  line-height: 1.1;
  letter-spacing: 0;
}

h3.club {
  font-size: 26px;
	line-height: 1.0;
	letter-spacing: normal;
	color: $blue;
}

h3.club-teaser-heading {
  font-size: 18px;
	line-height: 24px;
	letter-spacing: normal;
	color: $blue;
}

h3.club-heading {
  font-size: 18px;
	line-height: 24px;
	letter-spacing: normal;
	color: $blue;
}

h3.category-header {
  font-size: responsive 20px 24px; /* min-size, max-size */
  font-range: 640px 1280px;
  margin: 6px 0 10px;
  @media all and (min-width: 1024px) {
    margin-bottom: 16px;
  }
  color: $blue_heading_color;
}

h3.tile {
  font-size: responsive 26px 44px; /* min-size, max-size */
  font-range: 640px 1920px;
  line-height: 1.09;
  color: white;
  margin-bottom: 2px;
  max-width: 15ch;
  @media all and (min-width: 1024px) {
    margin-bottom: 10px;
  }
}

h4 {
  font-size: 16px;
  line-height: 24px;
  color: #01285E;
}

h4.preheading {
  margin-top: 20px;
  @media all and (min-width: 1024px) {
    margin-top: 40px;
  }
}

h4.preheading a {
  color: #01285E;
}

.activity-footer h4 {
  font-size: responsive 16px 18px; /* min-size, max-size */
  font-range: 640px 1024px;
	color: $body_text;
  line-height: 1.1;
  margin-bottom: 2px;
}

h5 {
	font-size: 14px;
	line-height: 1.43;
	letter-spacing: normal;
	color: $body_text;
}

h2.black, h3.black, h4.black{
  color: $body_text;
}


h4.contacts-header {
  margin: 20px 0 10px;
  font-size: 18px
}

p{
  font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.38;
	letter-spacing: normal;
	color: $body_text;
  margin: 4px 0;
}

p.info {
  font-size: 26px;
	line-height: 1.46;
	color: #373a36;
}

.resource-text{
  margin-bottom: 32px;
  p {
    font-size: 14px;
    line-height: 18px;
    color: $body_text;
  }
}

.subheader-text {
  margin: 20px 0;
}

.subheader-text p{
  font-size: responsive 16px 18px; /* min-size, max-size */
  font-range: 420px 1280px;
  line-height: 1.56;
  color: #070707;
  text-align: center;
  font-weight: 500;
}

.subheader-text.large-text p{
  font-size: 24px;
  @media all and (min-width: 1480px) {
    font-size: 28px;
  }
}

p.promo-light{
  font-size: 18px;
	line-height: 0.78;
	color: #ffffff;
}

.hero p {
  font-size: 24px;
	font-weight: 700;
	color: #ffffff;
}

p.description {
  font-size: 20px;
	line-height: 1.21;
  color: $body_text;
  @media all and (min-width: 1024px) {
    font-size: 24px;
  }
}

p.resource-description {
  font-size: 14px;
}

.large-body-copy p {
  font-size: 20px;
  line-height: normal;
  margin: 10px 0;
}

.large-body-copy p.small {
  font-size: 16px;
  line-height: 1.5;
}

.large-body-copy .btn{
  margin: 30px 0 50px;
}

p.smallprint {
  color: #939393;
  font-size: 16px;
}

p.club-description-inclusion{
  font-size: 14px;
  line-height: 22px;
  margin: 8px 0 16px;
  @media all and (min-width: 1024px) {
    font-size:16px;
    line-height: 24px;
  }
}

a {
  text-decoration: none;
  cursor: pointer; cursor: hand;
  color: $link_colour;
  font-size: 16px;
}

a.more-programs-link {
  font-size: 18px;
	line-height: 48px;
	color: $blue;
  margin-top: 18px;
}

a.chevron-blue {
  background: url(../../images/svg/chevron-right-blue.svg) no-repeat 98% 50%/18px 18px;
  padding-right: 18px;
}

a.chevron-blue:hover {
  text-decoration: underline;
}

span.last-updated-text{
  font-size: 14px;
  color: #1B1B1B;
  font-weight: normal;
}

a.detail-link {
  font-size: 16px;
	line-height: 1.38;
	letter-spacing: normal;
	color: $body_text;
  text-decoration: underline;
}

a.download-link {
  text-decoration: none;
  color: $blue_light;
  font-weight: 700;
  font-size: 20px;
}

a.download-link:hover {
  text-decoration: underline;
}

.program-type-block h2,
.assist-links h2,
.program-detail h2 {
  margin: 20px 0 0 0;
  width: 45%;
  line-height: 1;
  color: white;
}


.program-type-block .description,
.assist-links .description{
  font-size: 12px;
  margin: 2px 0 4px;
  font-weight: 700;
  line-height: 1.33;
  color: #fff;
}

.capitalize {
  text-transform: capitalize;
}

.lead-text h3,
.activity-section-text h3 {
  font-size: 30px;
}

h4.map-club-name{
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  color: #00529C;
}

h4.download {
  font-size: 24px;
	font-weight: 700;
	color: #ffffff;
}

.map-club-address {
  color: #555555;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.club-description{
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: $blue;
}

.club-teaser .club-audience {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #4A4A4A;
  text-transform: uppercase;
}

a.view-club-details {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: $red;
}

.club-link-block a{
  font-weight: 700;
  font-size: 14px;
}

.btn-primary.btn-find-your-way{
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
  text-align: center;
  line-height: 22px;
  color: #white;
  text-transform: uppercase;
}

h2.programs-heading {
  font-size: 36px;
  line-height: 1.2;
  text-align: center;
  padding: 55px 0;
  font-weight: normal;
  font-style: italic;
  color:#373a36;
}

p.intro-text, .intro-text p{
  font-size: 16px;
  line-height: 1.4;
}

.home-intro-text p{
  line-height: 1.333;
  margin: 20px auto 20px;
  @media all and (min-width: 1024px) {
    line-height: 1.4;
    margin: 30px auto 30px;
  }
  @media all and (min-width: 1280px) {
    margin: 50px auto 40px;
  }
}

p.subheading {
  font-size: responsive 18px 28px; /* min-size, max-size */
  font-range: 640px 1280px;
}

.lead-text p {
  font-size: 16px;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
}

.footer {
  color: #FFFFFF;
  font-size: 14px;
  a {
    color: #FFFFFF;
    font-size: 16px;
  }
  .footer__footer-heading {
    font-weight: bold;
  }
}

p.smallprint a {
  font-size: 15px;
}

p.small {
  font-size: 14px;
}

.node-half-header-page {
  h3 {
    font-size: 24px;
    margin: 10px 0 22px;
  }
  p {
    font-size: 18px;
    margin: 8px 0;
    line-height: 1.8;
    a {
      font-size: 18px;
      color: #4a90e2;
    }
    a.btn {
      color: #ffffff;
    }
  }

  p.blue-intro {
    font-size: 24px;
    color: #00529c;
    padding: 30px 0 20px;
    line-height: 1.38;
  }
  a.btn-large {
    margin: 20px 0;
    padding: 0 20px;
  }
}

@media all and (max-width: 360px){
 

  .program-type-menu h2 {
    font-size: 16px;
  }

  .program-type-menu h3 {
    font-size: 25px;
  }

  .program-type-menu .program-type-details{
    font-size: 11px;
  }

  .program-type-menu {
    font-size: 11px;
  }
}


@media all and (min-width: 640px){

  h4.download {
    font-size: 28px;
  }

  p.intro-text, .intro-text p{
    font-size: 16px;
  }

  h3.blue,
  h3.blue input {
    font-size: 26px;
  }

  p.info {
    font-size: 16px;
  }

  p.promo-light{
    font-size: 12px;
  }

  .large-body-copy p{
    font-size: 24px;
    line-height: 1.5;
    margin: 10px auto;
  }

  .large-body-copy p.small {
    font-size: 20px;
  }

  .description {
    font-size: 12px;
  }

}



@media all and (min-width: 1024px){


  .program-type-block .description,
  .assist-links .description{
    font-size: 16px;
    margin: 14px 0 0;
  }


  .lead-text h3 {
    font-size: 44px;
  }

  .activity-section-text h3 {
    font-size: 36px;
  }

  p.info {
    font-size: 24px;
  }

  p.intro-text, .intro-text p{
    font-size: 22px;
  }

  .hero p {
    font-size: 22px;
  }

  p.block-italic{
    font-size: 22px;
  }

  p.promo-light{
    font-size: 20px;
  }

  .footer, .footer a {
    font-size: 14px;
  }

  .lead-text p {
    font-size: 18px;
  }

  .node-half-header-page {
    h3 {
      font-size: 30px;
      margin: 10px 0 20px;
    }
    p {
      font-size: 18px;
      a {
        font-size: 18px;
      }
    }
    p.blue-intro {
      font-size: 30px;
      padding: 50px 0 30px;
    }
  }
}

@media all and (min-width: 1480px){

  h2.block {
    max-width: 12ch;
  }

  h2.programs-heading {
    font-size: 36px;
  }


  h4.download {
     font-size: 36px;
	   color: #ffffff;
  }

  p.info {
    font-size: 26px;
  }

  p.intro-text, .intro-text p{
    font-size: 28px;
  }

  p.promo-light{
    font-size: 24px;
  }

  .activity-section-text h3 {
    font-size: 40px;
  }

  .node-half-header-page {
    h3 {
      font-size: 36px;
      margin: 10px 0 30px;
    }
    p {
      font-size: 24px;
      a {
        font-size: 24px;
      }
    }

    p.blue-intro {
      font-size: 36px;
      padding: 70px 0 30px;
    }
    a.btn-large {
      margin: 30px 0;
    }
  }

}

@media all and (min-width: 1800px){

  .home-promo .description,
  .home-promo .description p {
    font-size: 20px;
  }


  p.promo-light{
    font-size: 36px;
  }

  p.tagline {
    font-size: 30px;
  }

  .hero p {
    font-size: 24px;
  }

  .aflw-promo p {
    font-size: 18px;
  }

  .aflw-promo p.larger {
    font-size: 24px;
  }

  .aflw-promo p.dark {
    font-size: 16px;
  }

  .activity-section-text h3 {
    font-size:44px;
  }


}

@media all and (max-width: 320px){
  .aflw-promo p {
    font-size: 12px;
    letter-spacing: 0px;
    margin: 2px 0 8px;
  }
}


.no-letter-spacing {
  letter-spacing: 0 !important;
}

.upper {
  text-transform: uppercase !important;
}

.white {
  color: #fff !important;
}

.spaced {
  line-height: 1.44;
}

.extra-bold {
  font-weight: 800 !important;
}

.filson {
  font-family: 'filson-soft';
}
