/** cms-content is any content defined in cms ckeditors */

.cms-content h4,
.contents-generating-item-body h4{
  	margin: 20px 0 6px;
	color: $blue;
	@media all and (min-width: 1024px){
		margin: 30px 0 10px
	}
	@media all and (min-width: 1280px){
		margin-top: 40px;
	}
}

.cms-content h4.closer{
	margin: 20px 0 10px;
}

.cms-content h4.no-margin{
	margin: 0;
}


.cms-content p,
.activity-section-text-inner {
	color: $body_text;
	margin: 10px 0;
	strong, b {
		font-weight: 700;
	}
}

.cms-content .terms-conditions p {
  margin-left: 35px;
}

.cms-content, 
.contents-generating-item-body {
	.file-image{
		img {
			max-width: 100%;
			height: auto;
		}
	}
} 

.cms-content a, .contents-generating-item-body a {
  	font-size: 16px;
	
	color: $red;
	background: linear-gradient(180deg,transparent 0,transparent 60%,rgb(228, 30, 49, 0.1) 0,rgba(228, 30, 49,.1) 92%,transparent 93%,transparent);
}

.cms-content a:hover,
.contents-generating-item-body a:hover {
	color: $link_hover_colour;
	background: linear-gradient(180deg,transparent 0,transparent 60%,rgb(2, 60, 145, 0.1) 0,rgba(2, 60, 145,.1) 92%,transparent 93%,transparent);
}

.cms-content a.btn {
	height: 45px;
	line-height: 45px;
	color: #fff;
	text-decoration: none;
	padding: 0 26px;
	margin: 10px 0;
	background: none;
}

.cms-content a.btn:hover {
	color: #fff;
	background-color: #c40011;
}

.cms-content ul {
    list-style: none; /* Remove list bullets */
    padding: 0;
    margin: 12px 0 16px;
}


.cms-content ul > li, .cms-content ol > li {
  	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	color: $body_text;
  	padding-left: 4px;
	margin: 6px 0;
}

.cms-content ul > li:before {
    content: "•"; /* Insert content that looks like bullets */
    padding-right: 8px;
}


.cms-content .terms-conditions ul > li {
  list-style-type: none;
  font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: $body_text;
  margin-bottom: 20px;
  clear: both;
  display: table;
}



.cms-content .terms-conditions ul > li:before {
    content: none;
}

.cms-content .terms-conditions ul > li a {
  font-size: 20px;
}

.cms-content .terms-conditions ul li span.indicator {
  font-size: 28px;
	font-weight: 800;
	font-style: italic;
	font-stretch: normal;
	line-height: 1.0;
	letter-spacing: normal;
	color: $blue;
  width: 35px;
  display: inline-block;
  float: left;
}

.cms-content .terms-conditions ul li div {
  float: left;
  display: inline-block;
  width: 90%;
  width: calc(100% - 35px);
}

.cms-content .terms-conditions ul li ul {
  margin-top: 10px;
  padding-left: 35px;
}

.cms-content .terms-conditions ul li ul li {
  font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.56;
	letter-spacing: normal;
	color: $body_text;
  margin-bottom: 10px;
}

.sidebar-content a{
  font-size: 18px;
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: $blue;
  text-decoration: none;
}

.sidebar-content a:hover{
  text-decoration: underline;
}



.cms-content a:hover {
  color: $link_hover_colour;
}

.cms-content hr {
  border: solid 1px #979797;
}

.cms-content hr.blue {
  border: solid 1px #d2d7de;
}

.cms-content hr.even {
	margin:12px 0;
}

.cms-content hr.light-grey {
	border: solid 1px #E4E4E4
}

@media all and (min-width:640px) {
	.cms-content h4.classic {
		font-size: 22px;
	}


}

@media all and (min-width: 1280px){
  

.cms-content h4.classic {
	font-size: 24px;
}

.cms-content h5 {
   font-size: 18px;
}

  .cms-content p,
  .cms-content ul li,
	.activity-section-text-inner {
    font-size: 18px;
	margin: 18px 0;
  }

	.resource-intro p,
  p.large {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $body_text;
  }

	p.auskick-intro {
		font-size: 20px;
		line-height: 1.55;
	}

	p.small {
		font-size: 14px;
	}

  .sidebar-content a{
    font-size: 18px;
    color: $blue;
  }
}
