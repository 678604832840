/** General utilities for hiding and showing content at various sizes */
.hide-for-desktop {
  @media all and (min-width: 640px) {
    display: none;
  }
}

.show-tabletportrait-down{
  display: block;
  @media all and (min-width: 768px) {
    display: none;
  }
}

.hide-tabletportrait-down{
  display: none;
  @media all and (min-width: 768px) {
    display: block;
  }
}

.hide-for-tabletup {
  display: block;
  @media all and (min-width: 1280px) {
    display: none;
  }
}

.hide-for-mobile {
  display: none;
  @media all and (min-width: 640px) {
    display: block;
  }
}

.hide-for-mobile-inline {
  display: none;
  @media all and (min-width: 640px) {
    display: inline;
  }
}

.hide-for-tabletdown {
  display: none;
  @media all and (min-width: 1280px) {
    display: block;
  }
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.hide-for-smalltabletdown {
  display: none;
  @media all and (min-width: 1024px) {
    display: block;
  }
}

.show-for-smalltabletdown {
  display: block;
  @media all and (min-width: 1024px) {
    display: none;
  }
}

.hide-for-smalltabletdown-inline {
  display: none;
  @media all and (min-width: 1024px) {
    display: inline;
  }
}

.flex-center-items{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

hr.overline {
  color: #E41E31;
  width: 40px;
  border: none;
  border-top: solid 4px #E41E31;
  box-shadow: 0 2px 8px 0 rgba(232,33,56,0.25);
  margin: 10px 0 12px;
  @media all and (min-width: 1024px){
    width: 62px;
    margin: 16px 0;
  }
}

hr.overline.level-1{
  color: $level_1_color;
  border-top: solid 4px $level_1_color;
  box-shadow: 0 2px 8px 0 rgba(255,130,36,0.25);
}

hr.overline.level-2{
  color: $level_2_color;
  border-top: solid 4px $level_2_color;
  box-shadow: 0 2px 8px 0 rgba(22,147,175,0.25);
}

hr.overline.level-3{
  color: $level_3_color;
  border-top: solid 4px $level_3_color;
  box-shadow: 0 2px 8px 0 rgba(89,44,130,0.25);
}

.test-class {
  border: 1px solid red;
}

.grey-top-border{
  border-top: 1px solid #E8E7E7;
}

