
/**
Sets up the order of our media queries for mq packer,
otherwise they appear in the order they are first included
*/

@media all and (min-width: 400px) {

}

@media all and (min-width: 540px) {

}

@media all and (min-width: 640px) {

}

@media all and (min-width: 768px) {

}

@media all and (min-width: 832px) {

}

@media all and (min-width: 1024px) {

}

@media all and (min-width: 1280px) {

}

@media all and (min-width: 1360px) {

}

@media all and (min-width: 1480px) {

}

@media all and (min-width: 1800px) {

}

@media all and (min-width: 1920px) {
    
}

@media all and (min-width: 1980px) {

}

@media all and (min-width: 2200px) {

}

@media all and (max-width: 640px){

}
