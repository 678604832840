:root {
  /**
  some variables - largely so that webpack doesn't complain to us
  */
    --space-1: .5rem;
    --space-2: 1rem;
    --space-3: 2rem;
    --space-4: 4.5rem;
    --width-1: 24rem;
    --width-2: 32rem;
    --width-3: 48rem;
    --width-4: 64rem;
    --h00:4rem;
    --h0:3rem;
    --h1:2rem;
    --h2:1.5rem;
    --h3:1.25rem;
    --h4:1rem;
    --h5:0.875rem;
    --h6:0.75rem;
    --bold-font-weight:bold;
    --caps-letter-spacing:.2em;
    --line-height-1: 1;
    --line-height-2: 1.125;
    --line-height-3: 1.25;
    --line-height-4:1.5;
    --border-width:1px;
    --border-radius: 0;
    --z1:1;
    --z2:2;
    --z3:3;
    --z4:4;
}


/** Header - Height at 640px */
$header_height_mobile: 50px;
$header_height_640: 50px;
$header_height_832: 50px;
$header_height_1024: 60px;
$header_height_1280: 70px;

$club_details_border_height: 9px;
$content_width: 1920px;

/** Colors */
$red: #e41e31;
$blue: #02295D;
$pale_blue: #578ed5;
$blue_light: #4990E2;
$body_text: #070707;
$link_colour: #e41e31;
$link_hover_colour: #023C91;
$blue_title_colour: #023C91;
$black_text: #373a36;
$btn_grey: #939393;
$input_grey: #939393;
$arrow-color: #4b92e2;
$white: #ffffff;
$deep_blue: #0a549a;
$social_blue: #0d4f9c;
$black: #000000;
$coaches_background: #0052B0;
$orange_background: #ff830e;
$button_disabled: #cacaca;
$grey_header: #949494;
$program-text: #02295D;
$top-nav-colour: #01285E;
$footer-link-color: #7E7E7E;
$disabled-grey: #7E7E7E;
$pale_grey_outlines: #d3d3d3;

$link_underline_hover_color: #e41e31;
$blue_button_color: #023C91;
$blue_heading_color: #023C91;
$title_hover_color: #023C91;
$blue_button_hover_color: #0455cf;

$button_alt_color: #D60D52;
/** Borders **/

$grey_border: 1px solid #979797;
$tab_underline: 1px solid #dbdbdb;

$link_bottom_hover: #4b92e2;
$tab_bottom_hover: #e41e31;
$nav_underline: #c9c9c9;

/** Sections **/

$section_height_sml: 450px;
$section_height_med: 520px;
$section_height: 706px;
$section_height_lrg: 926px;
$section_bg_grey: #f0f1f3;
$section_bg_blue: $blue;
$section_bg_light_blue: $blue_light;

$level_1_color: #ff8224;
$level_2_color: #1693af;
$level_3_color: #592c82;
