/**
Resources and Content Generating Page Styles
*/

.resources-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.resource-item{
  margin: 0 0 20px;
  @media all and (min-width: 1024px) {
    margin-top: 30px;
  }
}

a.resource-link, .cms-content a.resource-link{
	color: $blue_title_colour;
	font-weight: normal;
	text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px;
}

a.resource-link:hover, .cms-content a.resource-link:hover{
	color: $blue;
}

a.resource-link i {
  vertical-align: -5%;
}

/**  Resource page */

.resource-image{
  min-width: 100%;
  height: auto;
  margin-bottom: 6px;
}

.header-image-container{
  margin: 170px auto 0;
  padding: 10px 0 0;
  img {
    max-width: 100%;
    height: auto;
  }

  @media all and (min-width: 640px) {
    max-width: 600px;
    padding: 0;
  }

  @media all and (min-width: 1024px) {
    margin: 50px auto 0;
    max-width: 960px;
  }
  
  @media all and (min-width: 1280px) {
    max-width: 1280px;
  }
}

.node-type-article{
  .header-image-container{
    margin-top: 20px;
  }
}


.video-iframe-container,
.node-contents-generating-page {
  .header-image-container{
    margin-top: 20px;
    img {
      min-width: 100%;
    }
    @media all and (min-width: 1024px) {
      margin-top: 0;
    }
  }
} 

.node-program-subtype-page, .node-curriculum-landing, .node-school-ambassadors-registration{
  .header-image-container{
    margin: 0;
    padding: 0;
  }
}

.contents-generating-section{
  display: flex;
  flex-direction: column;
  
  .contents-table-section{

    h4 {
      font-size: 12px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: #02295D;
      font-weight: bold;
    }
    ul.page-contents {
      padding-left: 0;
      list-style: none;
      li {

        a {
          font-weight: 600;
          font-size: 16px;
          color: #023C91;
          background: none;
          padding: 8px 0;
          display: block;
          text-decoration-line: underline;
        }
        a:hover {
          background: none;
        }
      }
    }
  }
  .contents-generating-item{
    margin-bottom: 40px;
    h2{
      font-size: responsive 26px 42px; /* min-size, max-size */
      font-range: 420px 1280px;
      margin: 6px 0 10px;
    }
    .contents-generating-item-body{
      margin-bottom: 16px;
      
      p, li, ol, a{
        font-size: responsive 16px 18px; /* min-size, max-size */
        font-range: 420px 1280px;
        color: #070707;
        margin: 10px 0;
        line-height: 1.667;
      }

      a{
        color: $red;
      }

      a:hover {
        color: $link_hover_colour
      }
    }
  }
}

@media all and (min-width:640px) {

  
  
  .resources-container{
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px -10px;
  }

  .resource-item{
    width: 50%;
    padding: 0;
    box-sizing: border-box;
    margin: 0 0 10px;
  }

}

@media all and (min-width:1024px) {
  .resource-item{
    width: 25%;
  }

  /**  Resource page */

  .resource-image{
    margin-bottom: 12px;
  }

  .contents-generating-section{
    flex-direction: row;
    .contents-table-section{
      width: 200px;
      margin-right: 30px;
    }
    .contents-items-container{
      width: 700px;
      .contents-generating-item{
        margin-bottom: 80px;
        h2{
          margin-bottom: 30px;
        }
        .contents-generating-item-body{
          margin-bottom: 30px;
        }
        a.btn {
          padding: 0 36px;
        }
      }
    }
  }
}


@media all and (min-width:1920px) {
  .contents-generating-section{
    
    .contents-items-container{
      width: 850px;
    }
  }
}
