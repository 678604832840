@charset "UTF-8";

/**
This iconset is generated using apps.fontastic.me
*/

@font-face {
  font-family: "play";
  src:url("../../fonts/play.eot");
  src:url("../../fonts/play.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/play.woff") format("woff"),
    url("../../fonts/play.ttf") format("truetype"),
    url("../../fonts/play.svg#afl") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "play" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "play" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
.icon-angle-down:before {
  content: "\62";
}
.icon-angle-left:before {
  content: "\63";
}
.icon-angle-right:before {
  content: "\64";
}
.icon-angle-up:before {
  content: "\65";
}
.icon-arrow-left:before {
  content: "\66";
}
.icon-arrow-right:before {
  content: "\67";
}
.icon-chevron-down:before {
  content: "\68";
}
.icon-chevron-right:before {
  content: "\69";
}
.icon-close-grey:before {
  content: "\6a";
}
.icon-close-square:before {
  content: "\6b";
}
.icon-download:before {
  content: "\6c";
}
.icon-menu:before {
  content: "\6d";
}
.icon-instagram-old:before {
  content: "\70";
}
.icon-facebook-official:before {
  content: "\71";
}
.icon-email:before {
  content: "\6e";
}
.icon-facebook:before {
  content: "\6f";
}
.icon-instagram:before {
  content: "\74";
}
.icon-phone:before {
  content: "\75";
}
.icon-twitter:before {
  content: "\76";
}
.icon-youtube:before {
  content: "\77";
}
*/

.icon-alert:before {
  content: "\61";
}
.icon-angle-down:before {
  content: "\62";
}
.icon-angle-left:before {
  content: "\63";
}
.icon-angle-right:before {
  content: "\64";
}
.icon-angle-up:before {
  content: "\65";
}
.icon-arrow-left:before {
  content: "\66";
}
.icon-arrow-right:before {
  content: "\67";
}
.icon-chevron-down:before {
  content: "\68";
}
.icon-chevron-right:before {
  content: "\69";
}
.icon-close-grey:before {
  content: "\6a";
}
.icon-close-square:before {
  content: "\6b";
}
.icon-download:before {
  content: "\6c";
}
.icon-menu:before {
  content: "\6d";
}
.icon-instagram-old:before {
  content: "\6e";
}
.icon-facebook-official:before {
  content: "\6f";
}
.icon-instagram:before {
  content: "\70";
}
.icon-facebook:before {
  content: "\71";
}
.icon-email:before {
  content: "\72";
}
.icon-phone:before {
  content: "\73";
}
.icon-twitter:before {
  content: "\74";
}
.icon-teamapp:before {
  content: "\76";
}
.icon-link:before {
  content: "\77";
}
.icon-youtube:before {
  content: "\75";
}

.icon-sm {
  font-size: 0.8em;
}

.icon-md {
  font-size: 1.2em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.icon-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.icon-angle-right.icon-lg {
  vertical-align: -25%;
}

.icon-2x {
  font-size: 2em;
}
.icon-3x {
  font-size: 3em;
}
.icon-4x {
  font-size: 4em;
}
.icon-5x {
  font-size: 5em;
}

.btn-badge .icon-email{
  font-size: 0.8;
}
