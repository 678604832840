
.auskick-program-page, .standard-program-page, .node-school-ambassadors-registration{
  .left-center-text, .hero-special-text{

    h2{
      font-weight: 700;
      line-height: 1;
      font-size: 34px; /* min-size, max-size */
      line-height: 40px;
      margin-top: 10px;
    }

    p{
      font-size: responsive 18px 28px; /* min-size, max-size */
      font-range: 640px 1480px;
      font-weight: normal;
      line-height: 1.36;
      max-width: 24ch;
      margin: 6px auto;
    }
  }

  .auskick-landing-main{
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    p.auskick-landing-audience{
      color: #023C91;
      font-size: responsive 14px 22px; /* min-size, max-size */
      font-range: 420px 1280px;
      font-weight: bold;
      margin: 30px auto 16px;
      padding: 0 20px;
      line-height: 1.42;
    }
    p.auskick-intro-text{
      margin: 20px auto 30px;
      padding: 0 20px;
      font-size: responsive 18px 28px; /* min-size, max-size */
      font-range: 420px 1800px;
      font-weight: normal;
      line-height: 1.44;
    }

  }

  .program-details-map-section{
    background-color: #ff8223;
    margin: 40px 0;
    padding: 36px 25px 310px;
    text-align: center;

    .auskick-girl-pompoms{
      position: absolute;
      bottom:0;
      left: -20px;
      height: auto;
      width: 112%;
      z-index: 7;
    }
    a.btn{
      background-color: #d10f47;
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 1px;
      position: absolute;
      bottom: 20px;
      width: calc(100% - 80px);
      left: 40px;
    }
    p{
      font-family: 'filson-soft';
      font-size: 24px;
      font-weight: 900;
      line-height: 1.44;
    }
  }
}

.content-video-container{
  margin: 70px 0 50px;
  position: relative;
  video {
    width: 100%;
  }
  .video-cover{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: 100% 100%;
    .btn-play-button{
      cursor: pointer;
      width: 50px;
      height: 50px;
      background: url(../../images/svg/play-comp.svg) no-repeat top left/100% 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      display: block;
      border: none;
      transform: translate(-50%, -50%);
    }
  }
}

.paragraphs-item-content-sections,
.paragraphs-item-content-section-container{
  .paragraphs-items-field-sections{
    .field-items{
      display: flex;
      flex-direction: column;
      .field-item{
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        background-color: white;
        .activity-section-text{
          padding: 20px 20px 50px;
          text-align: center;
          h2{
            font-family: 'filson-soft';
            font-weight: 900;
            color: #023C91;
            font-size: responsive 24px 42px; /* min-size, max-size */
            font-range: 1024px 1280px;
            line-height: 1.23;
          }
          p {
            font-size: responsive 16px 18px; /* min-size, max-size */
            font-range: 1024px 1920px;
            margin: 8px 0 12px;
            line-height: 1.6666;
            max-width: 45ch;
          }

          p:empty {
            margin: 0 !important;
          }

          a.btn{
            margin-top: 0;
          }
          
          .section-link-image{
            height: 25px;
            vertical-align: -22%;
            margin-right: 2px;
          }
          
        }
      }
      
    }
  }
}



.paragraphs-item-content-sections,
.paragraphs-item-content-section-container {
  .white-first-background{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item{
          background-color: white;
        }
        
      }
    }
  }
  .first-image-full-height{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item:nth-of-type(1){
          padding-top: 0;
          padding-bottom: 0;
          @media all and (min-width: 640px) and (max-width: 1024px) {
            padding-top: 80px;
          }
          @media all and (min-width: 768px) and (max-width: 1024px) {
            padding-top: 40px;
            .activity-section-text{
              margin-top: -40px;
            }
          }
        }
      }
    }
  }
}

.auskick-program-page{
  .paragraphs-item-content-sections, .paragraphs-item-content-section-container{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item:first-of-type, 
        .field-item:nth-of-type(2){
          background-color: #f7f8fc;
          margin-bottom: 30px;
        }
        .field-item.odd{
          h2{
            max-width: 15ch;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}

.auskick-columns-section,
.auskick-video-section,
.auskick-multitext-section,
.program-testimonials-section{
  padding: 30px 20px 50px;
  h2{
      font-family: 'filson-soft';
      font-weight: 900;
      color: #023C91;
      font-size: responsive 28px 42px; /* min-size, max-size */
      font-range: 640px 1280px;
      line-height: 1.24;
  }
  p{
    margin: 8px 0 0;
    font-size: responsive 18px 24px; /* min-size, max-size */
    font-range: 640px 1280px;
    line-height: 1.4;
    color: $body_text;
  }

  .column-sections{
    margin-bottom: 20px;
    h2{
      font-family: 'filson-soft';
      font-size: responsive 20px 36px; /* min-size, max-size */
      font-range: 640px 1920px;
      margin: 0 0 10px;
      line-height: 1.4;
      color: #023C91;
    }
    p{
      font-size: responsive 16px 18px; /* min-size, max-size */
      font-range: 640px 1280px;
      color: $body_text;
      line-height: 1.625;
    }
    .activity-section-image{
      max-width: 100%;
      min-width: 100%;
      margin: 0 auto;
    }
    .activity-section-text{
      .activity-section-text-inner{
        position: static;
        transform: none;
      }
    }
    
  }

  .auskick-multitext-columns{
    padding: 30px 0 50px;
    text-align: left;
    .field-items{
      .field-item{
        h3 {
          font-size: 24px;
          line-height: 1.2;
        }
        p{
          font-weight: 300;
          font-size: responsive 16px 20px; /* min-size, max-size */
          font-range: 640px 1280px;
          line-height: 1.6;
        }
      }
    }
  }
  .curriculum-listings-container{
    margin-top: 16px;
  }
}

.footyskillslab{
  .hero{
    h1.hero-title {
      color: #01285E !important;
      @media all and (min-width: 640px) {
        color: #fff !important;
      }
      @media all and (min-width: 1024px) {
        font-size: 60px;
      }
      @media all and (min-width: 1480px) {
        font-size: 70px;
      }
      @media all and (min-width: 1920px) {
        font-size: 90px;
      }
    }
    p.subheading {
      font-size: 18px;
      line-height: 28px;
      max-width: 40ch;
      color: #070707 !important;
      @media all and (min-width: 640px) {
        color: #fff !important;
      }
      @media all and (min-width: 1480px) {
        font-size: 20px;
      line-height: 30px;
      }
    }
  }
  .has_menu{
    margin-top: 50px;
    h1.hero-title.podium{
      max-width: 18ch;
    }
  }

  .auskick-columns-section,
  .auskick-video-section,
  .auskick-multitext-section,
  .program-testimonials-section{
    h2 {
      font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
      font-weight: 700;
    }
  }

  .auskick-video-section.no-bottom-padding{
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    p{
      font-size: 16px;
      line-height: 28px;
      margin-top: 12px;
      @media all and (min-width: 1280px) {
        margin-top: 20px;
        font-size: 18px;
        line-height: 30px;
      }
    }
    .fsl-google-logo{
      margin-top: 20px;
      @media all and (min-width: 1480px) {
        margin-top: 30px;
      }
    }
  }

  .auskick-video-section{
    margin: 40px auto 10px;
    padding: 0 10px 0;
  }

  .paragraphs-item-content-sections {
    .paragraphs-items-field-sections {
      .field-items {
        .field-item {
          .activity-section-text {
            padding: 20px;
            .section-link-image{
              display: none;
            }
          }
        }
      }
    }
  }
  
}

.auskick-video-section{
  .field-type-video-embed-field{
    .field-items {
      .field-item{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.auskick-activity-list-section{
  padding: 30px 0 20px;
}

.program-testimonials-section{
    padding: 30px 0;
}

.auskick-multitext-section{
  a.btn{
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    width: 100%;
  }
}

.auskick-video-section,
.program-testimonials-section{
  padding: 46px 10px 20px;
  background-color: white;
  text-align: center;
  margin: 30px auto 20px;
  .content-video-container{
    margin: 16px 0 0;
  }
  p {
    font-size: responsive 18px 28px; /* min-size, max-size */
    font-range: 640px 1480px;
  }
}

.program-testimonials-section{
    padding: 46px 0 20px;
}

.auskick-find-centre-section, .cta-panel{
  background-color: #ff8223;
  margin-top: 30px;
  padding: 20px 20px 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  overflow: hidden;
  .auskick-find-centre-section-inner, .cta-panel-inner{
    max-width: 380px;
    z-index: 9;
    p{
      font-family: 'filson-soft';
      font-weight: 900;
      color: white;
      font-size: responsive 24px 48px; /* min-size, max-size */
      font-range: 640px 1800px;
      margin-bottom: 20px;
      line-height: 1.25;
    }
  }

  .auskick-girl-pompoms{
    width: 90%;
    max-width: 350px;
    height: auto;
    margin-top: 20px;
    z-index: 8;
  }
  .auskick-boy{
    display: none;
    z-index: 8;
  }
}

.cta-panel {
  padding: 0;
  background-color: #023C91;
  .cta-panel-inner{
    margin-top: 20px;
    p {
      font-size: 24px !important;
      font-weight: normal;
      line-height: 38px;
      max-width: 24ch;
      margin: 8px auto 20px;
      font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
    }
    a.desktop-link{
      display: block;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: white;
      margin: 16px auto 10px;
      opacity: 1;
      transition: opacity 0.25s;
    }

    a.desktop-link:hover {
      opacity: 0.8;
    }
  }

  .cta-left-image{
    width: 100%;
    max-width: 350px;
    height: auto;
    z-index: 8;
    margin-top: -40px;
  }

  .cta-right-image{
    display: none;
  }
}

.program-testimonials-section{
  display: flex;
  flex-direction: column;
  h2 {
    padding: 0 40px;
  }
  .slider-buttons-container{
    order: 3;
    display: flex;
    align-self: center;
    .arrow-scroller-button{
      border: solid 2px #4990e2;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: white;
      .svg-fill {
        fill: #4990e2
      }
    }
    .arrow-scroller-button.disabled{
      background-color: transparent;
      border: solid 2px #d1d1d1;
      svg{
        opacity: 0.9;
      }
      .svg-fill {
        fill: #d1d1d1
      }
    }
  }
}

.testimonials-container{
  margin-top: 30px;
  overflow:hidden;
  .field-name-field-testimonials{
    overflow:hidden;
    width: 100%;
    margin-left: 20px;
    .field-items{
      max-width: none;
      margin: 0;
      width: 5000px;
      display: flex;
      height: 280px;
      padding-left: 10px;
      .field-item{
        margin-right: 20px;
        width: 300px;
        background-color: white;
        padding: 10px 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        height: 250px;
        display: flex;
        flex-direction: column;
        text-align: left;
        div {
          flex-grow: 5;
          margin-top: 10px;
          p {

            font-weight: 300;
            font-size: responsive 16px 20px; /* min-size, max-size */
            font-range: 640px 1800px;
            line-height: 1.6;
          }
        }

        h4{
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.curriculum-header-container{
  padding-top: 30px;
  @media all and (min-width: 1024px) {
    padding-top: 40px;
  }
}

@media all and (min-width: 400px) {
  .auskick-program-page{
    .program-details-map-section{
      margin: 50px 0;
      .auskick-girl-pompoms{
        width: 360px;
        left: calc(50% - 200px)
      }
      a.btn{
        max-width: 240px;
        left: calc(50% - 120px);
      }
    }
  }
}

@media all and (min-width: 640px) {

  .auskick-program-page, .standard-program-page, .node-school-ambassadors-registration{
    .left-center-text, .hero-special-text{
      h1{
        
        margin-bottom: 6px;
      }
      p {
        padding-right: 20px;
        margin: 12px 0;
      }
    }

    .program-details-map-section{
      margin: 50px 40px;
      .auskick-girl-pompoms{
        width: 360px;
        left: calc(50% - 200px)
      }
      a.btn{
        max-width: 300px;
        left: calc(50% - 150px);
      }
    }
  }

  .content-video-container{
    margin: 80px auto 20px;
  }

  .paragraphs-item-content-sections,
  .paragraphs-item-content-section-container{
    .paragraphs-items-field-sections{
      .field-items{
        display: flex;
        flex-direction: column;
        .field-item{
          display: flex;
          flex-direction: column-reverse;
          padding: 20px 0px 20px;
          .activity-section-text{
            max-width: 540px;
            margin: 0 auto;
            padding: 20px 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2 {
              margin: 6px 0;
              line-height: 1.24;
            }
            .activity-section-text-inner {
                padding: 0;
            }
          }
          .activity-section-image{
            max-width: 540px;
            margin: 0 auto;
          }

        }
        .field-item:nth-of-type(2n){
          background-color: white;
        }
        .field-item:last-of-type{
          padding: 0;
          margin: 0 auto 30px;
          .activity-section-text {
            padding: 0 0 10px;
          }
          
        }
      }
    }
  }

  .node-program-subtype-page{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
      .field-items{
          .field-item:last-of-type{
            margin: 0 auto;
            padding: 0;
            
            .activity-section-text {
              padding: 0 0 20px;
            }
        }
      }
    }
  }

  .auskick-program-page{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item{
            max-width: 540px;
            margin-left: auto;
            margin-right: auto;
            .activity-section-text{
              max-width: none;
              margin:0;
              display: flex;
              align-items: center;
              
            }
            .activity-section-image{
              max-width: none;
              margin:0;
            }
          }
          
          .field-item.odd{
            padding: 0 60px;
            h2{
              margin-left: auto;
              margin-right: auto;
            }
            .activity-section-image{
              align-self: flex-end;
            }

            
          }
          
        }
      }
    }
  }

  .auskick-program-page{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item:first-of-type{
            padding: 0 60px;
          }
        }
      }
    }
  }


  .auskick-columns-section,
  .auskick-video-section,
  .auskick-multitext-section,
  .auskick-activity-list-section,
  .program-testimonials-section{
    padding: 20px;
    p{
      margin: 10px 0 10px;
    }
    .field-items{
      display: flex;
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      .field-item{
        width: 33%;
        padding: 10px;
      }
    }

    .auskick-multitext-columns{
      margin: 20px 0 30px;
      .field-items{
        flex-wrap: wrap;
        .field-item{
          width: 50%;
        }
      }
    }
  }

  .auskick-multitext-section{
    a.btn{
      width: auto;
      padding: 0 30px;
    }
  }

  .auskick-video-section{
    margin-top: 20px;
    .content-video-container{
      margin-left: auto;
      margin-right: auto;
    }
  }

  .auskick-activity-list-section{
    text-align: left;
    padding: 20px 0;
    .auskick-activity-list-section-header{
      display: flex;
      align-items:center;
      margin-bottom: 10px;
      h3 {
        flex-grow: 1;
      }
    }
    .curriculum-listings-container{
      margin: 0 -30px 20px -30px;
      padding-left: 30px;
      overflow: hidden;
    }
    p {
      padding: 0;
    }
  }

  .program-testimonials-section{
      padding: 40px 0;
      display: block;
      .slider-buttons-container{
        float: right;
        margin-right: 10px;
        display: flex;
        .arrow-scroller-button{
          height: 34px;
          width: 34px;
        }
      }
      .testimonials-container{
        margin-top: 30px;
        .field-name-field-testimonials{

          margin-left: 40px;
          .field-items{
            height: 270px;
            padding-left: 10px;
            .field-item{
              margin-right: 20px;
              width: 300px;
              height: 250px;
            }
          }
        }
      }
  }

  .footyskillslab.max-width-page{
      padding-top: 30px;
      .hero .btn-primary{
        display: none !important;
      }
  }
}

@media all and (min-width: 1024px) {

  

  .auskick-program-page{

    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item{
            max-width: none;
            flex-direction: row;
            padding: 20px 0px 20px;
            .activity-section-text{
              max-width: 50%;
              margin: 0 auto;
              padding: 20px 0 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            
            }
            .activity-section-image{
              max-width: 50%;
              margin: 0 auto;
            }

          }
          .field-item:nth-of-type(2n){
            flex-direction: row-reverse;
          }
          .field-item:first-of-type,
          .field-item:nth-of-type(2),
          .field-item:last-of-type{
            margin: 20px 0;
            padding-top: 0;
            padding-bottom: 0;
          }
          .activity-section-text {
            padding-bottom: 0;
          }
          
        }
      }
    }

    .program-details-map-section{
      text-align: left;
      margin: 100px;
      padding: 50px 40px 45px 24%;
      .auskick-girl-pompoms{
        left: 50px;
        width: 380px;
      }
      a.btn{
        position: relative;
        width: auto;
        left: 0;
        bottom: 0;
        top: 0;
      }
      p{
        font-size: 24px; /* min-size, max-size */
        font-size: responsive 24px 43px; /* min-size, max-size */
        font-range: 1024px 1800px;
      }
    }
  }
  .content-video-container{
    .video-cover{
      .btn-play-button{
        width: 80px;
        height: 80px;
      }
    }
  }
  .auskick-columns-section,
  .auskick-video-section,
  .auskick-multitext-section,
  .auskick-activity-list-section,
  .program-testimonials-section{
    padding: 40px 20px 50px;
    p{
      margin: 12px 0 0;
    }
    .field-items{
      display: flex;
      max-width: 1200px;

      .field-item{
        width: 33%;
        padding: 10px 20px;
      }
    }

    .auskick-multitext-columns{
      padding: 30px 0 40px;
      .field-items{
        .field-item{
          width: 33%;
        }
      }
    }
  }

  .program-testimonials-section{
      padding: 40px 0;
  }

  .auskick-video-section {
    padding: 40px 0 20px;
    margin-bottom: 30px;
    .content-video-container{
        margin-top: 30px;
    }
  }

  .auskick-activity-list-section{
    padding: 40px 0 50px;
    .curriculum-listings-container{
      margin-right: 0;
    }
    
  }

  .paragraphs-item-content-sections,
  .paragraphs-item-content-section-container{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item{
          flex-direction: row;
          margin-bottom: 25px;
          .activity-section-text{
            max-width: none;
            margin:0;
            width: 50%;
            .activity-section-text-inner {
              padding: 0 20px;
              p {
                margin: 12px 0 22px;
              }
            }
          }

          .activity-section-image{
            max-width: none;
            margin:0;
            width: 50%;

          }
        }
        .field-item:nth-of-type(2n){
          flex-direction: row-reverse;
        }
        .field-item:last-of-type{
          padding-bottom: 0;
        }
      }
    }

    .first-image-full-height{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item:nth-of-type(1){
            padding-top: 0;
            padding-bottom: 0;
            .activity-section-image{
              padding: 0;
            }
          }
        }
      }
    }
  }

  .footyskillslab{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item{
            flex-direction: row-reverse;
            .activity-section-text{
              p {
                margin: 12px 0;
              }
              .section-link-image{
                display: block;
                height: 50px;
                width: auto;
                @media all and (min-width: 1280px) {
                  height: 80px;
                }
                @media all and (min-width: 1480px) {
                  height: 120px;
                }
              }
            }
          }
          .field-item:nth-of-type(2){
            flex-direction: row;
          }
        }
      }
    }
  }
  

  .auskick-find-centre-section, .cta-panel{
    flex-direction: row;
    position: relative;
    margin-top: 10px;
    padding: 70px 0 80px;
    .auskick-girl-pompoms{
      width: 32%;
      position: absolute;
      left: -3%;
      bottom:0;
      max-width: 400px;
    }
    .auskick-find-centre-section-inner, .cta-panel-inner{
      max-width: 490px;
      margin-left: auto;margin-right: auto;
    }
    .auskick-boy{
      width: 32%;
      display: block;
      position: absolute;
      bottom:0;
      right: 0;
      max-width: 400px;
    }
  }

  .cta-panel {
    padding: 0 !important;
    .cta-left-image, .cta-right-image{
      width: 32%;
      height: auto;
      display: block;
      margin-top: 0;
      max-width: none;
    }
    
  }

  .auskick-video-section{
      p {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
      }
  }

  .program-testimonials-section{
      padding: 40px 0;
      .testimonials-container{
        margin-top: 50px;
        .field-name-field-testimonials{

          .field-items{
            height: 320px;
            .field-item{
              margin-right: 20px;
              width: 320px;
              padding: 20px 30px;
              height: 280px;
            }
          }
        }
      }
  }
}

@media all and (min-width: 1280px) {
  .auskick-program-page, .standard-program-page{
    .program-details-map-section{
      margin: 100px 150px;
      padding: 80px 50px 70px 22%;
      
      .auskick-girl-pompoms{
        width: 420px;
      }
    }

    .auskick-landing-main{
      max-width: 1100px;
      p.auskick-intro-text{
        margin: 30px auto 40px;
      }
    }
  }

  .content-video-container{
    max-width: 1200px;
    margin: 80px auto 70px;
    .video-cover{
      .btn-play-button{
        width: 100px;
        height: 100px;
      }
    }
  }

  .auskick-find-centre-section, .cta-panel{
    padding: 120px 0 90px;
    .auskick-girl-pompoms{
      width: 40%;
      max-width: 400px;
      left: 0;
    }
    .auskick-find-centre-section-inner, .cta-panel-inner{
      max-width: 600px;
    }
    .auskick-boy{
      width: 40%;
      max-width: 400px;
    }
  }

  .auskick-video-section{
      p {
        max-width: 1000px;
      }
  }


  .program-testimonials-section{
      padding: 60px 0;
      .slider-buttons-container{
        .arrow-scroller-button{
          width: 40px;
          height: 40px;
        }
      }
      .testimonials-container{
        margin-top: 60px;
        .field-name-field-testimonials{

          .field-items{
            height: 340px;
            .field-item{
              margin-right: 30px;
              width: 350px;
              padding: 20px 30px;
              height: 300px;
            }
          }
        }
      }
  }

  .footyskillslab{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item{
            .activity-section-text{
              .section-link-image{
                  height: 80px;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1480px) {
  .auskick-program-page, .standard-program-page{
    .left-center-text{
      width: 38%;
      p {
        padding-right: 90px;
        max-width: 20ch;
      }
    }

    .program-details-map-section{
      margin: 130px 150px;
      padding: 100px 60px 90px 23%;

      .auskick-girl-pompoms{
        left: 75px;
        width: 520px;
      }
    }
  }

  .content-video-container{
    max-width: 1280px;
    margin: 80px auto 70px;
  }

  .paragraphs-item-content-sections, .paragraphs-item-content-section-container{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item{

          .activity-section-text{
            .activity-section-text-inner {
                padding: 0 50px;
            }
          }
        }
      }
    }
  }

  .auskick-program-page{
    .paragraphs-item-content-sections, .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item{
            padding: 0 140px;
            .activity-section-image{
              align-self: flex-end;
            }
          }
          .field-item:first-of-type,
          .field-item:nth-of-type(2),
          .field-item:last-of-type{
            margin: 20px 0;
          }
        }
      }
    }
  }

  .auskick-find-centre-section, .cta-panel{
    padding: 160px 0 110px;
    .auskick-girl-pompoms{
      max-width: 520px;
      left: 0;
    }
    .auskick-boy{
      max-width: 520px;
    }
    p {
      margin-bottom: 30px;
    }
  }

  .program-testimonials-section{
      padding: 80px 0;
      .testimonials-container{
        margin-top: 70px;
        .field-name-field-testimonials{
          margin-left: 80px;
          .field-items{
            height: 370px;
            .field-item{
              margin-right: 40px;
              width: 500px;
              padding: 20px 50px;
              height: 350px;
              div {
                margin-top: 20px;
              }
              h4 {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
  }

  .footyskillslab{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item{
            .activity-section-text{
              p {
                margin: 12px 0 20px;
              }
              .section-link-image{
                  height: 120px;
              }
            }
          }
        }
      }
    }
  }

}

@media all and (min-width: 1920px) {
  .auskick-program-page, .standard-program-page{
    .program-details-map-section{
      max-width: 1600px;
      margin: 150px auto;
      padding: 100px 60px 90px 23%;

    
      .auskick-girl-pompoms{
        left: 75px;
        width: 614px;
      }
    }

    .auskick-landing-main{
      max-width: 1500px;
      p.auskick-intro-text{
        margin: 40px 0 50px;
      }
    }

    .content-video-container{
      max-width: 1480px;
    }
  }

  .paragraphs-item-content-sections, .paragraphs-item-content-section-container{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item{

          margin-bottom:50px;
          .activity-section-text{
            .activity-section-text-inner {
                padding: 0 100px;
            }
          }
        }
      }
    }
  }

  .auskick-program-page{
    .paragraphs-item-content-sections, .paragraphs-item-content-section-container{
      .paragraphs-items-field-sections{
        .field-items{
          .field-item{
            padding: 0 160px;
          }
        }
      }
    }
  }

  .auskick-columns-section,
  .auskick-video-section,
  .auskick-multitext-section,
  .auskick-activity-list-section{
    padding: 20px;
    .column-sections{
      margin-bottom: 20px;
    }
    .field-items{
      max-width: 1500px;
      .field-item{
        padding: 20px 50px;
      }
    }
  }

  .auskick-video-section{
    padding-bottom: 30px;
    margin-bottom: 50px;
  }

  .auskick-activity-list-section{
    padding: 60px 0;
  }

  .auskick-find-centre-section, .cta-panel{
    .auskick-girl-pompoms, .auskick-boy{
      max-width: 580px;
    }
  }
}

@media all and (max-width: 640px) {
  .auskick-columns-section,
  .auskick-video-section,
  .auskick-multitext-section,
  .auskick-activity-list-section{
    .field-items{
      .field-item{
        padding-bottom: 30px;
      }
    }
  }

  .paragraphs-item-content-sections.featured-article{
    .paragraphs-items-field-sections{
      .field-items{
        .field-item{
          flex-direction: column;
          .activity-section-text{
            padding: 2px 0 50px;
            text-align: left;
          }
        }
      }
    }
  }

  .footyskillslab{
    .hero:before{
      background-image: none;
    }
  }
  
}


