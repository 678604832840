/**
  Styling for hero images and top of page spacing
*/

.hero::before {
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-image: linear-gradient(to bottom,transparent,rgba(0,0,0,.5));
}

.hero.horizontal-gradient::before {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}

.hero.has-desktop-video::before{
  background-image: none;
}

.hero.no-tint::before {
  background-image: none;
}

.hero::after {
  content: " ";
  display: block;
  padding-bottom: 99%;
}

.square-mobile-hero.hero::after {
  padding-bottom: 80%;
}

.out-of-bounds-hero::after{
  padding-bottom: 160%;
}
.long-mobile-hero::after{
  padding-bottom: 151%;
}

.hero img.header-img{
  position: absolute;
  top:0;
  left:0;
  z-index: -1;
  width: 100%;
  height: auto;
}

.hero.half-hero{
  background-position: bottom left;
  background-size: 100% 55%;
  background-color: $blue;
}

.hero.half-hero::before {
  background-image: none;
}

.hero.half-hero::after{
  content: " ";
  display: block;
  padding-bottom: 147%;
}

.hero.half-hero .hero-special-text{
  left: 50%;
  top: 22%;
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
  width: 80%;
}

.hero.half-hero .hero-special-text{
  top: 18%;
}


.hero.half-hero .btn-primary{
  height: 36px;
  line-height: 36px;
}

.hero-special-text h2,
.hero-special-text p {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:none;

}

.hero-special-text{
  h2{
    font-size: 28px
  }
  .btn-large{
    width: 80%;
  }
}

.hero.half-hero .hero-special-text h2 {
  font-size: 32px;
  font-weight: 800;
  font-style: italic;
  text-transform: none;
  margin-top: 10px;
  line-height: 1.2;
}

.hero.half-hero .hero-special-text .plain-text{
  margin-top: 10px;
}

.hero.half-hero .hero-special-text .plain-text p {
    font-weight: normal;
    line-height: 1.25;
    margin: 10px 0;
}

.hero.half-hero .hero-special-text .plain-text p.small {
  font-size: 14px;
}

.hero.half-hero .hero-special-text p {
  font-size: 20px;
  font-weight: normal;
  margin-top: 6px;
}

.hero.half-hero .auskck-rego-header-football{
  display: none;
  position: absolute;
  top: 14px;
  left: 0;
  width: 120px;
  height: auto;
}

/* Similar to the half hero, but with inlined image elements rather than bg images */
.half-image-hero{
  background-color: $coaches_background;
  margin: 0;
  .header-image {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .hero-special-text {
    padding: 10px 30px;
  }
}
.half-image-hero.auskick-parents,
.half-image-hero.auskick-activity-helpers,
.half-image-hero.livesite-header{
  background-color: $orange_background;
}

.scroll-icon {
  position: absolute;
  display: none;
  width: 40px;
  height: 40px;
  background: url(../../images/svg/scroll.svg) no-repeat top left/100% 100%;
  bottom: 30px;
  left: 50%;
  z-index: 3;
  text-align: center;
  opacity: 0.7;
  cursor: pointer; cursor: hand;
  margin-left: -20px;
  transition: transform 250ms;
  transform: translateY(0);
}


.scroll-icon:hover, .scroll-icon.hover {
  opacity: 0.99;
  transition: transform 250ms;
  transform: translateY(10px);
}

.scroll-icon p {
  color: #ffffff;
  margin: 4px 0;
}

.scroll-icon img{
  width: 40px !important;
  height: auto;
}

.scroll-icon img{
  width: 40px !important;
  height: auto;
}

.home-hero {
  margin-top: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  
  .home-header-video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.home-hero::after{
  content: " ";
  display: block;
  padding-bottom: 145.6%;
}

.hero .play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../../images/icons/play-video.png) at-2x;
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
}

.hero .play-video:hover {
  background-image: url(../../images/icons/play-video-hover.png) at-2x;
}

.hero {
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.out-of-bounds-hero {
  background-image: url(../../images/404/404_M.jpg) at-2x;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.home-banner-image-text{
  width: 95%;
  height: auto;
}

.left-bottom-text{
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 80%;
  h1, h2 {
    margin-bottom: 2px;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 8px;
    @media all and (min-width: 1280px) {
      margin-bottom: 14px;
    }
  }
}

.left-center-text {
  position: absolute;
  left: 40px;
  bottom: 40px;
  width: 80%;
  z-index: 9;
  
}

.left-center-text.mobile-top {
  bottom: auto;
  top: 30px;
  text-align: center;
  width: 100%;
  left: 0;
  padding: 0 20px;
}

.left-center-text p {
  font-size: 20px; /* min-size, max-size */
  font-weight: normal;
}

.hero .centered-header-video-link{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../../images/play-video.png) at-2x;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 70px;
  height: 70px;
  display: block;
}

.hero .centered-header-video-link:hover{
  background-image: url(../../images/play-video-hover.png) at-2x;
}

.menu-spacer {
  padding-top: 50px;
}

.has-program-menu .menu-spacer{
  padding-top: 170px;
}

.has-program-menu .hero, 
.has-program-menu .half-image-hero{
  margin-top: 170px;
}

.hero #video_container video {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.hero #program-page-video-player{
  display: none;
}

@media all and (min-width: 640px) {

  .hero.has-desktop-video::before{
    background: rgba(0,0,0,0.40);
    z-index: 8;
  }

  .hero #program-page-video-player{
    display: block;
  }

  .hero::after,
  .home-hero::after,
  .square-mobile-hero.hero::after,
  .long-mobile-hero::after {
    content: " ";
    display: block;
    padding-bottom: 50%;
  }

  .home-hero::after{
    padding-bottom: 50%;
  }
  
  /*
  .hero.has-desktop-video{
      margin-bottom: 30px;
  }*/

  .hero.has_menu::after {
    padding-bottom: 48.125%;
  }

  .hero.has_menu.has-desktop-video::after,
  .hero.has-desktop-video::after {
    padding-bottom: 56.25%;
  }
  .hero.half-hero {
    background-position: top right;
    background-size: 50% 100%;
  }

  .hero.half-hero .hero-special-text{
    left: 25%;
    top: 50%;
    width: 40%;
    text-align: left;
  }

  .hero.half-hero .hero-special-text h2 {
    font-size: 30px;
    line-height: normal;
  }

  .womens-hero, .umpire-hero {
    background-image: url(../../images/womens/womens.jpg) at-2x;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .out-of-bounds-hero {
    background-image: url(../../images/404/404.jpg) at-2x;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .home-banner-image-text{
    width: 340px;
    height: auto;
  }

  .left-bottom-text{
    bottom: 25px;
    left: 25px;
    width: 60%;
  }

  .left-center-text, .left-center-text.mobile-top {
    bottom: auto;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    width: 48%;
    text-align: left;
    padding: 0;
  }

  .left-center-text p {
    font-size: 18px;
  }

  .hero.half-hero .hero-special-text p {
    font-size: 16px;
  }

  .hero.half-hero .hero-special-text .plain-text p {
    font-size: 14px;
  }

  .hero.half-hero .hero-special-text .plain-text p.small {
    font-size: 12px;
  }

  .hero.half-hero .auskck-rego-header-football{
    display: none;
  }

 

}


@media all and (min-width: 768px) {

  .home-hero::after{
    padding-bottom: 50%;
  }

   /* Similar to the half hero, but with inlined image elements rather than bg images */
   .half-image-hero{
    display: flex;
    flex-direction: row-reverse;
    .header-image-container{
      width: 50%;
      img{
        margin: 0;
      }
    }
    .hero-special-text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      h2{
        font-size: 28px
      }
      padding-left: 40px;
      .btn-large{
        align-self: flex-start;
        padding: 0 45px;
        width: auto;
      }
    }
  }
}

@media all and (min-width: 1024px) {

  .has-program-menu .menu-spacer
  {
    padding-top: 120px;
  }

  .has-program-menu .hero,  
  .has-program-menu .half-image-hero{
    margin-top: 120px;
  }

 

  .home-banner-image-text{
    width: 600px;
  }

  .left-bottom-text{
    bottom: 30px;
    left: 30px;
    width: 50%;
    h1, h2 {
      margin-bottom: 6px;
    }
  }
  
  .scroll-icon {
    display: block;
    z-index: 9;
  }

  .has-desktop-video{
    .scroll-icon{
      bottom: 100px;
    }
  }

  .left-center-text, .left-center-text.mobile-top {
    left: 80px;
    width: 50%;
  }

  .left-center-text p {
    font-size: 24px;
  }

  .hero.half-hero .hero-special-text h2 {
    font-size: 48px;
  }

  .hero.half-hero .hero-special-text p {
    font-size: 24px;
  }

  .hero.half-hero .hero-special-text .plain-text p {
    font-size: 20px;
  }

  .hero.half-hero .hero-special-text .plain-text p.small {
    font-size: 16px;
  }

  .hero.half-hero .auskck-rego-header-football{
    width: 260px;
      top: 10px;
  }

  .hero.half-hero .btn-primary{
    height: 40px;
    line-height: 40px;
  }


  .half-image-hero{
    .hero-special-text{
      padding-left: 60px;
    }
    .header-image{
        height: auto;
        object-fit: fill;
    }
  }


}

@media all and (min-width: 1280px) {

  .hero.half-hero .hero-special-text h2 {
    font-size: 54px;
    line-height: 1.1;
  }

  .home-banner-image-text{
    width: 900px;
  }

  .left-bottom-text{
    h1, h2 {
      margin-bottom: 0;
    }
    p {
      margin: 4px 0 10px;
    }
  }

  .left-center-text{
    width: 40%;
  }

  .left-center-text, .left-center-text.mobile-top {
    left: 120px;
    width: 46%;
  }

  .hero.has-desktop-video{
      margin-bottom: 70px;
  }

  .hero.half-hero .hero-special-text p {
    font-size: 30px;
    line-height: 1.16;
  }

  .hero.half-hero .hero-special-text .plain-text p {
    font-size: 20px;
  }

  .hero.half-hero .hero-special-text .plain-text p.small {
    font-size: 16px;
  }

  .hero.half-hero .auskck-rego-header-football{
    width: 320px;
    top: 5%;
  }

  .hero.half-hero .btn-primary{
    height: 50px;
    line-height: 50px;
  }

  .half-image-hero{
    .hero-special-text{
      padding-left: 120px;
    }
  }

  .has-desktop-video{
    .scroll-icon{
      bottom: 140px;
    }
  }

}

@media all and (min-width: 1480px) {

  .hero.half-hero .hero-special-text h2 {
    font-size: 62px;
  }

  .left-bottom-text {
    width: 50%;
  }

  .left-bottom-text h1,
  .left-bottom-text h2 {
    margin-bottom: 2px;
  }

  .left-center-text, .left-center-text.mobile-top {
    left: 150px;
    width: 40%;
  }

  .hero.half-hero .hero-special-text .plain-text{
    margin-top: 40px;
  }

  .hero.half-hero .hero-special-text p {
    font-size: 32px;
  }

  .hero.half-hero .auskck-rego-header-football{
    width: 25%;
    top: 8%;
  }

  .has-desktop-video{
    .scroll-icon{
      bottom: 200px;
    }
  }

  .half-image-hero{
    .hero-special-text{
      padding-left: 170px;
    }
  }


}

@media all and (min-width: 1920px) {

  .half-image-hero {
    margin-left: 0;
    margin-right: 0;
  }

  .home-banner-image-text{
    width: 1051px;
  }

  .left-center-text{
    width: 30%;
  }

  .hero.half-hero .hero-special-text .plain-text{
    margin-top: 60px;
  }

  .hero.half-hero .hero-special-text p {
    font-size: 36px;
  }

  .hero.half-hero .auskck-rego-header-football{
    top: 10%;
  }

  .has-desktop-video{
    .scroll-icon{
      bottom: 250px;
    }
  }

}



