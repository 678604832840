
/** styles for Button links */
.btn {
  display: inline-block;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:none;
  box-sizing: border-box;
  font-weight: 700;
}

.btn:hover {
  text-decoration: none;
}

.btn.right{
  text-align: right;
}

.btn-primary, .btn-map{
  background-color: #E41E31;
  border: none;
  padding: 0 18px;
  line-height: 40px;
  height: 40px;
  font-size: 13px;
  transition: box-shadow 0.25s;
  box-shadow: 0px 2px 8px 0px rgba(232, 33, 56, 0.25);
}

.btn-primary.btn-hero,
.hero .btn-primary {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  font-size: 15px;
}



.btn-primary.btn-map-ok {
  width: 120px;
}

.btn-primary:hover, .btn-map:hover {
  background-color: #c40011;
  box-shadow: 0px 10px 15px 0px rgba(232, 33, 56, 0.25);
}

.btn-primary, .btn-blue, .btn-map {
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  padding: 0 20px;
  color: #fff;
}

.btn-map{
  span.icon {
    font-size: 12px;
  }
}

.btn-blue {
  background-color: $blue_button_color;
  box-shadow: 0px 2px 8px 0px rgba(2, 60, 145, 0.25);
}

.btn-blue:hover{
  background-color: $blue_button_hover_color;
  box-shadow: 0px 10px 15px 0px rgba(2, 60, 145, 0.25);
}

.btn-deactivated, .btn-deactivated:hover{
  background-color: #cccbcb;
}

a.btn-header-register {
  height: 54px;
  line-height: 54px;
  background-color: #f0001b;
  font-size: 18px;
  padding: 0 22px;
}

.btn-large {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
	font-weight: 800;
  padding: 0 22px;
  
}


.btn-home-registration {
  margin-bottom: 10px;
}

.btn-program-type-block{
  opacity: 0.99;
  z-index:500;
  padding: 0 15px;
}

.btn-promo {
  height: 25px;
  line-height: 25px;
  padding: 0 13px;
}

.btn-modal {
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  padding: 0 34px;
  margin-top: 30px;
}

.btn-map-promo{
  padding: 0 28px;
}

.btn-right-arrow{
  background: url(../../images/svg/RightArrowWhite.svg) no-repeat 90% 50%;
}

.btn-play-video{
  opacity: 0;
}

.btn-program-finder{
  padding: 14px 20px;
  margin: 8px 10px 8px 10px;
  border: 2px solid #4990e2;
}

.btn-program-finder:hover{
  border: 2px solid #2970c2;
}

.btn-primary.btn-program-finder.selected{
  background-color: #ffffff;
  color: #4990e2;
  border: 2px solid #4990e2
}

.btn-club-detail-register {
  font-size: 22px;
  font-weight: 800;
  line-height: 55px;
  height: 55px;
  padding: 0 10px;
}

.btn-primary.btn-club-register {
  padding: 0 16px;
  line-height: 40px;
  height: 40px;
  border: none;
}

.btn-scroll-to-form{
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  padding: 0 24px;
}

.btn-lets-play{
  margin:16px 0 10px 0;
  width: 325px;
  font-weight: bold
}

.btn-homepage{
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 0 16px;
}

.btn-white {
  height: 42px;
  line-height: 42px;
  border: $grey_border;
  color: $btn_grey;
  background-color: white;
  font-size: 14px;
  padding: 0 12px;
}

.btn-white:hover{
  border: 1px solid #0a549a;
  color: #0a549a;
}

.hero .btn-play-with-icon{
  background: #E41E31 url(../../images/svg/play-comp.svg) no-repeat 10px 50%/26px 26px;
  color: #fff;
  border: none;
  padding: 0 16px 0 42px;
  line-height: 44px;
  height: 44px;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 6px;
}

.hero .btn-play-with-icon:hover {
  background-color: #c40011;
}

.btn-support{
  color: $social_blue;
  background-color: white;
  border: $grey_border;
  font-size: 18px;
  font-weight: 800;
  height: 50px;
  line-height: 50px;
  width: 200px;
  i{
    vertical-align: -10%;
    font-size: 125%;
    margin-right: 3px;
  }

  i.icon-phone{
    font-size: 135%;
    vertical-align: -12%;
    margin-left: -3%;
    margin-right: -1.5%;
  }
}

.btn-support:hover{
  color: $white;
  background-color: $social_blue;
  border: 1px solid $social_blue;
}

.btn-continue {
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  font-weight: 800;
  color: $white;
  width: 100%;
  margin: 0 0 10px;
  text-transform: capitalize;
  i {
    font-size: 80%;
  }
}

.btn-cancel {
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  font-weight: 800;
  border: 1px solid $body_text;
  color: $body_text;
  background-color: $white;
  width: 100%;
  margin: 10px 0 0 ;
  text-transform: capitalize;
}

.btn-cancel:hover{
  color: $white;
}

a.btn.program-details-map-button{
  background: url(../../images/svg/map-pin.svg) no-repeat 10% 50%/20px 20px;
  padding-left: 30px;
  z-index: 8;
}

a.btn-find-centre{
  background: url(../../images/svg/map-pin.svg) no-repeat 20px 50%/16px 20px;
  padding: 0 30px 0 46px;
  height: 55px;
  line-height: 55px;
  font-size: 18px;
  background-color: #E41E31;
}

.btn-selector{
  background-color: white;
  border: 2px solid #D5D5D5;
  color: #0D4F9C;
  box-shadow: none;
  transition: box-shadow 0.25s ease-in-out;
}

.btn-selector:hover, .btn-selector.btn-selected{
  background-color: $level_1_color;
  border: 2px solid $level_1_color;
  box-shadow: 0 2px 8px 0 rgba(255,130,36,0.25);
  color: white;
}

.btn-selector.level-2:hover, .btn-selector.level-2.btn-selected{
  background-color: $level_2_color;
  border: 2px solid $level_2_color;
  box-shadow: 0 2px 8px 0 rgba(22,147,175,0.25);
}

.btn-selector.level-3:hover, .btn-selector.level-3.btn-selected{
  background-color: $level_3_color;
  border: 2px solid $level_3_color;
  box-shadow: 0 2px 8px 0 rgba(89,44,130,0.25);
}

.link-button-container{
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  .btn {
    margin: 0 10px 0 0;
  }
}

.btn-primary.btn-promo-popup {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  margin-top: 0px;
}

.btn-tile {
  background-color: $button_alt_color;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  color: white;
  padding: 0 16px;
  margin-top: 6px;
}

.btn-tile:hover {
  background-color: $red;
}

.btn-map{
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 700;
}

@media all and (min-width: 640px) {

  .btn-map-promo{
    height: 60px;
    line-height: 60px;
    padding: 0 35px;
    font-size: 16px;
  }

  .btn-find-program {
    margin: 30px 0;
    padding:16px;
    width: 260px;
  }

  .btn-program-type-block{
    height: 25px;
    line-height: 25px;
    padding: 0 18px;
  }

  .btn-homepage{
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 20px;
  }

  .btn-map-ok {
    width: 160px;
  }

  .btn-cancel, .btn-continue{
    width: 300px;
  }

  .btn-banner-register{
    font-size: 16px;
    height: 47px;
    line-height: 47px;
  }

  .sticky-button-container{
    display: none;
  }

}

@media all and (min-width: 768px) {
  .btn-program-type-block{
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
  }
  
}

@media all and (min-width: 1024px) {
  .link-button-container{
    .btn {
      margin: 2px 14px 0 0;
    }
  }

  .btn-map-promo{
    height: 60px;
    line-height: 60px;
    padding: 0 40px;
    font-size: 20px;
  }

  .btn-primary, .btn-blue {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    margin-top: 12px;
    padding: 0 22px;
  }

  .btn-primary.btn-hero{
    margin-top: 6px;
  }

  .btn-program-type-block {
    margin-top: 0;
  }

  .hero .btn-play-with-icon{
    margin-top: 10px;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
  }

  .hero .btn-banner-register{
    font-size: 18px;
    padding: 0 30px;
    margin-right: 10px;
  }

  .btn-transparent {
    padding: 0 22px;
    font-size: 18px;
  }

  a.btn.program-details-map-button{
    padding-left: 45px;
  }

  .btn-primary, .btn-blue {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    padding: 0 40px;
  }

  .link-button-container{
    margin-top: 30px;
    .btn {
      margin: 0 20px 0 0;
    }
  }

}

@media all and (min-width: 1280px) {
  .btn-program-type-block,
  .btn-promo {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 24px;
  }

  .btn-map-promo{
    width: 250px;
  }

  .btn-homepage{
    height: 60px;
    line-height: 60px;
  }

  .btn-large {
    height: 60px;
    line-height: 60px;
  }

  .hero .btn-primary {
    margin-top: 16px;
    padding: 0 42px;
  }

  .hero .btn-banner-register {
    font-size: 18px;
  }

  .hero .btn-play-with-icon{
    margin-top: 20px;
    font-size: 18px;
    line-height: 55px;
    height: 55px;
    background-size: 30px 30px;
    padding: 0 18px 0 52px;
    background-position: 12px 50%;
  }

}

@media all and (min-width: 1800px) {
  .btn-program-type-block,
  .btn-promo {
    height: 45px;
    line-height: 45px;
    font-size: 18px;
  }

  .btn-primary.btn-hero{
    padding: 0 40px;
  }

}

@media all and (max-width: 640px) {
  /*

  Not removing completely as is currently used
  on node--auskick_landing_map.tpl.php which may be used again in the future
  
  .btn-primary.btn-mobile-fixed {
    width:100%;
    position:fixed;
    left:0;
    bottom:0;
    z-index:9; 
    opacity: 1;
    display: block;
    transition: all 0.4s ease-out;
    margin-bottom: 0;
    margin: 0 !important;
    padding-left: 0;
    padding-right: 0;
    color: #ffffff !important;
  }

  .btn-primary.btn-mobile-fixed:hover {
    color: #ffffff !important;
  }

  .btn-primary.btn-go.btn-mobile-fixed {
    font-size: 16px;
  }

  .btn-primary.btn-mobile-fixed.scrolled {
    opacity: 0;
    display:none;
    transition: all 0.4s ease-out;
  }

  */

  /** Makes space for button on bottom of page*/
  .button-spacer{
    height: 80px;
    clear:both;
  }

  .button-spacer-landing {
    height: 65px;
    clear: both;
  }


  .btn-play-video{
    display: none;
  }

  .btn-banner-register{
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    width: calc(100vw - 40px);
  }

  .sticky-button-container{
    position: fixed;
    bottom: 50px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    .scroll-icon {
      margin: 10px 0 0;
      display: block;
      position: static;
    }
  }

  .sticky-button-container.js-loaded{
    opacity: 1;
  }


}

/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px)
and (-webkit-device-pixel-ratio: 3) {
  .sticky-button-container{
    position: absolute;
    bottom: 50px;
  }
}

@media all and (max-width: 768px) {
  .link-button-container{
    justify-content: center;
    .btn {
      margin: 0 10px;
    }
  }
}

@media all and (max-width: 320px) {
  .btn-primary{
    padding: 0 6px;
    line-height: 26px;
    height: 26px;
    font-size: 10px;
  }

  .hero .btn-banner-register{
    height: 52px;
    line-height: 52px;
    font-size: 16px;
  }
}
