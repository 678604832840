
/*----- Tabs -----*/
.tabs {
    display:inline-block;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;
}

/*----- Tab Links -----*/
    /* Clearfix */
    .tab-links {
      display: flex;
      padding: 12px 0 0;
      margin-top: 0;
      border-bottom: $tab_underline;
      border-top: $tab_underline;
    }
    .tab-links:after {
        display:block;
        clear:both;
        content:'';
    }

    .tab-links li {
        margin: 0 8px;
        list-style:none;
        text-align: center;
        flex-grow: 1;
    }

    .tab-links li.active a, .tab-links li:hover a{
      padding-bottom: 11px;
      border-bottom: 3px solid $tab_bottom_hover;
    }

        .tab-links a {
            padding: 2px 0 1px;
            text-transform: capitalize;
            display:inline-block;
            font-size: 13px;
            line-height: 16px;
            font-weight:600;
            transition:all linear 0.15s;
            text-decoration: none;
            color: $blue;
        }

        .tab-links a:hover {
            text-decoration:none;
        }

        .tab-links a h4 {
          margin: 0;
        }

    li.active a, li.active a:hover {
        background:#fff;

    }

    /*----- Content of Tabs -----*/
    .tab-content {
        background: $white;
    }

        .tab {
            display:none;
        }

        .tab.active {
            display:block;
          }

@media all and (min-width: 640px) {

  .tab-links {
    margin-top: 30px;
  }

  .tab-links li {
    text-align: left;
    flex-grow: 1;
    margin: 0 20px 0 0;
  }

  .tab-links li.active a, .tab-links li:hover a{
    padding-bottom: 18px;
  }
}

@media all and (min-width: 1280px) {
  .tabs {
    padding: 0;
  }
}
