

.program-filter-tabs{
  display: flex;
}

.filter-button-container{
  padding: 10px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
  @media all and (min-width: 640px) {
    margin: 0;
  }
}

.program-filters .btn.btn-filter-search{
  background: #e41e31 url(../../images/svg/magnifying-glass-white.svg) no-repeat 50% 50%/65% 65%;
  height: 55px;
  width: 55px;
  padding: 0;
  margin: 0;
  box-shadow: none;
  cursor: pointer;
}


.btn-find-place{
  text-align: center;
  height: 50px;
  font-size: 16px;
  color: #565656;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border: 1px solid #B4B4B4;
  width: 100%;
  span.magnifying-glass-icon{
    width: 20px;
    height: 20px;
    background-image: url(../../images/svg/search-red.svg) no-repeat top left/100% 100%;
    display: inline-block;
    margin-right: 5px;
  }
}

.program-filters{
  background-color: #ffffff;
  margin: 0 auto;
  font-weight: normal;
  color:#00529c;
  font-size: 18px;
  
  line-height: 30px;
  padding: 12px 20px 60px 20px;
  position: relative;
  
  .location-value{
    width: 250px;
  }
  .name-value{
    width: 250px;
  }
  .name-value.entered{
    color: #4a4a4a;
  }
  .filter-text{
    color: #1B1B1B;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .autocomplete-input-container{
    display: none;
    position: absolute;
    top: 0;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    left: -150px;
    top: -45px;
    padding: 15px;
    box-sizing: border-box;
    line-height: 1.15;
    min-height: 80px;
    z-index: 9;
  }
  .autocomplete-input-container.visible{
    display: inline-block;
  }
  .map-program-filter-box {
    .autocomplete-input-container{
      left: -10px;
    }
  }
  .centre-name-autocomplete{
    .autocomplete-input-container{
        left: -10px;
        width: 700px;
    }
  }
  .registration-location-autocomplete{
    .autocomplete-input-container{
        left: -10px;
        width: 500px;
    }
  }
}

.program-filters a{
  text-decoration: none;
  font-size: 16px;
  color: #565656;
  line-height: 30px;
}

.program-filters input:not(.filter-checkbox){
  border: none;
  outline: none;
  width: 100px;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
}

.program-filters .promo-icon{
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: sub;
}

.program-filters input.filter-checkbox{
  -webkit-appearance: checkbox;
}

.checkbox-container{
  display: flex;
  align-items: center;
  margin-top: -7px;
  min-width: 120px;
  label {
    padding: 0 6px 0 10px;
    font-size: 14px;
  }
}

.program-filters a.btn{
  color: #fff;
  margin: 0;
  font-size: 16px;
  line-height: 50px;
  height: 50px;
  padding: 0;
  font-weight: bold;
  text-align: center;
  float: none;
}

input.age-input {
  width: 100px;
  height: 30px;
  font-size: 16px;
  border: none;
  color: #565656;
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  text-align: left;
}

.popup-wrapper {
  position: relative;
}

.filter-popup{
  width:100vw;
  height:100vh;
  z-index:10;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  text-align: center;
  padding: 16px 0 16px;
}

.filter-popup-contents, .filter-popup-inner{
  height: 100%;
}

.filter-popup-body{
  width: 100%;
  text-align: center;
}

.filter-value a{
  padding-bottom: 2px;
  margin: 6px 0 4px 0;
  color: #4a4a4a;
  line-height: 70px;
  height: 70px;
  font-size: 20px;
}


.filter-value a:hover {
  color: $blue;
}

.filter-value.selected a{
  color: $blue;
}

.format.filter-popup{
  .filter-value{
    padding-bottom: 8px;
    margin-bottom: 8px;
    a{
      h4{
        color: $body_text;
        font-size: 16px;
      }
      p{
        color: $body_text;
        margin-top: 0;
        font-size: 14px;
      }
    }
  }
}

.close-filter-popup{
  margin-top: 4px;
  margin-right: 10px;
  margin-bottom: 6px;
}

.program-filters > div{
  position: relative;
}

.program-filters .autocomplete-items{
  width: 100%;
  max-height: 143px;
  overflow-y: scroll;
  z-index:10;
}

.program-filters .autocomplete-items > div{
  height: 36px;
  line-height: 36px;
  padding-left: 11px;
  
  color: #070707;
}

.filter-component {
  background-color: #ffffff;
}

.more-programs-link {
  float: none;
}

.nearby-link-container{
  display: none;
}

.club-finder-filter-component{
  .program-filter-tabs{
    .program-filter-tab, .program-filter-tab.active{
      opacity: 1;
      background-color: white;
      padding-left: 0;
    }
    .program-filter-tab{
      font-size: 14px;
      color: #939393;
      border: none;
    }
    .program-filter-tab.active{
      color: #e41e31;
    }
  }

 
  .program-filters{
    display: flex;
    padding-left: 0;
    .program-filter-box{
      height: 50px;
      line-height: 50px;
      width: 100%;
      border: 1px solid #b4b4b4;
      padding-left: 20px;
      border-radius: 3px 0 0 3px;
    }

    a.filter-button {
      height: 50px;
      line-height: 50px;
      width: 125px;
      text-align: left;
      font-size: 14px;
      font-weight: normal;
      padding: 0 20px;
      border-radius: 3px;
      border: 1px solid #d5d5d5;
      margin-left: 20px;
      color: $body_text;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      justify-content: space-between;
      .down-arrow-icon{
        background-image: url(../../images/svg/DownArrow.svg);
        height: 8px;
        width: 8px;
      }
    }
  }
  .program-filters.location-filter{
    .program-filter-box{
      width: 60%;
    }
  }
}

.close-component{
  background: no-repeat url(../../images/map/icons/chevron_left.svg) 50% 50%/20px 20px;
  width: 20px;
  height: 20px;
}

.mobile-search-component{
  .close-component{
    position: absolute;
    top: 14px;
    left: 8px;
  }
}

.advanced-filter-component{
  height: 100vh;
  height: calc(100vh - var(--vh-offset, 0px));
  width: 100vw;
  z-index: 12;
  padding: 0 12px 50px;
  position: relative;
  margin: 0;
  background-color: white;
  .advanced-filter-container{
    height: 100%;
  }
  .advanced-filter-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid $pale_grey_outlines;
    .close-component-container, .clear-results-link-mobile {
      flex-grow: 1;
      flex-basis: 0;
    }
    .close-component-container{
      display: flex;
      justify-content: flex-start;
    }    
    .clear-results-link-mobile{
      display: flex;
      justify-content: flex-end;
    }
  }
  .advanced-filter-inner{
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    text-align: left;
    .filter-group-container{
      margin: 12px 0 32px;
      border-top: 1px solid $pale_grey_outlines;
      padding-top: 8px;
    }

    .team-type-button-container, .program-format-container{
      margin: 0 -10px;
      .team-type-button-wrapper{
        width: 50%;
        padding: 10px;
        a.btn {
          background-color: white;
          border: 1px solid $disabled-grey;
          color: $disabled-grey;
          font-size: 16px;
          height: 50px;
          line-height: 50px;
          width: 100%;
        }
        a.btn.btn-selected {
          background-color: #00529c;
          border: 1px solid #00529c;
          color: white;
          span.icon-selected{
            height: 14px;
            width: 14px;
            display: inline-block;
            background: no-repeat url(../../images/map/icons/select-on-rev.svg) 0 0/100% 100%;
          }
        }
      }
      .program-format-button-wrapper{
        width: 50%;
        padding: 10px;
        a.btn {
          background-color: white;
          border: 1px solid $pale_grey_outlines;
          color: $body_text;
          
          display:flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 126px;
          background: no-repeat url(../../images/map/icons/select-off.svg) 50% calc(100% - 8px)/20px 20px;
          padding: 6px;
          img {
            height: 40px;
            width: auto;
            margin-top: 12px;
          }
          p {
            font-size: 12px;
            font-weight: 700;
            margin-top: 8px;
          }
        }
        a.btn.btn-selected {
          border: 1px solid #00529c;
          color: white;
          background: no-repeat url(../../images/map/icons/select-on.svg) 50% calc(100% - 8px)/20px 20px;
        }
      } 
    }
    .age-container{
      .age-container-inner{
        display:flex;
        input {
          border: 1px solid #7e7e7e;
          height: 50px;
          width: 100%;
          padding: 0 15px;
        }
        .filter-value{
          text-transform: capitalize;
          height: 60px;
           a {
             margin: 0;
             padding: 10px;
             height: 40px;
             white-space: nowrap;
           }
           a:hover, a:active {
             color: #4a4a4a;
           }
        }
        .filter-value.selected{
          a {
            background-color: #00529c;
            color: white;
            border-radius: 30px;

          }
        }
      }
    }

    .accessibility-toggle-container{
      margin-bottom: 4px;
      padding-top: 12px;
      align-items: center;
      h4{
        flex-grow: 3;
        margin-top: 0;
      }
    }
  }
  a.clear-results-link, 
  a.clear-results-link-mobile{
    display: none;
    color: $blue;
    font-size: 14px;
    line-height: 22px;
    text-decoration: underline;
  }
  a.clear-results-link-mobile{
    display: block;
  }
  .button-container{
    margin: 0;
    width: 100%;
    margin: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 10px;
    left: 0;
    a.btn{
      margin: 0;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 700;
      text-transform: none;
      width: 100%;
    }
    a.clear-results-link{
      display: none;
    }
  }
}

.program-filter-container{
  display:flex;
  flex-direction: column;
  height: 100%;
  .program-filter-outer{
    height: 100%;
    width: 100%;
    .advanced-filter-button{
      position: absolute;
      border: 0;
      right: 0px;
      top: 24px;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      background: no-repeat url(../../images/map/icons/filter.svg) 50% 50%/18px 18px;
      padding-left: 8px;
      font-weight: 400;
      height: 18px;
      width: 18px;
      padding: 0;
      span {
        display: none;
      }
      @media all and (min-width: 1280px) {
        width: 110px;
        padding-left: 16px;
        cursor: pointer;
        background: no-repeat url(../../images/map/icons/filter.svg) 0 50%/18px 18px;
        span {
          display: block;
        }
      }
      
    }
  }
  
}

@media all and (min-width: 768px) {

  .advanced-filter-component{
    max-width: 480px;
    margin: 40px auto 0;
    height: calc(100vh - 80px);
    padding: 16px 0 16px 24px;
    overflow: hidden;
    .advanced-filter-header{
      border-bottom: none;
      padding: 0 24px 0 0;
      .clear-results-link-mobile{
        display: none;
      }
      h3 {
        order: 1;
        flex-grow: 1;
        padding-left: 20px;
      }
      .close-component-container{
        order: 2;
        justify-content: flex-end;
        flex-grow: 0;
        .close-component{
          height: 20px;
          width: 20px;
          background: no-repeat url(../../images/svg/x.svg) 0 0/100% 100%;
        }
      }
    }
    .advanced-filter-container{
      height: 100%;
      .advanced-filter-inner {
        padding-bottom: 0px;
        padding-right: 10px;
      }
    }
    
    
    .button-container{
      position: absolute;
      padding: 10px 24px;
      background-color: white;
      margin-top: 24px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      a.clear-results-link{
        display: block;
        
      }
      a.btn {width: auto;}
    }
  }

  .filter-popup-contents, .filter-popup-inner{
    height: 100%;
  }

  .program-filters .autocomplete-items{
    z-index: 100;
    width: 100%;
  }

  .program-filter-container{
    max-width: 700px;
    margin: 0 0 0 16px;
    padding: 4px;
    border: 1px solid #B4B4B4;
    border-radius: 2px;
    background-color: #FFFFFF;
  }

  .filter-component {
    padding-top: 10px;
    z-index: 8;
    width: 100%;
    background-color: transparent;
  }

  .program-filters {
    padding: 0 0 0 20px;
    text-align: left;
    border-radius: 0 3px 3px ;
    margin: 0;
  }

  .program-filters, .program-filters a, 
  span.filter-value, input.age-input{
    font-size: 14px;
    color: #565656;
  }
  
  .program-filters input:not(.filter-checkbox){
    text-align: left;
  }

  .program-filters a.btn{
    font-size: 20px;
    line-height: 40px;
    height: 40px;
    padding: 0 0 0 20px;
    width: auto;
    text-align: center;
    float: right;
    margin: 0;
  }

  .program-filters a.btn.in-map-btn {
    float: none;
    margin-left: 6px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    vertical-align: 4%;
  }

  .filter-popup{
    position: absolute;
    width: 240px;
    top: -160px;
    z-index: 9999;
    height: 260px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    font-size: 16px;
    .filter-popup-contents {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      .filter-popup-inner{
        padding: 5px;
        margin: 0;
        /* max-height: 250px; */
      }
      
      .filter-popup-body{
        text-align: left;
        padding: 0;  
        margin: 0;
      }
    }
  }

  .filter-popup.format{
    height: fit-content;
  }

  .filter-popup.gender{
    top: -100px;
    height: 135px;
    .filter-popup-contents{
      overflow: hidden;
    }
  }
  .filter-value {
    padding: 0 15px;
    a{
      line-height: 30px;
      height: 30px;
      font-size: 14px;
      text-align: left;
      margin: 0;
      display: inline-block;
      width: 100%;
    }
  }
  .filter-value.selected, .filter-value:hover, .filter-value:focus {
    background-color: #E6EBF3
  }

  .more-programs-link {
    float: right;
  }

  .program-filters {
    display: flex;
    padding: 0;

    .location-filters-section,
    .format-filters-section{
      position: relative;
      margin-right: 10px;
    }

    .name-value.entered {
      width: 550px;
    }
    .location-filters-section:after,
    .format-filters-section:after
    {
      position: absolute;
      content: "";
      background: #979797;
      width: 1px;
      top: 4px;
      bottom: 4px;
      right: 0;
    }

    .location-filters-section{
      width: 40%;
      padding-left: 11px;
    }
    .name-filters-section{
      width: 654px;
      padding-left: 8px;
      height: 55px;
      .name-filter-results{
        height: 35px;
        overflow: hidden;
      }
    }
    .filter-section{
      display: flex;
      flex-direction: column;
      .filter-text{
        padding-top: 9px;
        line-height: 14px;
      }
    }
    .team-filters-section, 
    .age-filters-section,
    .format-filters-section{
      flex-grow: 1;
    }
    .format-filters-section{
      min-width: 220px;
    }
    .team-filters-section {
      min-width: 120px;
    }

    .autocomplete-input-container{
      border:none;
      border-radius: 0;
      background-color: transparent;
      left: -9px;
      padding: 0 ;
      box-shadow: none;
      top: -26px;
      width: 100%;
      input {
        width: 260px;
        padding: 0;
        height: 20px;
        margin-left: 9px;
      }
      .autocomplete-items {
        margin: 20px 0 0 -5px;
        padding: 10px 8px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3);
        max-height: 400px;
        overflow-y: auto;
      }

      .autocomplete-items:empty{
        padding: 0;
        margin: 0;
        display: none;
      }
    }

    .centre-name-autocomplete{
      .autocomplete-input-container{
        input {
          width: 640px;
        }
        .autocomplete-items {
          width: 700px;
          > div {
            overflow: hidden;
          }
        }
      }
    }
  }

  .no-location-dialog{
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    margin: 0;
  }

  


  .program-filters, .program-filters a, 
  span.filter-value, input.age-input{
    font-size: 16px;
  }

  .filter-value {
    a{
      font-size: 16px;
    }
  }

}


@media all and (min-width: 1024px) {

  
  .program-filters {
    .name-value{
      width: 600px;
    }
    .centre-name-autocomplete {
      .autocomplete-input-container {
        width: 700px;
      }
      input {
        width: 600px;
      }
    }
  }

  .program-filters a.btn{
    color: #fff;
    font-size: 26px;
    padding: 0 50px;
    font-weight: bold;
  }

  .program-filters a.btn.in-map-btn {
    line-height: 36px;
    height: 36px;
    font-size: 18px;
    padding: 0 30px;
  }

  .program-filters .autocomplete{
    top: 42px;
  }

  .program-filter-container{
    max-width: 720px;
  }

}


@media all and (min-width: 1280px) {

  .program-filters a.btn{
    color: #fff;
    font-size: 30px;
    line-height: 60px;
    height: 60px;
    padding: 0 50px;
  }

  .program-filters a.btn.in-map-btn {
    line-height: 40px;
    height: 40px;
    padding: 0 30px;
    font-size: 20px;
  }

  .program-filters .autocomplete-items{
    top: 50px;
  }
  .map-criteria .club-finder-filter-component{
    max-width: 1920px;
    
  }

  .program-filter-container{
    margin-left: auto;
    margin-right: auto;
   
  }

}

@media all and (max-width: 767px) {
  .map-criteria{
    transform: none;
    transition: transform 0.25s ease-in;
    height: 0;
  }

  .map-criteria.is-active{
    top: 0;
    z-index: 11;
  }

  .map-criteria.hide-filter{
    transform: translateY(100vh);
    -webkit-transform: translateY(100vh);
    -webkit-transform: translateY(500px);
    transition: transform 0.25s ease-in;
  }

  .ios-safari .advanced-filter-component{
    padding-bottom: 140px;
  }

  .mobile-map-filter-container{
    position: fixed;
    top: 50px;
    height: 70px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    z-index: 9;
    background-color: white;
    display: flex;
    align-items: center;
    .mobile-map-filter-inner{
      height: 100%;
      border: 1px solid #7e7e7e;
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-left: 40px;
      background: no-repeat url(../../images/svg/search-grey.svg) 15px 50%/20px 20px;
      background-color: white;
      .location-value, .name-value{
          flex-grow: 3;
          color: black;
          text-overflow: ellipsis;
      }
    }
    .mobile-map-filter-button, .mobile-map-view-type-button{
      padding: 10px 0;
      width: 40px;
      height: 40px;
      background: no-repeat url(../../images/map/icons/filter.svg) 100% 50%/24px 24px;
    }
    .mobile-map-filter-button.filter-on {
      background: no-repeat url(../../images/map/icons/filter_on.svg) 100% 50%/24px 24px;
    }
    .mobile-map-view-type-button{
      background: no-repeat url(../../images/map/icons/list.svg) 100% 50%/24px 24px; /* defaults to show list icon */
    }
    .mobile-map-view-type-button.map-button{
      background: no-repeat url(../../images/map/icons/map.svg) 100% 50%/24px 24px;
    }
  }

  .mobile-search-component, .advanced-filter-component{
    

    h3 {
      color: $blue;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      margin-top: 0;
    }

    h4 {
      font-size: 16px;
      color: $blue;
      margin: 14px 0 4px;
    }

    .program-filter-tabs{
      justify-content: center;
      height: 50px;
      margin-bottom: 10px;
      .program-filter-tab{
        background-color: white;
        border: none;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        color: $blue;
        opacity: 1;
        padding: 16px 16px 10px;
        margin-bottom: 0;
        span {
          padding: 0 0 12px;
        }
      }

      .program-filter-tab.active{
        background-color: white;
        padding: 16px 16px 0 16px;
        span{
          border-bottom: 3px solid #e41e31;
          padding: 0 0 12px;
          width: 100%;
        }
      }
    }
    .promos-container {
      padding: 0 10px;
      margin: 2px 0 16px;
    }
    input.filter-checkbox{
      -webkit-appearance: checkbox;
      margin-left: 6px;
    }

    .promo-icon{
      display: inline-block;
      height: 24px;
      width: 24px;
      vertical-align: text-bottom;
    }
  }

  .mobile-expanding-autocomplete-container.mobile-search-component {
    .mobile-expanding-autocomplete-inner > div{
      .autocomplete-input-container{
        border: 1px solid #D5D5D5;
        input{
          color: #4a4a4a;
          background:none;
          padding: 3px 0 0;
          border:none;
          line-height: 16px;
        }
      }
    }
    .mobile-expanding-autocomplete-inner {
      .autocomplete-items{
        position: absolute;
        left: -20px;
        right: -20px;
        padding-left: 24px;
        font-size: 16px;
        width: 100vw;
        color: #4a4a4a;
        div {
          margin: 0 !important;
          width: 100%;
          padding: 10px 20px 10px 30px;
        }
      }

      .cancel-autocomplete-icon{
        right: 10px;
      }
    }

  }
  .autocomplete-input-container.visible {
    display: flex;
    > div {
      width: 100%;
    }
    .autocomplete-button{
      height: 50px;
      width: 38px;
      display: inline-block;
      background: no-repeat url(../../images/map/icons/search.svg) 50% 50%/20px 20px ;
    }

  }
  .nearby-link-container{
    display: block;
    padding: 12px 10px 4px 38px;
    background: no-repeat url(../../images/map/icons/search.svg) 8px 10px/20px 20px ;
    a {
      color: #4a4a4a;
      font-size: 16px;
    }
  }

}

/* iphone X */
@media only screen and (min-device-width: 375px)
and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .ios-safari .advanced-filter-component{
      padding-top: 0;
    }
}

/* iphone XR */
@media only screen and (min-device-width : 414px)
and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
  .advanced-filter-component{
    padding-bottom: 130px;
  }
  .ios-safari .advanced-filter-component{
    padding: 0 10px 60px;
  }
}

/* iphone 11 + 12 */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3)
    and (orientation : portrait) { 
      .advanced-filter-component{
        padding-bottom: 150px;
      }
      .ios-safari .advanced-filter-component{
        padding: 0 10px 150px;
      }
}

@media only screen 
 and (min-width: 768px)
 and (max-height: 830px) {
   .advanced-filter-component{
     padding-bottom: 120px;
   }
}

@media only screen 
 and (min-width: 768px)
 and (min-height: 830px) {
   .advanced-filter-component{
     height: 800px;
     .advanced-filter-container{
      .advanced-filter-inner{
        overflow: hidden;
        padding-right: 24px;
      }
      .button-container {
        padding-bottom: 24px;
      }
     }
   }
}

/** Ipad */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  .advanced-filter-component{
    height: 700px;
    .advanced-filter-inner{
      padding-right: 24px;
    }
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .advanced-filter-component{
    height: 700px;
    .advanced-filter-inner{
      padding-right: 14px;
      padding-left: 14px;
    }
  }
}

