$footer-vertical-spacing: 17px;

/**
Social Prefooter
**/
.social-prefooter-container {
  .social-prefooter {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    .social-prefooter-column {
      padding: 40px 0;
      box-sizing: border-box;

      h3 {
        font-size: responsive 28px 40px; /* min-size, max-size */
        font-range: 640px 1280px;
        margin-bottom: 12px;
        color: $blue;
        font-family: "PODIUMSharp-211", Impact, Haettenschweiler,
          "Arial Narrow Bold", sans-serif;
        font-weight: normal;
        letter-spacing: 1.33px;
        text-transform: uppercase;
        line-height: 1;
      }

      p,
      p a {
        font-size: 18px;
        margin: 10px 0;
      }

      p a {
        color: #4a90e2;
        text-decoration: underline;
      }

      p.large,
      p.large-blue {
        font-size: responsive 20px 24px; /* min-size, max-size */
        font-range: 420px 1280px;
        margin-bottom: 2px;
      }

      p.large-blue {
        font-weight: bold;
        color: $blue;
      }

      p.smaller {
        font-size: 16px;
        margin: 0;
      }

      p.smaller-grey {
        font-size: 14px;
        color: #9b9b9b;
        margin-top: 2px;
      }

      a.dark.smaller {
        font-size: 16px;
        font-size: responsive 16px 18px; /* min-size, max-size */
        font-range: 420px 1280px;
        color: #070707;
      }

      .support-buttons-group {
        .support-button-container {
          margin: 0 0 24px;
          text-align: center;
          .btn-badge {
            margin: 0 auto 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 54px;
            height: 54px;
            color: white;
            background-color: #0d4f9c;
            border-radius: 50%;
            font-size: 28px;
            i {
              margin: 2px 0 0 2px;
            }
          }
        }
      }

      .social {
        text-align: center;
        float: none;

        a {
          color: #9b9b9b;
          text-decoration: none;
          margin: 8px;

          i {
            font-size: 40px;
          }
          i.icon-facebook-official {
            font-size: 40px;
            vertical-align: 6%;
          }
          svg {
            width: 40px;
            height: 40px;
          }
        }
        a:hover {
          color: $social_blue;
        }
      }
    }
  }
  .social-prefooter.prefooter-auskick {
    .social-prefooter-column {
      h3 {
        font-family: "filson-soft";
        text-transform: none;
        letter-spacing: 0;
        font-weight: 700;
        font-size: responsive 24px 30px;
        font-range: 640px 1280px;
      }
    }
  }
}

.social-prefooter-container.no-social-prefooter {
  .social-prefooter {
    .social-prefooter-column:first-of-type {
      border-bottom: none;
      border-top: 1px solid #9b9b9b;
      margin-top: 40px;
    }
  }
}

/**

The proper footer
*/
.footer-container {
  background: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  .footer-acknowledgement {
    color: $body_text;
    font-size: 14px;
    line-height: 22px;
    padding: 30px 20px 50px;
  }
  .footer {
    background-color: #023c91;
    .footer-inner {
      .footer-logo-container {
        padding-bottom: 15px;
        .footer-afl-logo {
          width: 140px;
          height: auto;
          margin-top: -20px;
        }
      }
      .footer-text {
        background-color: white;
        padding: 25px 0 30px;

        ul.footer-links {
          list-style-type: none;
          margin: 8px 0 0 0;
          padding: 0;
          li {
            padding: 6px 7px;
          }
        }

        .afl-copyright {
          margin-bottom: 10px;
        }
        .afl-copyright,
        a {
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          transition: opacity 0.25s;
          color: $footer-link-color;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.social {
  float: right;
  margin-top: 9px;
}

a.social-link {
  margin-left: 16px;
}

@media all and (min-width: 640px) {
  .footer.footer-landing {
    padding-top: 0;
  }

  .support-buttons-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .support-button-container {
      padding: 0 16px;
      margin: 0;
      box-sizing: border-box;
    }
  }

  .no-social-prefooter {
    .support-buttons-group {
      flex-direction: column;
    }
  }

  .social-prefooter-container {
    .social-prefooter {
      padding: 0 40px;
    }

    .social-prefooter-column {
      .social {
        margin-top: 14px;
        a {
          margin: 0 12px;
        }
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .support-buttons-group {
    margin-top: 10px;
  }

  .social-prefooter-container {
    .social-prefooter {
      display: flex;
      flex-direction: row;
      padding: 25px 0;

      .social-prefooter-column {
        width: 50%;

        h3 {
          margin-bottom: 16px;
        }

        .social {
          margin-top: 18px;
          a {
            margin: 0 14px;

            i {
              font-size: 46px;
            }
            i.icon-facebook-official {
              font-size: 46px;
              vertical-align: 6%;
            }
            svg {
              width: 46px;
              height: 46px;
            }
          }
        }
      }

      .social-prefooter-social-column p {
        max-width: 220px;
        margin-left: auto;
        margin-right: auto;
      }
      .social-prefooter-column:first-of-type {
        border-bottom: none;
        /* border-right: 1px solid #9b9b9b; */
      }
    }
  }

  .social-prefooter-container.no-social-prefooter {
    .social-prefooter {
      .social-prefooter-column {
        width: 100%;
      }
      .social-prefooter-column:first-of-type {
        border-right: none;
        border-top: 1px solid #9b9b9b;
      }
    }
  }

  .footer-container {
    .footer-acknowledgement {
      width: 845px;
      margin: 10px auto;
      font-size: 15px;
      padding: 0 0 25px;
    }
    .footer {
      padding-top: 8px;
      .footer-inner {
        display: flex;
        .footer-logo-container {
          width: 250px;
          .footer-afl-logo {
            margin: -30px 20px 0;
          }
        }
        .footer-text {
          flex-grow: 5;
          display: flex;
          padding: 0 0 0 40px;
          height: 90px;
          align-items: center;
          .afl-copyright {
            margin: 0 30px 0 0;
          }
          ul.footer-links {
            display: flex;
            margin: 0;
            li {
              padding: 0 12px 0 18px;
              position: relative;
            }
            li::before {
              position: absolute;
              left: 0;
              content: "•";
              color: #7e7e7e;
            }
            li:first-of-type::before {
              content: "";
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1280px) {
  .support-buttons-group {
    .support-button-container {
      padding: 0 30px;
    }
  }

  .social-prefooter-container {
    .social-prefooter {
      .social-prefooter-column {
        h3 {
          margin-bottom: 20px;
        }
      }

      .social-prefooter-social-column p {
        max-width: 640px;
      }
    }
  }
}

@media all and (min-width: 1480px) {
  .support-buttons-group {
    .support-button-container {
      padding: 0 40px;
    }
  }

  .social-prefooter-container {
    .social-prefooter {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;

      .social-prefooter-column {
        .social {
          margin-top: 20px;
          a {
            margin: 0 13px;

            i {
              font-size: 52px;
            }
            i.icon-facebook-official {
              font-size: 52px;
              vertical-align: 9%;
            }
            svg {
              width: 52px;
              height: 52px;
            }
          }
        }
      }
    }
  }
}

.social-prefooter-container
  .social-prefooter
  .social-prefooter-column
  .social
  a:hover
  svg
  path {
  fill: $social_blue;
}

@media all and (max-width: 1023px) {
  .social-prefooter-container {
    .social-prefooter {
      .social-prefooter-column {
        .social a {
          i {
            font-size: 26px;
          }
          i.icon-facebook-official {
            font-size: 26px;
            vertical-align: 9%;
          }
          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
}
