/* golden rego hero */

.has-notification-bar {
  .golden-rego-hero {
    margin-top: 132px !important;
    @media (max-width: 639px) {
      margin-top: 118px !important;
    }
  }
  .auskick-prize-page {
    @media (max-width: 639px) {
      padding-top: 62px;
    }
  }
}

.golden-rego-hero {
  height: auto;
  margin-top: 82px;

  .left-center-text {
    left: 40px;

    @media (max-width: 639px) {
      width: 100%;
      height: fit-content;
      position: absolute;
      top: 2%;
      left: 0;
      padding: 0px 10px;
    }

    h1 {
      font-size: 64px;
      color: #01285e;
      max-width: 100% !important;
      @media (max-width: 639px) {
        max-width: 100%;
        text-align: center;
        font-size: 48px;
      }
      @media (min-width: 640px) and (max-width: 1023px) {
        font-size: 42px;
      }
      @media (min-width: 1024px) {
        font-size: 64px;
      }
    }
    p {
      @media (max-width: 639px) {
        text-align: center;
        font-size: 20px !important;
      }
    }
    p.subheading {
      font-size: 20px;
      color: #070707;
    }
    @media (min-width: 640px) {
      width: 40%;
    }
  }

  &::before {
    background-image: none !important;
  }
  @media (max-width: 639px) {
    height: 570px;
    margin-top: 50px;
  }
  @media (min-width: 520px) and (max-width: 639px) {
    height: 70vh;
  }
}

/* auskick prize page text */

.auskick-prize-page {
  padding-top: 70px;
  .auskick-prize-text {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #01285e;

    @media screen and (max-width: 639px) {
      font-size: 14px;
    }
  }
  .auskick-prize-text.subheading {
    width: 80%;
    margin: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #070707;

    @media screen and (max-width: 639px) {
      font-size: 20px;
    }
  }
  .auskick-prize-title {
    font-family: PODIUMSharp-211, Impact, Haettenschweiler, Arial Narrow Bold,
      sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #02295d;
    word-break: break-word;
    padding: 1rem 0;

    @media (max-width: 639px) {
      width: 95%;
      margin: auto;
    }
    @media screen and (max-width: 1023px) {
      font-size: 48px;
    }
  }
}

/* golden rego find club section */
.golden-rego-find-club-section {
  background: #023c91;
  padding: 20px 10px;

  .auskick-find-centre-section-inner {
    p {
      font-family: PODIUMSharp-211, Impact, Haettenschweiler, Arial Narrow Bold,
        sans-serif;

      @media (max-width: 639px) {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
  @media (max-width: 639px) {
    min-height: 240px;
    max-height: 350px;
    justify-content: flex-end;
  }
}

/* golden rego prize */

.header-prize {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto 2rem 0;

  h2 {
    font-family: "Montserrat", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px !important;
    line-height: 36px !important;
    letter-spacing: -0.01em;
    color: #02295d;
    padding-left: 20px;

    @media screen and (max-width: 767px) {
      font-size: 24px !important;
      line-height: 26px;
      padding-left: 0;
      margin-bottom: 1.2rem;
    }
    @media (min-width: 640px) {
      font-size: 32px;
    }
    @media (min-width: 1920px) {
      padding-left: 50px !important;
      margin-left: 0;
    }
  }

  a {
    font-family: "Montserrat", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #023c91;
    text-decoration: underline;
    margin-right: 3.5rem;

    &:hover {
      text-decoration: underline;
    }
    @media (min-width: 1920px) {
      margin-right: 5rem;
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (min-width: 640px) and (max-width: 767px) {
    padding-left: 30px;
  }
  @media (min-width: 1920px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
  }
}

/* prize content field sections */
.field-name-field-prize-content-sections {
  .field-label {
    display: none;
  }
  .field-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100% !important;

    .field-item {
      .activity-section-text {
        .activity-section-text-inner {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #070707;
          padding: 0;
          text-align: left;
          h2 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            font-size: 18px !important;
            line-height: 24px;
            color: #02295d;
            padding-left: 0;
            text-align: left;
          }
        }
        .footy-stories-title {
          h2 {
            color: #023c91;
            /* &::after {
              border-style: solid;
              border-width: 0.2em 0.2em 0 0;
              content: "";
              display: inline-block;
              height: 0.35em;
              left: 10px;
              position: relative;
              top: -3px;
              transform: rotate(45deg);
              vertical-align: top;
              width: 0.35em;
            } */
          }
        }
      }
      .activity-section-image {
        @media (min-width: 640px) {
          overflow: hidden;
        }
        img {
          @media (min-width: 640px) {
            width: 100%;
            height: auto;
            position: relative;
          }

          &::before {
            @media (min-width: 640px) {
              content: " ";
              display: block;
              padding-bottom: calc(65.8% - 16px);
            }
          }
          &::after {
            @media (min-width: 640px) {
              align-items: center;
              background-color: #f8f8f8;
              content: " " attr(alt);
              display: flex;
              font-weight: 700;
              height: 100%;
              justify-content: center;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }
        }
      }

      @media (min-width: 640px) and (max-width: 869px) {
        width: 45%;
        margin-right: 20px;
      }
      @media (min-width: 1024px) {
        width: calc(25vw - 33.33333px);
        margin-right: 20px;
      }
      @media (min-width: 1280px) and (max-width: 1919px) {
        margin-right: 30px;
        width: calc(25vw - 41px) !important;
      }
      @media (min-width: 1920px) {
        min-width: 458px !important;
        width: 458px !important;
        margin-right: 0;
      }
    }
    @media screen and (max-width: 520px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: 520px) and (max-width: 1023px) {
      display: flex;
      flex-direction: unset;
    }
    @media (min-width: 1920px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1920px !important;
    }
  }
}
/* hero bottom summary text */

.field-type-text-with-summary {
  .field-items {
    .field-item {
      p.rtecenter {
        max-width: 1100px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #070707;
        margin: 0 auto 1rem auto;
        &:not(:first-child) {
          max-width: 1100px;
          margin: auto;
          margin-bottom: 1.5rem;
          text-align: center;
          padding: 0;

          @media (max-width: 767px) {
            font-size: 20px !important;
          }
        }
        @media (max-width: 640px) {
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding-top: 30px;
  }
  @media (max-width: 767px) {
    padding-top: 25px;
  }
}

.auskick-bg-img-class {
  .field-name-field-pagecards-content-sections {
    .field-items {
      .field-item {
        background: #f8f8f8 !important;
        .activity-section-text {
          .activity-section-text-inner {
            h2 {
              font-family: PODIUMSharp-211, Impact, Haettenschweiler,
                Arial Narrow Bold, sans-serif;
            }
          }
        }
      }
    }
  }
  .auskick-columns-section {
    padding: 0;
  }
}
.auskick-prize-page {
  .footy-stories-filson-title {
    font-family: "filson-soft", Arial !important;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #02295d;
    text-transform: inherit;
  }
}
