
.node-curriculum-activity, .node-curriculum-overview{
  .curriculum-header-container,
  .curriculum-header-container.has-video{
    padding-top: 15px;
    padding-bottom: 0;
    @media all and (min-width: 1024px) {
      padding-top: 20px;
    }
    @media all and (min-width: 1280px) {
      padding-top: 30px;
    }
  }

  h1 {
    margin-bottom: 10px;
  }
}

.node-curriculum-overview{
  .video-iframe-container{
    .header-image-container{
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.curriculum-hero-container{
  max-width:1280px;
  margin:0 auto;
}

.curriculum-header-info-container{
  margin-top:2rem;
}


.curriculum-header-container .breadcrumbs-container{
  text-align: center;
  margin: 0 0 10px;
  padding: 0;

}

.video-iframe-container{
  position:relative;
}

.video-iframe-container::after{
  content:" ";
  position:absolute;
  display:block;
  padding-bottom:56.25%;
  top:0;
  left:0;
}

.video-iframe-container iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border:none;
}

.video-iframe-container .header-image-container{
  width:100%;
}

.curriculum-level, .curriculum-tag{
  color:#fff;
  height:24px;
  line-height:24px;
  font-size:14px;
  font-weight:bold;
  border-radius:12px;
  padding:0 12px;
  display:inline-block;
  margin-right:12px;
  vertical-align:60%;
  background-color:#b4b4b4;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-shadow:none;
}

.curriculum-level.level-1, .curriculum-level-heading.level-1{
  background-color:#ff8224;
}

.curriculum-level.level-2, .curriculum-level-heading.level-2{
  background-color:#1693af;
}

.curriculum-level.level-3, .curriculum-level-heading.level-3{
  background-color:#592c82;
}

.curriculum-container{
  padding:0;
}

.curriculum-listings-container{
  padding:0;
}

.curriculum-tag-breadcrumb, .curriculum-level-breadcrumb, h4.preheading {
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  color: #02295D;
  font-size: 16px;
  line-height: 19px;
}

.curriculum-level-breadcrumb{
  margin-right: 0;
  padding-right: 0;
}


span.blue-chevron{
  background: no-repeat url(../../images/svg/chevron-right-blue.svg) 50% 50%/100% 100%;
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: -35%;
}

.curriculum-level-heading.level-1{
  background-color:#ff8224;
}

.curriculum-title{
  padding-right:4px;
}

.curriculum-title.level-1{
  color:#ff8224;
  padding-right:4px;
}

.tabs-links-controls{
  padding-top: 20px;
}

.curriculum-level-heading.level-2{
  background-color:#1693af;
}


.curriculum-title.level-2{
  color:#1693af;
}

.curriculum-level-heading.level-3{
  background-color:#592c82;
}

.curriculum-title.level-3{
  color:#592c82;
}

.activity-controls{
  display:flex;
  justify-content: center;
  margin: 4px 0 14px;
}

.activity-widget{
  width: 24px;
  height: 24px;
  display:inline-block;
  background-size:100% 100%;
  background-position:center;
  background-repeat:no-repeat;
  vertical-align: bottom;
}

a.download-link:hover{
  text-decoration:none;
}

.activity-widget.share-control{
  background-image:url(../../images/svg/share.svg);
}


.activity-widget-container:hover .activity-widget.share-control{
  background-image:url(../../images/svg/share_hover.svg);
}


.activity-widget.download-pdf{
  background-image:url(../../images/svg/download_pdf.svg);
}


.activity-widget-container.download-widget:hover .activity-widget.download-pdf{
  background-image:url(../../images/svg/download_pdf_hover.svg);
}


.activity-widget-container:hover .widget-label, .activity-widget-container:hover .widget-label a{
  color:#0a549a;
}


.activity-widget-container{
  padding: 0 20px;
  text-align:center;
}


.widget-label, .widget-label a{
  display:inline-block;
  font-size:13px;
  line-height: 24px;
	font-weight: 600;
	text-align:center;
  vertical-align:top;
	color: $blue;
}

.vud-widget{
  display:flex;
}


.curriculum-level-container{
  font-size:18px;
	font-weight:800;
	color:#0a549a;
  position:relative;
  margin-bottom:30px;
  margin-top:30px;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-shadow:none;
}


.curriculum-level-heading{
  height:30px;
  line-height:30px;
	border-radius:15px;
  padding:0 9px 0 15px;
  font-size:18px;
	font-weight:800;
  color:#ffffff;
  margin-right:8px;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  display:inline-block;
}


.curriculum-level-heading i{
  vertical-align:0%;
    margin-left:2px;
    font-size:14px;
}


.curriculum-level-selector{
  display: flex;
}

.curriculum-level-selector .btn{
  margin-right: 10px;
}


.activity-block{
  text-align: left;
  a {
    height: 100%;
    width: 100%;
  }
}

.node-curriculum-landing 
.auskick-activity-list-section{
  .activity-footer p{
  
    font-size:14px;
    line-height:1.4;
    font-weight: 300;
    color:#070707;
    padding: 0;
    margin-top: 6px;
  }
}


.activity-footer h4{
  margin-top: 14px;
	color:#02295D;
}


.activity-block img{
  max-width:100%;
  min-width:100%;
  height:auto;
  vertical-align:middle;
}


.activity-block .activity-image-container{
  margin-bottom:6px;
  position:relative;
}


.activity-block .activity-image-container .actvitity-video-icon{
  position:absolute;
  display:block;
  top:50%;
  left:50%;
  width:30px;
  height:30px;
  background: url(../../images/play-video.png) top left/100% 100%;
  transform:translate(-50%, -50%);
  transition:transform .25s ease-in-out,-webkit-transform .25s ease-in-out
}

.activity-block .activity-image-container:hover .actvitity-video-icon{
  background-image: url(../../images/play-video-hover.png);
}

.activity-levels{
  display:block;
  height:18px;
}


.activity-levels .curriculum-level{
  height:18px;
  width:18px;
  padding:0;
  background-repeat:no-repeat;
  background-size:100% 100%;
  margin:4px 2px 0 0;
}

.activity-levels .curriculum-level.level-1{
  background-image:url(../../images/svg/level_1.svg);
}

.activity-levels .curriculum-level.level-2{
  background-image:url(../../images/svg/level_2.svg);
}

.activity-levels .curriculum-level.level-3{
  background-image:url(../../images/svg/level_3.svg);
}


.activity-levels .level-header{
  font-size:12px;
  color:#4a4a4a;
  font-weight: 800;
  margin-right:4px;
  vertical-align:top;
  padding-top:6px;
  display:inline-block;
}

.long-activities-row-block{
  margin: 10px 0 12px;
  position:relative;
}


.round-scroller-button{
  display:block;
  position:absolute;
  top:40%;
  -webkit-transform:translateY(-50%);
      -ms-transform:translateY(-50%);
          transform:translateY(-50%);
}


.scroller-button-next{
  right:-20px;
}


.scroller-button-prev{
  left:-20px;
}


.round-scroller-button{
  display:none;

  background-color:#023C91;
	-webkit-box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.5);
	        box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width:34px;
  height:34px;
  text-align:center;
  line-height:34px;
  text-decoration:none;
  color:#ffffff;
  font-size:24px;
  line-height: 24px;
  z-index:9;
}


.round-scroller-button i{
  vertical-align:-10%;
}


.activity-lead-text, 
h3.activity-gear-title{
  font-family: 'Montserrat', 'Helvetica-Nueue', sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: $body_text;
  line-height:1.62;
}

.activity-lead-text{
  text-align: center;
  margin: 0 auto 20px;
  max-width: 335px;

}

.activity-section{
  margin:20px 0;
}


.activity-section-text{
  margin:10px 0;
}


a.next-tab{
  text-transform:none;
}


a.next-tab i{
  vertical-align:-2%;
}


.activity-tips-container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  margin:10px 0 20px;
  max-height:280px;
  overflow:hidden;
  -webkit-transition:max-height 0.35s ease-in-out;
  -o-transition:max-height 0.35s ease-in-out;
  transition:max-height 0.35s ease-in-out;
}


.activity-tips-item{
  margin-bottom:10px;
}


.activity-tips-item img{
  margin-bottom:14px;
}


.activity-tips-container.open{
  max-height:99999px;
  overflow:auto;
}


.lead-text{
  margin:20px auto 30px;
  padding: 10px;
  text-align: center;
}


.lead-text h3{
  margin-bottom:20px;
}


.no-image .activity-section-text-inner{
  margin:4px 20px;
}


.cms-content .activity-section-text-inner p, .aim-tab .cms-content p{
  font-size:18px;
  margin:18px 0;
}


.curriculum-features .curriculum-feature{
  position:relative;
  margin-bottom:14px;
  box-sizing:border-box;
}


.curriculum-features .curriculum-feature .text-container{
    top:20px;
    left:10px;
    @media all and (min-width: 1024px) {
      left: 30px;
    }
    width:65%;
    z-index:9;
}


.white-spacer{
  margin-bottom:20px;
}


.curriculum-features .curriculum-video-link{
  position:absolute;
  top:50%;
  left:50%;
          transform:translate(-50%, -50%);
  background-image:url(../../images/play-video.png);
  background-repeat:no-repeat;
  background-size:100% 100%;
  width:36px;
  height:36px;
  display:none;
  @media all and (min-width: 1024px) {
    width:50px;
    height:50px;
    display:block;
  }
}


.curriculum-features .curriculum-feature:hover .curriculum-video-link{
  background-image:url(../../images/play-video-hover.png);
}


.curriculum-features .blocklink{
  top:0;
}


.curriculum-mobile-header{
  margin:30px 0 30px 20px;
  font-size:30px;
}

.node-curriculum-landing .lead-text {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.node-curriculum-landing .lead-text p{
  margin:16px 0;
  line-height: 1.667;
}


.cms-content .activity-section-text ul{
  margin-left:4px;
  padding-left:12px;
  list-style-type:disc;
}


.cms-content .activity-section-text ul>li{
  list-style-type:disc;
  padding-left:0;
  font-size:18px;
}


.cms-content .activity-section-text ul>li:before{
  content:"";
  padding-right:0;
}


.activity-section-downloads{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  margin-bottom:30px;
}


.activity-section-downloads .download-widget{
  width:50%;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  text-align:left;
  margin-bottom:4px;
}


.gear-section-container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}


.gear-section-container .activity-section{
  text-align:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  padding:10px 20px;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1;
}


.gear-section-container .activity-section:nth-of-type(2n){
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  padding-left:0;
  padding-right:0;
}


.gear-section-container .activity-section .activity-section-image{
  -webkit-box-ordinal-group:1;
      -ms-flex-order:0;
          order:0;
  max-width:80%;
  min-width:50%;
  margin-left:auto;
  margin-right:auto;
  padding:0;
}


.gear-section-container .activity-section:nth-of-type(2n) .activity-section-image{
  padding:0;
}


.gear-section-container .activity-section .activity-section-text{
  -webkit-box-ordinal-group:2;
      -ms-flex-order:1;
          order:1;
  margin-right:0;
}


.gear-section-container .activity-section:nth-of-type(2n) .activity-section-text{
  margin-left:0;
}


.gear-section-container .activity-section .activity-section-text h3{
  font-size:24px;
  margin:10px 0 0;
}


.gear-section-container .activity-section .activity-section-text p{
  font-size:14px;
  margin:2px 0 10px;
}


.gear-section-container .activity-section .activity-section-text .activity-section-text-inner{
  position:static;
  -webkit-transform:none;
      -ms-transform:none;
          transform:none;
}


.activity-gear-title{
    margin-top:20px;
}


.activity-gear-intro{
  margin-top:10px;
}


.activity-tips-downloads{
  margin:20px 0 30px;
  padding-top:20px;
  border-bottom:1px solid #dbdbdb;
  border-top:1px solid #dbdbdb;
}


.activity-tips-downloads-container{
  margin:20px 0 30px;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
}


.activity-tips-downloads .activity-widget-container{
  text-align:left;
  margin-bottom:10px;
}


.activity-tips-downloads .activity-widget-container .widget-label, .activity-tips-downloads .activity-widget-container .widget-label a{
  font-size:16px;
}

.activities-title-container{
  margin-top: 10px;
}


@media all and (min-width: 640px) {
  .activity-heading-container{
    position: relative;
  }

  .activity-heading-container{
    position:relative;
  }

  .curriculum-level-container{
    margin-top: 40px;
  }

  .tabs-links-controls {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D8D8D8;
    .activity-controls {
      order: 1;
      margin: 0;
    }
    .tab-links {
      order: 0;
      margin: 0;
      padding-top: 0;
      border: none;
    }
  }

  .long-activities-row-viewport{
    width:100%;
    width:calc(100vw - 40px);
    overflow:hidden;
    position:relative;
    margin:0 20px;
    height:310px;
  }

  .long-activities-row-inner{
    position:absolute;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width: 2500px;
  }

  .long-activities-row-inner .activity-block{
    width: 270px;
    margin-right:20px;
  }

  .activity-block .activity-footer {
	  margin: 10px 0;
  }

  .long-activities-row-inner .activity-block .activity-image-container, .hover-image-container{
    overflow:hidden;
  }

  .long-activities-row-inner .activity-block img{
    width:100%;
    height:auto;
    transform:translateZ(0) scale(1, 1);
    transition:transform 0.25s ease-in-out;
    position: relative;
  }

  .long-activities-row-inner .activity-block img::before{
    content: " "; 
    display: block;
  
    padding-bottom: calc(65.8% - 16px);
  }

  .long-activities-row-inner .activity-block img::after{
    content: " " attr(alt);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #f8f8f8;
  }


  .long-activities-row-inner .activity-block img:hover{
    transform:scale(1.1) translateZ(0px);
  }


  .round-scroller-button{
    display:block;
    position:absolute;
    top: 24%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    transform:translateY(-50%);
    color:#ffffff;
  }

  .round-scroller-button:hover{
    background-color: #0452c3;
  }

  .scroller-button-next{
    right: -10px;
  }

  .scroller-button-prev{
    left:0;
  }

  .activity-lead-text,
  h3.activity-gear-title{
    font-size: 18px;
    max-width: 600px;
    margin: 10px auto 25px;
  }

  .activity-tips-container{
    max-height:99999px;
    overflow:auto;
    flex-direction:row;
        flex-wrap:wrap;
    margin-left:-10px;
    margin-right:-10px;
  }

  .activity-tips-item{
    width:33%;
    padding:10px;
    box-sizing:border-box;
  }

  .show-tips{
    display:none;
  }

  .lead-text{
    margin:40px auto 20px;
    text-align:center;
  }

  .curriculum-features{
      display:flex;
      flex-direction: column;
      margin:0 auto;
      box-sizing:border-box;
      padding:0;
      max-width: 540px;
    .curriculum-feature{
      box-sizing:border-box;
      padding: 0;
      overflow:hidden;
      position:relative;
      margin-bottom: 20px;
    }
  }


  .curriculum-features .curriculum-feature .text-container{
      top:20px;
      left:30px;
      width:70%;
  }

  .curriculum-features .curriculum-feature img{
    top:0;
    left:0;
    width:100%;
    height:100%;
    -webkit-transform:translateZ(0) scale(1, 1);
    transform:translateZ(0) scale(1, 1);
    -webkit-transition:-webkit-transform 0.25s ease-in-out;
    transition:-webkit-transform 0.25s ease-in-out;
    -o-transition:transform 0.25s ease-in-out;
    transition:transform 0.25s ease-in-out;
    transition:transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    vertical-align:middle;
  }

  .curriculum-features .curriculum-feature:hover img{
    -webkit-transform:scale(1.1) translateZ(0px);
            transform:scale(1.1) translateZ(0px);
  }

  .white-spacer{
    height:20px;
    background-color:white;
  }

  .node-curriculum-landing .lead-text p{
    margin:12px 0;
  }

  .activity-tips-downloads-container{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -ms-flex-wrap:wrap;
        flex-wrap:wrap;

  }

  .activity-tips-downloads .activity-widget-container{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0;
    margin-right:16px;
  }
  .up-current-score, .down-current-score{
    display:inline-block;
    text-align:left;
    font-size:18px;
    vertical-align:60%;
    margin-top:0;
  }

  .vud-widget-helpful-buttons{
    .up-current-score, .down-current-score{
      text-align: center;
    }
  }


}

@media all and (min-width: 1024px){

  .curriculum-listings-container.resources{
    margin-right: 0;
  }

  .curriculum-features{
    flex-direction: row;
    margin:0 -15px;
    padding:0;
    max-width: none;
    .curriculum-feature{
      width: 33.33%;
      padding: 0;
      margin: 0 15px;
    }
  }

  .long-activities-row-inner .activity-block{
    /*width:calc((100vw - 120px) / 4);*/
    width: calc(25vw - 33px);  /**Safari doesn't always like decimal px? */
    width: calc(25vw - 33.33333px);
  }

  .long-activities-row-viewport{
    margin: 0;
    width: 100%;
  }

  .activity-section-container{
    display:flex;
    flex-direction:column;
  }

  .activity-section{
      display:flex;
      flex-direction:row-reverse;
      margin:40px 0;
  }

  .activity-section:nth-of-type(2n){
    flex-direction:row;
  }

  .activity-section-image{
    max-width:50%;
    min-width:50%;
    padding:0;
  }

  .activity-block .activity-footer {
	  margin:10px 0;
  }

  .activity-section-text{
    margin-right:20px;
    position:relative;
    width:100%;
  }

  .activity-section:nth-of-type(2n) .activity-section-text{
    margin-left:20px;
  }

  .activity-section:nth-of-type(2n) .activity-section-image{
    padding-right:20px;
    padding-left:0;
  }

  .activity-section-text-inner{
    position:absolute;
    width:100%;
    top:50%;
    left:0;
    transform:translateY(-50%);
  }

  .no-image .activity-section-text-inner{
    position:static;
    transform:none;
  }

  .activity-tips-item{
    width:25%;
  }

  .activity-section-downloads{
    margin-bottom:0;
  }

  .round-scroller-button{
    height: 70px;
    width: 40px;
    font-size: 28px;
    line-height: 28px;
    top: 26%;
  }

  .scroller-button-next{
    right: 0;
  }

  .gear-section-container{
    display:flex;
    flex-direction:row;
  }

  .activity-gear-title{
    margin-top:40px;
  }

  .activity-gear-intro{
    margin-top:10px;
  }

  .activity-tips-downloads .activity-widget-container{
    margin-right:24px;
  }
  .curriculum-header-container.has-video{
    padding:  1rem 30px 1rem;
    margin-top:0;
  }

  .curriculum-header-inner{
    max-width:1490px;
    margin:0 auto;
  }

  .curriculum-container.curriculum-header-info-container{
    box-sizing:border-box;
    padding-left:20px;
    position:relative;
  }

  .curriculum-header-info-container{
      margin-top:0;
      width:33%;
  }

  .curriculum-header-info-container .activity-heading-container{
    position:static;
  }

  .curriculum-header-info-container .activity-controls{
    bottom:0;
    top:auto;
    right:auto;
    left:20px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:100%;
    margin-bottom:0;
  }

  .curriculum-header-info-container .down-score, .up-label, .up-score, 
  .curriculum-header-info-container .activity-control{
    text-align:left;
  }

  .node-contents-generating-page, .node-resource-detail-page{
    .curriculum-header-info-container .down-score, .up-label, .up-score, 
    .curriculum-header-info-container .activity-control {
      text-align: center;
    }
  }

  .curriculum-header-info-container h3.listing{
    font-size:40px;
  }

  .curriculum-level-selector .btn{
    margin-right: 20px;
  }

  .activities-title-container{
    margin-top: 16px;
  }

  .curriculum-level-container{
    margin-top: 50px;
  }

  .activity-lead-text{
    max-width: 700px;
  }
}

@media all and (min-width: 1280px){
  .curriculum-container{
      max-width:1280px;
      padding:0;
  }

  .long-activities-row-block{
      width:100%;
  }

  .long-activities-row-viewport{
    height:360px;
    margin:0;
  }

  .long-activities-row-inner{
    width: 3000px;
  }

  .long-activities-row-inner .activity-block{
    width: calc(25vw - 41px);
    margin-right: 30px;
  }

  .multi-sections .activity-section-text{
    margin-right:40px;
  }

  .multi-sections .activity-section:nth-of-type(2n) .activity-section-text{
    margin-left:40px;
  }

  .activity-tips-container{
    margin-left:-20px;
    margin-right:-20px;
  }

  .activity-tips-item{
    width:20%;
    padding:20px;
  }

  .curriculum-features{
      margin-left:-10px;
      margin-right:-10px;
      padding:0;
  }

  .curriculum-features .curriculum-feature .text-container{
      width:70%;
      height:auto;
  }

  .curriculum-title{
    padding-right:6px;
  }

  .activity-section-text-inner{
    -webkit-box-sizing:border-box;
            box-sizing:border-box;
    padding:0 40px;
  }

  .round-scroller-button{
    height: 80px;
    width: 46px;
  }

  .activity-block .activity-image-container .actvitity-video-icon{
    width: 50px;
    height: 50px;
  }

  .curriculum-level-selector{
    margin-top: 20px;
  }

  .curriculum-level-selector .btn{
    margin-right: 30px;
  }

  .activities-title-container{
    margin-top: 20px;
  }

  .curriculum-level-container{
    margin-top: 70px;
  }

  .activity-lead-text, 
  h3.activity-gear-title{
    font-size: 22px;
  }
}

@media all and (min-width: 1480px){
  .long-activities-row-viewport{
    height: 410px;
  }

  .long-activities-row-inner .activity-block{
    min-width: calc(25vw - 41px);
  }

  .round-scroller-button{
    top: 31%;
  }

  .round-scroller-button{
    height: 90px;
    width: 50px;
  }

  .activities-title-container{
    margin-top: 30px;
  }
}

@media all and (min-width: 1920px){

  .long-activities-row-viewport{
    height: 420px;
  }
  
  .curriculum-listings-container{
    max-width:  1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .round-scroller-button{
    top: 150px;
  }

  .curriculum-header-info-container h3.listing{
    font-size:48px;
  }

  .long-activities-row-viewport{
    height: 420px;
  }

  .long-activities-row-inner .activity-block{
    width: 458px;
    min-width: 458px;
  }

  .activities-title-container{
    margin-top: 40px;
  }

  .node-curriculum-activity, .node-curriculum-overview {
    h1 {
      margin: 18px auto 20px;
    }
  }
  .activity-lead-text{
    max-width: 850px;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 28px;
  }
}

@media all and (max-width: 1023px){
  .long-activities-row-block{
    overflow: hidden;
    margin-right: -30px;
    margin-left: -30px;
  }

  .long-activities-row-viewport {
    width: 100%;
    margin: 0px;
    overflow: hidden;
    position: relative;
    height: 320px;
    overflow-x: auto;
   -webkit-overflow-scrolling: touch;
   -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .resources .long-activities-row-viewport {
    height: 350px;
  }

  .long-activities-row-viewport::-webkit-scrollbar {
    display: none;
  }

  .long-activities-row-inner{
    display: flex;
    flex-wrap: nowrap;
  }

  .activity-block {
    flex: 0 0 auto;
    width: 270px;
    float: left;
    box-sizing: content-box;
  }

  .activity-block:first-of-type{
    margin-left: 30px;
  }

  .slider-is-animating {
    transition: transform 400ms cubic-bezier( 0.5, 0, 0.5, 1 )
  }

}

@media all and (max-width: 640px){
  .long-activities-row-block{
    overflow: hidden;
    margin-right: -20px;
    margin-left: -20px;
  }

  .activity-block {
    padding-right: 20px;
  }

  .activity-block:first-of-type{
    margin-left: 20px;
  }
}
