/********   MAP FINDER *********/
.map-criteria h3 {
  font-size: 18px;
}

span.large-bull{
  font-size: 24px;
  padding-left: 6px;
  line-height: 24px; 
}

.club-distance {
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.club-description {
  padding-top: 10px;
}

.club-description-playhq {
  padding: 16px 12px;
  .club-description-header{
    .club-description-title{
      flex-grow: 5;
    }
    p.club-description-inclusion{
      font-size: 14px;
      line-height: 22px;
    }
    .club-distance {
      font-size: 14px;
      line-height: 22px;
      font-weight: normal;
    }
  }
  .club-format-logos{
    align-items: center;
    flex-grow: 4;
    img.club-format-logo{
      height: 25px;
      width: auto;
      margin-right: 12px;
      
    }
  }
}

.club-info-window{
  padding-right: 0;
}

.club-details-section{
  h4, p {
    margin-bottom: 4px;
  }
}

/** Remove padding from the google info window */
.gm-style .gm-style-iw-c{
  border-radius: 0;
  padding: 0;
}

a.contact-link {
  color: #023C91;
  padding-left: 24px;
}

.contact-email{
  background: no-repeat url(../../images/map/icons/email.svg) 0 50%/18px 18px;
}

.contact-web{
  background: no-repeat url(../../images/map/icons/link.svg) 0 50%/18px 18px;
}

.contact-phone{
  background: no-repeat url(../../images/map/icons/phone.svg) 0 50%/18px 18px;
}

.club-teasers-list:focus { outline: none; }

h4.registrations-deactivated{
  background: url(../../images/map/alert.svg) 0 50%/20px 20px no-repeat;
  padding: 4px 0 4px 26px;
  font-style: normal;
  font-size: 16px;
  margin: 2px 0;
}

.map-criteria{
  .club-finder-filter-component {
    position: relative;
    max-width: 768px;
    margin:0 auto;
  }
}

span.registrations-deactivated{
  font-size: 14px;
  margin-top: 2px;
  font-weight: 800;
  color: #00529c;
  margin-top: 8px;
  display: block;
}

h4.registrations-deactivated.no-icon{
  background: none;
  padding-left: 0;
}

.club-info-window{
  width: 280px;
}

.map-heading {
  margin: 20px;
}

.club-finder {
  height: 100%;
}

#drag-control-checkbox{
  -webkit-appearance: checkbox
}

.club-finder-intro-paragraph {
  font-size: 18px;
	font-weight: normal;
	font-style: italic;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: $blue;
}

.map-component-container {
  position: relative;
  min-height: 420px;
}

.map-criteria {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
}

.hero-filter-container .map-criteria {
  background-color: transparent;
}


.map-criteria.program-page {
  position: static;
}

.map-loading {
  background-color: #dce3eb;
}

.map-modal .close-modal{
  position: absolute;
  right: 10px;
  top: 10px;
}

.finder-modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-color: rgba(0,0,0,0.5);
  z-index: 11;
}

.club-detail-register{
  color: #E41E31;
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  line-height: 20px;
  margin-top: 4px;
  margin-right: 12px;
}

.teaser-club-details-container{
  display: flex;
}

.teaser-club-details-cell{
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  min-width: 35%;
  box-sizing: border-box;
  justify-content: flex-end;
}

a.view-club-details{
  color: #00529c;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
}

.bottom-align {
  display: flex;
}

.relative a.view-club-details.static{
  position: static;
}

.map-modal{
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  width: 300px;
  width: calc(100vw - 40px);
  height: 360px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}


.map-modal.loading-dialog {
  z-index: 1000;
  width: 180px;
  height: 50px;
  bottom: 14px;
  right: 12px;
  left: auto;
  top: auto;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  transform: none;
  p {
    color: $body_text;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    margin: 0 0 0 6px;
  }
  .map-loader-icon{
    width: 20px;
    margin: 0;
  }
}

.map-modal.map-registration-deactivated{
  height: 360px;
}

.map-modal p{
  margin: 20px;
  font-size: 16px;
  line-height: 28px;
	letter-spacing: normal;
  color: #070707;
}

.map-modal.map-registration-deactivated h4{
  margin-top: 20px;
}
.map-modal.map-registration-deactivated p {
  margin: 20px;
}

.map-modal.map-no-results p {
  margin: 30px 16px;
}

.map-fail-icon, .map-alert-icon{
  width: 50px;
  height: auto;
}

.map-loader-icon{
  margin-top: 20px;
  width: 50px;
  height: auto;
}

p.map-modal-text a{
  font-size: 18px;
  text-decoration: underline;
}
.map-modal-rego-heading{
  font-size: 24px;
	font-weight: 800;
  margin: 70px 26px 0px;
  color: #00529c;
}

.map-modal-rego-text{
  font-size: 18px;
	font-style: italic;
	line-height: 1.44;
  margin: 20px;
  color: #00529c;
}

.auskick-burst-container,
.auskick-burst-container-detail{
  display: flex;
  align-items: center;
  color: #4a4a4a;
}

.auskick-burst-container{
  font-size: 14px;
  img {
    height: 21px;
    width: auto;
    margin-right: 3px;
  }
}

.auskick-burst-container-detail{
  font-size: 16px;
  img {
    height: 25px;
    width: auto;
    margin-right: 4px;
  }
}



.map-container{
  height: 620px;
}

.no-results,
.map-results-loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  border-radius: 2px; cursor: pointer;
  z-index: 10
}

.map-results-loader i{
  color: $blue
}

.drag-control-container{
  margin: 10px;
  background-color: #ffffff;
  padding: 0 6px;
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  border-radius: 2px; cursor: pointer;

}

.drag-control-container label {
  display: inline-block;
  margin: 0 6px;
  color: #4a4a4a;
  font-weight: normal;
}

.filters-control-container{
  margin: 10px;
  background-color: #ffffff;
  user-select: none;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  border-radius: 2px; cursor: pointer;
}

.filters-control-container a{
  font-size: 16px;
  color: $red;
  line-height: 40px;
  padding: 0 16px;
  font-weight: 700;
}

.club-details,
.club-teasers-list {
  position: absolute;
  box-sizing: border-box;
  top: 250px;
  height: 460px;
  height: calc(100vh - 250px);
  width: 100%;
  z-index: 9;
  background-color: #ffffff;
  border: solid 1px #d7d7d7;
  overflow-y: scroll;
  margin-bottom: 55px;
  padding-bottom: 100px;
}

.club-teasers-list{
  top: 118px;
  height: calc(100vh - 118px);
  position: fixed;
}

.club-details-enter, .club-details-appear{
  transform: translateY(100vh);
  transition: transform 0.3s;
}

.club-details-leave,
.club-details-enter.club-details-enter-active,
.club-details-appear.club-details-appear-active {
  transform: translateY(0);
  transition: transform 0.3s;
}

.club-details-leave.club-details-leave-active {
  transform: translateY(100vh);
  transition: transform 0.3s;
}

.club-details-header {
  margin: 10px 20px 0 10px;
}

.quality-club-logo{
  height: 55px;
  width: auto;
  margin: 10px;
}

.club-type-logo {
  height: 55px;
  width: auto;
}

.club-type-logo-teaser{
  height: 50px;
  width: auto;
}

.close-club-details{
  cursor: pointer; cursor: hand;
  margin-left: 12px;
}

.club-details h3.club {
  margin: 0 0 6px;
}

.club-details-section{
  margin-top: 6px;
  border-top: 1px solid #dedede;
  padding: 9px 0;
}

.club-details-section p{
  margin: 0 0 2px;
}

.contact-details-icon {
  vertical-align: -19%;
}

.club-contact:not(:first-child){
  margin-top: 10px;
  padding-top: 6px;
}

p.contact-role{
  margin-bottom: 0;
  margin-top: 4px;
  line-height: 1.12;
}

.club-register-buttons{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  padding: 10px;
  background-color: white;
}

.club-register-button-container{
  width: 100%;
  padding: 10px;
}
.club-register-button-container.full-width{
  width: 100%;
}

@media all and (max-width: 767px) {
  .club-register-button-container{
    padding: 10px;
  }

  .club-register-buttons{
    a:first-of-type{
      border-right: 1px solid white;
    }
    a:last-of-type{
      border-left: 1px solid white;
    }
    .full-width{
      a{
        border: none;
        line-height: 50px;
        padding: 0;
      }
    }
  }
}


.club-register-buttons .btn{
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  padding: 17px 14px;
}

.club-register-buttons .btn.btn-full-width{
  width: 100%;
  padding: 0 14px;
}

.club-register-buttons .btn:nth-of-type(2){
  border-left: 1px solid white;
}

.club-rego-warning-modal{
  position: absolute;
  top: 180px;
  width: calc(100% - 20px);
  height: calc(100vh - 190px);
  left: 10px;
  right: 10px;
  z-index: 100;
  background-color: white;
  text-align: center;
  padding: 30px 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    margin: 0 auto 16px;
    max-width: 22ch;
  }

  .close-rego-popup{
    position: absolute;
    right: 10px;
    top: 16px;
  }

  .btn-clode-club-rego-modal{
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    width: 150px;
    margin-bottom: 16px;
  }
}

.no-location-dialog{
  top: 180px;
  width: calc(100% - 20px);
  height: calc(100vh - 190px);
  max-height: 360px;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: none;
}

@media all and (min-width: 400px) {

  .popup-wrapper{
    position: absolute;
  }


}

@media all and (min-width: 640px) {


  .club-details-body {
    position: relative;
  }

  .club-finder-intro-paragraph {
    font-size: 16px;
  }

  .map-component-container{
    min-height: 880px;
  }

  .map-container{
    height: 880px;
  }

  .club-details {
    top: 480px;
  }

  .map-criteria{
    position: static;
    text-align:center;
    padding:10px;
    width: auto;
  }

  .filters-control-container{
    display: none;
  }

  .club-info-window{
    width: 350px;
  }

  

  .map-fail-icon{
    width: 60px;
    height: auto;
  }

  p.map-modal-text a{
    text-decoration: underline;
  }

  .map-no-results p.map-modal-female-text{
    margin-top: 20px;
  }

  .map-loader-icon{
    margin-top: 20px;
    width: 100px;
  }

  .map-modal-rego-heading{
    margin: 120px 40px 20px 40px;
  }

}

@media all and (min-width: 768px) {


  .map-modal{
    text-align: center;
    background-color: #ffffff;
    width: 420px;
    height: 420px;
    z-index: 10;
    transform: translate(-50%, -50%);
  }
  
  .no-location-dialog {
    top: 50%;
    left: 50%;
    right: auto;
    width: 400px;
  }

  .club-register-buttons{
    width: 100%;
    position: static;
    display: flex;
    margin: 10px -5px;
    .club-register-button-container{
      padding: 0 5px;
      flex-grow: 1;
    }
  }

  .club-register-buttons .btn{
    text-transform: capitalize;
    width: 100%;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: relative;
    padding: 0 8px;
  }

  .map-component-container {
    min-height: 0; /* reset previous value */
    overflow: hidden;
    margin: 10px 23px 23px 23px;
  }

  .map-component-container-full-width .map-component-container{
    margin: 0 0 23px 0;
  }

  .max-width-5 .map-component-container {
    margin: 0 0 25px 0;
  }

  .map-container{
    height: 400px;
  }

  .club-details, .club-teasers-list{
    bottom:auto;
    right: 0;
    top: 0;
    width: 360px;
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 0;
    padding-bottom: 0;
    background-color: #f3f3f3;
    border: none;
    padding: 4px;
  }

  .club-teasers-list{
    position: absolute;
  }

  .club-teaser, .club-details{
    border: 1px solid #d5d5d5;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.15);
  }

  .club-description{
    margin-top: 0;
  }

  .club-teaser{
    margin-top: 5px;
    h3.club-heading {
      cursor: pointer;
    }
    h3.club-heading:hover {
      color: $title_hover_color;
    }
  }

  .club-teaser::after {
    border: none;
  }

  .club-teaser-header-container{
      min-height: 40px;
  }


  .club-details-enter {
    transform: translateX(+100vw);
    transition: transform 300ms ease-in;
  }

  .club-details-enter.club-details-enter-active {
    transform: translateX(0vw);
    transition: transform 300ms ease-in;
  }

  .club-rego-warning-modal{
    width: 420px;
    height: 250px;
    top: 50%;
    left: calc(50% - 190px);
    transform: translateX(-50%);
  }

  .map-empty{
    .club-rego-warning-modal{
      left: 50%;
    }
  }

}

@media all and (min-width: 1024px) {

  .club-details, .club-teasers-list{
    width: 380px;
  }

  .map-heading {
    margin: 40px 26px;
  }

  .map-component-container {
    margin: 10px 26px 26px 26px;
  }

  .map-component-container-full-width .map-component-container{
    margin: 0 0 26px 0;
  }

  .map-container{
    height: 480px;
    height: calc(100vh - 140px)
  }

  .map-modal-rego-heading{
    margin: 120px 40px 20px 40px;
  }

  .map-modal-rego-text{
    font-size: 18px;
    margin: 20px;
  }

  .club-detail-register{
    margin-right: 18px;
  }

  .auskick-register-map{
    .map-container{
      height: calc(100vh - 230px);
    }
    
  }

  .club-rego-warning-modal{
    width: 500px;
    left: calc(50% - 190px);
  }

  
}

@media all and (min-width: 1280px) {


  .club-finder-intro-paragraph {
    font-size: 18px;
  }

  .map-component-container {
    margin: 26px 32px 32px 32px;
  }

  .map-component-container-full-width .map-component-container{
    margin: 0 0 32px 0;
  }

  .club-details, .club-teasers-list{
    width: 460px;
  }

  .club-detail-register{
    margin-right: 22px;
  }

  .club-register-buttons .btn{
    font-size: 14px;
  }

  .club-rego-warning-modal{
    width: 520px;
    left: calc(50% - 230px);
  }

  .map-empty{
    .club-rego-warning-modal{
      left: 50%;
    }
  }
}

@media all and (min-width: 1920px) {
  .map-component-container {
    margin: 10px 70px 70px 70px;
  }

  .map-component-container-full-width .map-component-container{
    margin: 0 0 70px 0;
  }

  .map-container{
    height: 780px;
  }

  .club-details, .club-teasers-list {
    width: 450px;
  }

  .club-register-buttons .btn{
    font-size: 14px;
  }

  .club-details, .club-teasers-list{
    width: 500px;
  }

  .club-rego-warning-modal{
    left: calc(50% - 250px);
  }

  .map-empty{
    .club-rego-warning-modal{
      left: 50%;
    }
  }
}

@media all and (max-width: 640px) {
  .club-details-body {
    margin-right: 0;
    height: 320px;
    padding-bottom: 50px;
  }

  .map-container{
      height: calc(100vh - 50px);
  }
  
  .auskick-register-map .map-container {
    height: calc(100vh - 170px);
  }

  .map-criteria{
    .club-finder-filter-component {
      .program-filter-container{
        margin: 0;
      }
    }
  }

  .map-modal.female-no-results{
    height: 450px;
  }

  .auskick-register-map{
    .mobile-map-filter-container{
      top: 180px;
    }
  }
  

  .mob-menu-showing .mobile-map-filter-container{
    display: none;
  }

  .mobile-map-filter-container.hide{
    display: none;
  }
}


@media only screen
and (min-device-height : 701px)
and (max-device-height : 740px)
and (orientation : portrait) {
  .auskick-register-map .map-container {
    height: calc(100vh - 170px);
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  .map-component-container{
    margin-top: 20px;
  }

  .map-container {
    height: 880px;
    height: calc(100vh - 140px);
  }

  .auskick-register-map .map-container {
    height: 754px;
    height: calc(100vh - 270px);
  }

}

/* iphone 11 + 12 */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3)
    and (orientation : portrait) { 
      .map-container {
        height: 780px;
        height: calc(100vh - 180px);
      }
    
      .auskick-register-map .map-container {
        height: 754px;
        height: calc(100vh - 320px);
      }

      
}

@media only screen and (min-device-width: 375px)
and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .map-modal.loading-dialog {
      bottom: 50px
  }
}
