/**
Styles that relate to sections on Program PAges
*/
.max-width-page{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
        @media all and (min-width: 1024px) {
            margin-top: 10px;
        }
        @media all and (min-width: 1800px) {
            margin-top: 40px;
        }
        .paragraphs-items-field-sections{
            .field-items{
            
                .field-item{
                    @media all and (min-width: 1024px) {
                        margin: 10px 0;
                    }
                    
                    .activity-section-text{
                        .activity-section-text-inner{
                            @media all and (min-width: 1024px) {
                                padding: 0 30px 0 79px;
                                text-align: left;
                                h2{
                                    margin-left: 0;
                                    margin-right: 0;
                                    max-width: 25ch;
                                }
                            }
                            
                            @media all and (min-width: 1480px) {
                                padding: 0 140px;  
                            }
                            @media all and (min-width: 1920px) {
                                padding: 0 167px;
                            }
                        }

                        h2{
                            font-family: 'Montserrat', 'Helvetica Neue', 'sans-serif';
                            font-weight: 700;
                        }
                        
                        .section-link-image{
                            height: 25px;
                            vertical-align: -22%;
                            margin-right: 2px;
                        }
                        
                    }
                    .activity-section-image{
                        @media all and (min-width: 1024px) {
                            padding-right: 15px;
                            padding-left: 0;
                            img {
                                width: 100%;
                                height: auto;
                            }
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                .field-item:nth-of-type(2n){
                    .activity-section-text-inner{
                        @media all and (min-width: 1024px) {
                            padding: 0 30px 0 79px;
                        }
                        @media all and (min-width: 1480px) {
                            padding: 0 140px;   
                        }
                        @media all and (min-width: 1920px) {
                            padding: 0 167px;
                        }
                    }
                    .activity-section-image{
                        @media all and (min-width: 1024px) {
                            padding-left: 15px;
                            padding-right: 0;
                        }
                    }
                }
                .field-item:last-of-type{
                    @media all and (min-width: 1024px) {
                        margin: 20px 0 40px;
                    }
                    @media all and (min-width: 1480px) {
                        margin-bottom: 40px; 
                    }
                }
                
                
            }
        }
    }
}


.standard-program-page{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
        .junior-program-page, .senior-program-page, .masters-program-page, .youth-program-page{
            .paragraphs-items-field-sections{
                .field-items{
                    .field-item:last-of-type{
                        background-color: #F8F8F8;
                        margin-top: 30px;
                        padding-top: 0;
                        @media all and (min-width: 1280px) {
                            margin-top: 30px;
                        }
                        
                        .activity-section-image{
                            img {
                                @media all and (min-width: 1024px) {
                                    margin-top: 10px;
                                }
                                @media all and (min-width: 1280px) {
                                    margin-top: 20px;
                                }
                                @media all and (min-width: 1450px) {
                                    margin-top: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .questions_section{
        max-width: 680px;
        margin: 10px auto;
        h2 {
            font-size: 26px;
            margin: 50px 0 20px;
        }
        h3 {
            font-size: 20px;
            margin: 20px 0 6px;
        }
        p {
            font-size: 16px;
            line-height: 26px;
        }
        @media all and (min-width: 1024px) {
            h2 {
                font-size: 30px;
                line-height: 40px;
            }
            h3 {
                font-size: 24px;
                line-height: 34px;
                margin: 40px 0 8px;
            }
            p {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}


.max-width-page.standard-program-page{
    .paragraphs-item-content-sections,
    .paragraphs-item-content-section-container{
        .afl9s-program-page, .masters-program-page{
            .paragraphs-items-field-sections{
                .field-items{
                    .field-item{
                        .activity-section-text{
                            h2{
                                font-size: responsive 26px 30px;
                            }
                            p {
                                font-size: responsive 16px 17px; /* min-size, max-size */
                                font-range: 640px 1480px;
                                line-height: 1.58;
                            }
                        }
                    }
                }
            }
        }
    }
}
