
/******  TOP NAV   ******/

.fixed-header {
  background-color: transparent;
}

.fixed-header.grey-underline{
  border-bottom: 2px solid #d8dce2;
}

.fixed-header.nav-slim-border{
  border-bottom: 1px solid #f7f8fc
}

.no-header-image .fixed-header{
  border-bottom: 1px solid $nav_underline;
}

html.mob-menu-showing{
  overflow: hidden;
}

.fixed-header-transparent-mobile,
.nav-transparent{
  background-color: transparent;
}

.nav__inner {
  max-width: $content_width;
  height: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background-color: $top-nav-colour;
  padding: 0 10px;
}

.nav__nav-center{
  display: none;
  align-items: center;
  justify-content: flex-end;
  li a, a, li a.active {
    color: #fff;
  }
}

.nav__nav-burger{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 86px;
 
  button.burger-button{
    background-color: transparent;
    display: block;
    border: none;
    width: 24px;
    height: 24px;
    position: relative;
    outline: none;
    .burger-bar{
      background-color: white;
      transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
      position: absolute;
      display: block;
      top: 50%;
      right: 0px;
      left: 0px;
      height: 2px;
      width: 22px;
      z-index: 10;
      opacity: 1;
    }
    .burger-bar--1{
      width: 16px;
      transform: translateY(-9px);
    }
    .burger-bar--2{
        transform: translateY(-3px);
    }
    .burger-bar--3{
        transform: translateY(3px);
    }
    .burger-bar--4{
      width: 16px;
      transform: translateY(9px);
    }
  }
  .burger-button.active{
    .burger-bar--1, .burger-bar--4{
      opacity: 0;
    }
    .burger-bar--3 {
      transform: rotate(-45deg);
    }
    .burger-bar--2 {
      transform: rotate(45deg);
    }
  }
}

.nav__nav-button-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 86px;
  a.btn-register{
    background-color: #E41E31;
    color: #fff;
    border: none;
    padding: 0 14px;
    line-height: 36px;
    height: 36px;
    font-size: 13px;
  }
  a.btn-register-button:hover {
    color: white;
    background-color: #c40011;
  }
}

.navigation-icon {
  vertical-align: bottom;
}

.dropdown-menu-container{
  display: none;
  opacity: 0;
  transform: translateY(.8rem);
  transition: transform .25s ease-in-out,opacity .25s ease-in-out;
  height: calc(100vh - 50px);

  background-color: white;
  .dropdown-menu-inner{
    overflow-y: auto;
    max-height: calc(100vh - 50px);
    padding-bottom: 120px;
  }
}

.dropdown-menu-container.active
{
  display: block;
}

.dropdown-menu-container.active.visible
{
  transform: translateY(0);
  transition: transform .25s ease-in-out,opacity .25s ease-in-out;
  opacity: 1;
}

.programs-menu-container{
  background-color: white;
  .programs-menu-inner{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 0;
    .program-menu-item{
      width: 50%;
      padding: 20px 0;
      @media all and (min-width: 640px) {
        width: 33.33%;
      }
      a {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
      }
      
      h4 {
        font-size: 28px;
        line-height: 26px;
        color: $program-text;
        max-width: 9ch;
        margin: 10px auto 6px;
        font-family: 'PODIUMSharp-211', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 1px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      p {
        color: $program-text;
        font-weight: bold;
        font-size: 13px;
        margin: 0 auto;
        font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
      }
      img {
        height: 44px;
        width: auto;
      }
      img.img-auskick {
        margin: 8px 0 2px;
        @media all and (min-width: 1024px) {
          margin-top: 10px;
        }
      }
      img.img-afl-9s {
        height: 60px;
      }
    }
  }
}

.nav{
  .main-menu-container{
    border-top: 1px solid #D6D6D6; 
    padding: 20px 10px 20px 25px;
    ul.menu {
      display: flex;
      flex-direction: column;
      li.leaf a{
          font-size: 18px;
          font-weight: 600;
          margin: 4px 0;
          padding: 10px 0;
          line-height: 1.3;
          display: block;
          @media all and (min-width: 1024px){
            font-size: 13px;
          }
      }
      li.leaf a.external-link{
        background: url(../../images/svg/chevron-right.svg) no-repeat 97% 50%/24px 24px;
      }
    }
  }
}

.program-submenu{
  background-color: white;
  transition: box-shadow 0.25s;
  .program-submenu-inner {
    display: flex;
    flex-direction: column;
    .program-submenu-logo-container{
      height: 70px;
      display:flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #D6D6D6;
      .program-header-logo{
        height: 55px;
        width: auto;
      }
      h2 {
        font-size: 28px;
        letter-spacing: 1px;
        font-family: 'PODIUMSharp-211', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: normal;
        color: $program-text;
        text-align: center;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
    .program-submenu-nav-container{
      height: 50px;
      overflow-x: hidden;
      display: flex;
      justify-content: center;
      .nav-prev, .nav-next {
        width: 36px;
        opacity: 0;
        transition: opacity 0.25s;
        display: inline-block;
        background: url(../../images/svg/chevron-left.svg) no-repeat 50% 50%/26px 26px;
      }
  
      .nav-next {
        background-image: url(../../images/svg/chevron-right.svg);
      }
  
      .nav-prev.active, .nav-next.active{
        opacity: 1;
      }
  
      ul.menu {
        display: flex;
        flex-direction:row;
        margin: 0;
        padding: 0;
        flex-wrap: nowrap;
        overflow: auto;
        li.leaf {
          list-style-type: none;
          margin: 0 20px 0 0;
          padding: 0;
          a {
            color: $program-text;
            font-weight: bold;
            font-size: 13px;
            margin: 0 auto;
            line-height: 50px;
            display: flex;
            position: relative;
            @media all and (min-width: 1024px){
              line-height: 70px;
            }
            font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
            white-space: nowrap;
          }
          
        }
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      ul.menu::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      ul.menu {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    } 
  }
}

.no-header-image .program-submenu{
  border-bottom: 1px solid #D6D6D6;
}
.scrolled .program-submenu{
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3);
}
.program-menu-item, 
.program-submenu-nav-container ul.menu, 
.highlight {
  a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: $link_underline_hover_color;
    transition: height .25s ease;
  }
  a:hover:after, a.active:after, a.active-trail:after{
    height: 4px;
  }
}

.ios-ipad, .ios-safari {
  .program-menu-item, 
.program-submenu-nav-container ul.menu, 
.highlight {
  a:after{
    bottom: 1px;
    }
  }
}

.program-submenu-nav-container ul.menu {
  li::marker {
    content: none;
  }
}

.nav ul.menu {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

.nav ul.menu li.leaf {
  display: inline;
  list-style-type: none;
  list-style-image: none;
  padding-top: 0;
}

.nav ul.menu li.leaf a {
  font-size: 13px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
  line-height: 70px;
  padding-bottom: 20px;
  margin-right: 40px;
  color: #02295D;
}

.nav ul.menu li.leaf a.active {
  &.js-scroll-to-from-menu {
    border-bottom: none;
  }
}
.nav ul.menu li.leaf a:hover,
.nav ul.menu li.leaf.dropdown ul li a:hover{
  &.js-scroll-to-from-menu {
    border-bottom: 4px solid $red;
  }
}

.nav ul.menu li.dropdown{
  position: relative;
  overflow: visible;
}
.nav ul.menu li.dropdown ul {
  padding-left: 0;
  margin-top: -10px;
  list-style-type: none;
  background-color: #ffffff;
  padding: 0 10px 6px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-in;
  position: absolute;
  white-space: nowrap;

}

.nav ul.menu li.leaf.dropdown a {
  border-bottom: none;
  margin-right: 0;
}

.nav ul.menu li.dropdown:hover ul {
  max-height: 400px;
  transition: max-height 0.25s ease-in;
  margin-top: -10px;

  box-shadow:  0 4px 4px -2px #777777;
}
.nav ul.menu li.leaf.dropdown ul li{
  display: block;
}
.nav ul.menu li.leaf.dropdown ul li a {
  padding-bottom: 2px;
  margin-right: 10px;
  line-height: 36px;
}

.nav .nav__inner ul.menu {
  height: $header_height_mobile;
}

span.red {
  color: $red;
}

.header-logo{
  vertical-align: middle;
  margin-top: -6px;
  margin-left: -4px;
  height:34px;
  width: auto;
}

.header-play-logo{
  width: 86px;
  vertical-align: 0;
}

.nav-items-container{
  padding-top:4px;
}

.fixed-header {
    transition: all 0.25s ease-out;
}

section.nav h1{
  display: inline-block;
}

.fixed-header h1 a, .fixed-header i {
  color: #ffffff;
  font-size: 24px;
  font-weight: 800;
  text-transform: lowercase;
}

.fixed-header h1 a.large-logo-text{
  font-size: 24px;
	line-height: 1.0;
	letter-spacing: 1.6px;
  vertical-align: middle;
  margin-top: 5px;
}

.fixed-header.scrolled {
    background: rgb(255, 255, 255); /*IE*/
    background: rgba(255, 255, 255, 0.99); /*NON-IE*/
}

.fixed-header.neg {
  z-index: -1;
}


/*** FOOTER MENU ***/

.footer-menu ul {
  list-style-type: none;
  padding-left: 0;
}

.footer-menu ul li{
  list-style-type: none;
  list-style-image: none;
  padding-left: 0;
  margin: 0 20px;
  border-top: solid 1px #dedede;
}

.footer-menu ul li a{
  display: block;
  line-height: 80px;
  font-size: 20px;
	letter-spacing: normal;
	color: $blue;
  background: url(../../images/svg/ArrowRightMobile.svg) no-repeat right center;
}

.close-menu-icon{
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 110;
}

.nav__nav-center, 
.region-main-navigation, 
.program-submenu-nav-container {
  ul.menu {
    transition: opacity 0.25s;
  }
  ul.menu:hover > * {
    opacity: 0.6;
    transition: opacity 0.25s;
  }
  ul.menu > *:hover {
    opacity: 1;
  }
}

@media all and (min-width: 640px){

  .fixed-header h1 a{
    font-size: 20px;
  }

  .fixed-header a {
    color: #ffffff;
  }

  .fixed-header-transparent-mobile{
    background-color: #ffffff;
  }

  .nav-items-container{
    padding-top:18px;
  }

  section.nav.nav-equal-padding{
    padding: 0 6px 0 10px;
  }

  .header-logo-afl9s{
    width: 50px;
    margin-left: 0;
  }

  .nav ul.menu {
    margin-left: 10px;
  }

  .nav ul.menu li.leaf {
      padding-right: 4px;
  }

  .nav ul.menu li.leaf a {

    padding-bottom: 10px;
    margin-right: 0;
  }

  .menu-down-arrow-red{
    width: 12px;
  }


  .btn.btn-nav{
    height: 32px;
    line-height: 32px;
    color: #ffffff !important;
    margin-top: 4px;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .scrolled .btn.btn-nav{
    opacity: 1;
  }
}

@media all and (min-width: 768px){
  .nav-alt{
    .nav__inner{
      justify-content: flex-start;
      padding: 0 10px;
      .nav__nav-burger{
        order: 4;
        width: 30px;
        margin-left: 10px;
      }
      .nav__nav-logo{
        order: 1;
        width: 130px;
      }
      .nav__nav-center{
        order: 2;
        display: flex;
        
      }
      .nav__nav-button-container{
        order: 3;
      }
    }
  }

  .nav__inner{
    
    .nav__nav-center{
      .nav-ways-container{
        display: none;
      }
      justify-content: center;
      height: 50px;
      line-height: 50px;
      flex-grow: 5;

      .nav ul.menu.ways-where{
        margin:  0;
      }
    
      ul.menu{
        transition: opacity 0.25s;
        margin-left: 0;
        li {
          margin: 0;
        }
        li a {
          font-weight: bold;
          font-size:  13px;
          position: relative;
          margin: 0 1em;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }
      }
    }
  }
}


@media all and (min-width: 1024px){

  .nav-top {
    background-color: $top-nav-colour;
    z-index: 10;
  }
  .nav__inner, .nav-alt .nav__inner{
    justify-content: flex-start;
    padding: 0 30px;
    .nav__nav-burger{
      order: 4;
      width: 30px;
      margin-left: 10px;
    }
    .nav__nav-logo{
      order: 1;
      width: 200px;
    }
    .nav__nav-center{
      order: 2;
      display: flex;
      .nav-ways-container{
        display: inline-block;
      }
    }
    .nav__nav-button-container{
      order: 3;
      width: 170px;
    }
  }

  .program-submenu{
    height: 70px;
    .program-submenu-inner {
      max-width: $content_width;
      flex-direction: row;
      margin: 0 auto;
      position: relative;
      .program-submenu-logo-container{
        border-bottom: none;
        width: 220px;
        position: absolute;
        left: 20px;
        
        justify-content: flex-start;
        padding-left: 10px;
        .program-header-logo{
          margin-left: -5px;
        }
        .program-header-afl9s{
          margin-left: 50px;
        }
        .program-header-schools{
          margin-left: 20px;
        }
      }
      .program-submenu-nav-container{
        .nav-prev, .nav-next {
          display: none;
        }
        flex-grow: 5;
        height: 70px;
        line-height: 70px;
        
      }
    }
  }
  
  .dropdown-menu-container{
    opacity: 1;
    height: 0;
    transform: none;
    width: 100%;
  }
  .dropdown-menu-container.show-contents{
    display: block;
  }


  .programs-menu-container, .main-menu-container{
    display: flex;
    visibility: hidden;
    position: fixed;
    top: $header_height_mobile;
    width: 100%;
    height: auto;
    background-color: white;
    transform: translateY(.8rem);
    transition: transform .25s ease-in-out,opacity .25s ease-in-out, visibility 0s linear 0.25s;
    opacity: 0;
    z-index: -1;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3);
    .programs-menu-inner {
      justify-content: center;
    }
    .programs-menu-inner, .region-main-navigation{
      background-color: white;
      width: 100%;
      margin: 0 auto;
      max-width: $content_width;
      padding: 0;
      
      .program-menu-item{
        width: auto;
        padding: 0 16px;
      }
      .program-menu-item a {
        padding: 20px 0;
        position: relative;
        margin: 0;
        box-sizing: border-box;
        height: 100%;
      }
    }
  }

  .programs-menu-container.active, 
  .main-menu-container.active{
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    z-index: 10;
    transition: transform .25s ease-in-out, opacity .25s ease-in-out, visibility 0.25s linear;
  }

  .programs-menu-arrow, .main-menu-arrow{
    width: 0; 
    height: 0; 
    border-left: 9px solid transparent;  
    border-right: 9px solid transparent; 
    border-bottom: 9px solid #ffffff; 
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: -7px;
    left: 43.5%;
  }

  .nav .main-menu-container{
    width: 270px;
    left: 80%;
    padding: 0;
    border-top: none;
    .region-main-navigation {
      width: 270px;
      padding: 20px 10px 20px 20px;
    }
  }

  .nav ul.menu li.leaf a {
    padding-bottom: 19px;
    margin-right: 10px;
  }

  .btn.btn-nav {
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
    margin-top: 10px;
    margin-right: 4px;
  }

}

@media all and (min-width: 1280px){

  .programs-menu-container, .main-menu-container{
    .programs-menu-inner, .region-main-navigation{
      .program-menu-item a {  
          padding: 30px 0;
      }
    }
  }

  .nav__nav-button-container{
    a.btn-register-button{
      padding: 0 25px;
    }
  }

  .nav ul.menu li.leaf {
      padding-right: 8px;
  }

  .nav ul.menu li.leaf a {
    padding-bottom: 24px;
    margin-right: 22px;
  }

  .fixed-header h1 a.large-logo-text
  {
    font-size: 48px;
  	line-height: 1.0;
  	letter-spacing: 1.6px;
    font-weight: 800;
  }

  .btn.btn-nav{
    height: 50px;
    line-height: 50px;
    padding: 0 34px;
    margin-top: 10px;
    margin-right: 4px;
    font-size: 14px;
  }


}

@media all and (min-width: 1980px){
  .nav__inner, .nav-alt .nav__inner{
    padding: 0;
  }

  .program-submenu{
    .program-submenu-inner {
      padding: 0;
      .program-submenu-logo-container{
        left: 0;
        padding: 0;
      }
    }
  }

}

@media all and (max-width: 320px){

  .nav__nav-logo {
      padding-left: 160px;
  }
  .nav__nav-button-container {
    a.btn-register-button{
      padding: 0 10px;
      height: 34px;
      line-height: 34px;
    }
  }
}
