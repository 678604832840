
.hero-special-text.ambassadors-text{
  padding: 20px 20px 50px;
  margin-bottom: 30px;
  h2 {
    margin: 10px 0 10px;
    max-width: 20ch;
    line-height: 1;
  }
  p {
    color: $white;
    font-weight: normal;
    margin-bottom: 10px;
    max-width: 46ch;
  }
}

@media all and (min-width: 640px){
  .hero-special-text.ambassadors-text{
    padding-left:  50px;
  }
}

@media all and (min-width: 1024px){
  .hero-special-text.ambassadors-text{
    padding-left: 60px;
    
  }
}

@media all and (min-width: 1280px){
  .hero-special-text.ambassadors-text{
    padding-left: 80px;
    
  }
}

@media all and (min-width: 1480px){
  .hero-special-text.ambassadors-text{
    padding-left: 120px;
   
    
  }
}

.section-content-container p {
  font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
}
