/* auskick-new pages.css new file should be created */

/* more to explore section */

.explore-auskick-columns-section {
  padding: 30px 0 !important;
  position: relative;

  h2 {
    font-family: "Montserrat", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px !important;
    line-height: 32px;
    color: #02295d;
    text-align: left;
    max-width: 100%;

    @media all and (max-width: 1919px) {
      width: fit-content;
    }

    @media all and (max-width: 1023px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media all and (min-width: 1920px) {
      max-width: 1920px;
      margin: auto;
    }
  }

  .paragraphs-items-field-explore-content-sections {
    .field-name-field-explore-content-sections {
      .field-label {
        display: none;
      }
      .field-items {
        max-width: 100% !important;
        justify-content: space-between;
        @media all and (min-width: 1920px) {
          max-width: 1920px;
          margin: auto;
        }
        @media all and (max-width: 1023px) {
          margin: 0;
        }
        .field-item {
          padding-left: 0;
          padding-right: 0;
          @media all and (max-width: 1023px) {
            margin-left: 0;
          }
          .explore-activity-section-text {
            a {
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 32px;
              color: #01285e;
              background: 0 0;
              box-shadow: none;
              text-align: left;
              padding: 0;
              width: 100%;

              &:hover {
                text-decoration: underline;
              }
            }
          }
          @media (max-width: 639px) {
            width: 270px;
            margin: 0 0.5rem 0 0;
          }
          @media (min-width: 640px) and (max-width: 767px) {
            width: 270px;
            margin: 0 0.5rem 0 0;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            width: 320px;
            margin: 0 0.5rem 0 0;
          }
          @media (min-width: 1024px) {
            width: 32%;
          }

          .activity-section-image {
            overflow: hidden;

            img.responsive-img {
              transition: transform 0.25s ease-in-out;
              vertical-align: middle;
              &:hover {
                transform: scale(1.1) translateZ(0);
              }
            }
          }
        }
        @media (max-width: 639px) {
          display: -webkit-box;
          margin-left: auto;
          margin-right: auto;
          overflow: auto;
        }
        @media (min-width: 640px) and (max-width: 1023px) {
          display: -webkit-box;
          margin: 0 0.5rem 0 0;
          width: 100%;
          overflow: auto;
        }
      }
    }
  }
}

/* faq section */
@media (max-width: 639px) {
  .node-auskick-base-content .mobile-side-padding {
    margin: 0 !important;
  }
}
.faq-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  hr.overline {
    display: none;
  }
  .category-header {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(196, 196, 196, 0.7);
    font-style: normal;
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 36px;
    letter-spacing: -0.01em;
    color: #01285e;
    @media (max-width: 1439px) {
      font-size: 24px !important;
      line-height: 32px;
    }
    @media (min-width: 1920px) {
      font-size: 48px !important;
      line-height: 52px;
    }
  }
  .see-all-faq-link {
    display: block !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #023c91 !important;
    margin: 2rem 0;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
  .see-all-faq-d-none {
    display: none !important;
  }
  .accordion {
    .accordion__item {
      padding: 0;
      margin: 0;
      border-bottom: 1px solid rgba(196, 196, 196, 0.7);
      .accordion__trigger {
        align-items: center;
        cursor: pointer;
        display: flex;
        width: 100%;
        &:before {
          display: none;
        }
        &:after {
          content: "+";
          float: left;
          margin-right: 14px;
          margin-top: 2px;
          width: 30px;
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 8px;
          color: #02295d;
        }
        .accordion__heading {
          padding: 24px 0;
          @media all and (max-width: 1279px) {
            font-size: 18px;
            line-height: 24px;
          }
          @media all and (min-width: 1280px) {
            font-size: 20px;
            line-height: 1.4;
          }
        }
        @media all and (min-width: 1920px) {
          width: 100%;
        }
      }
    }
  }
  @media all and (max-width: 639px) {
    padding: 0;
  }
  @media all and (min-width: 640px) {
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
  }
  @media all and (min-width: 1024px) {
    max-width: 100%;
  }
  @media all and (max-width: 1023px) {
    max-width: 100%;
  }
  @media (min-width: 1280px) and (max-width: 1439px) {
    max-width: 100%;
    padding: 0;
  }
  @media (min-width: 1440px) and (max-width: 1919px) {
    max-width: 1024px;
    margin: auto;
  }
  @media (min-width: 1920px) {
    max-width: 1200px;
    margin: auto;
  }
}

/* auskick-hero-section */

.auskick-hero #video_container {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.auskick-hero #video_container video {
  height: 100%;
  object-fit: cover;
  /* display: block; */
}

.auskick-hero {
  background-position: right;
  background-size: contain;
  background-color: #40cff0;

  &:before {
    background-image: none !important;
  }

  .left-center-text {
    left: 80px !important;

    @media (max-width: 639px) {
      width: 100%;
      height: fit-content;
      position: absolute;
      top: 0px;
      padding-top: 32px;
      left: 0 !important;
    }

    p.setup_title {
      font-style: normal;
      font-weight: 700;
      font-size: 12px !important;
      line-height: 15px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: #01285e;
      margin-bottom: 1.5rem;
    }
    h1 {
      font-family: "filson-soft";
      font-weight: 800;
      font-size: 48px;
      line-height: 52px;
      @media (min-width: 1920px) {
        font-size: 72px;
        line-height: 76px;
        color: #01285e;
      }
      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 42px;
        line-height: 1;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 24px !important;
      line-height: 36px;
      color: #070707;
    }
    p.subheading {
        max-width: 100%;
      @media (min-width: 1920px) {
        font-size: 24px;
        line-height: 36px;
        color: #070707;
      }
      @media (max-width: 767px) {
        font-size: 20px !important;
        line-height: 28px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 20px !important;
        line-height: 1.38;
      }
    }
      @media (min-width: 1024px) and (max-width: 1439px) {
      width: 50% !important;
      padding-right: 32px !important;
      left: 32px !important;
    }
    @media (min-width: 1440px) and (max-width: 1919px) {
      width: 50% !important;
      padding-right: 80px !important;
    }
    @media (min-width: 1920px) {
      width: 50% !important;
      padding-right: 120px !important;
      left: 120px !important;
    }
  }
  .left-center-text.mobile-top {
    @media (min-width: 640px) and (max-width: 1023px) {
      left: 0 !important;
      width: 50%;
      padding: 32px 0px 12px 32px;
    }
  }
  @media (min-width: 1920px) {
    margin-top: 82px;
  }
  @media (max-width: 639px) {
    margin-top: 50px;
    background-position: bottom;
  }
}

.auskick-find-centre-section-inner a.btn-find-centre {
  background-image: none;
}

.auskick-find-centre-section-inner a .xs-hide {
  display: none !important;
}

@media (min-width: 640px) and (max-width: 767px) {
  .header-prize {
    padding-left: 30px;
  }
}

.auskick-program-page {
  .field-type-text-with-summary {
    .field-items {
      .field-item {
        p.rtecenter {
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          color: #070707;
          margin: 0 auto 1rem auto;
          max-width: 1100px;

          &:not(:first-child) {
            margin: auto;
            margin-bottom: 1.5rem;
            text-align: center;
            padding: 0;
          }
          @media (max-width: 640px) {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
    @media (min-width: 768px) {
      padding-top: 30px;
    }
    @media (max-width: 767px) {
      padding-top: 25px;
    }
  }
}

/* textboxes section */
.field-name-field-textboxes-content-sections {
  .field-label {
    display: none;
  }
  .field-items {
    max-width: 100% !important;
    .field-item {
      padding-top: 20px;
      background: #f8f8f8;
      margin: 0.6rem 2rem 0.6rem 0;

      .activity-section-image {
        max-width: 30px;
        min-width: 20px;
        padding: 16px 0px;
      }
      .activity-section-text {
        .activity-section-text-inner {
          padding: 0;
          h2 {
            font-style: normal;
            font-weight: 800;
            font-size: 24px !important;
            line-height: 32px;
            text-align: center;
            color: #01285e;
          }
        }
      }
      @media (max-width: 639px) {
        width: 100%;
        margin: 0.6rem 0;
      }
      @media (min-width: 640px) and (max-width: 1023px) {
        width: 48.5%;
        margin: 0.6rem 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (min-width: 640px) and (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

/* page cards content section */

.field-name-field-pagecards-content-sections {
  .field-label {
    display: none;
  }
  .field-items {
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100% !important;

    .field-item {
      width: 49% !important;
      margin-bottom: 2rem;
      background: #f8f8f8;
      padding-bottom: 0 !important;

      .activity-section-image {
        position: relative;
        top: 4px;
      }

      .activity-section-text {
        .activity-section-text-inner {
          padding: 32px;
          a {
            display: inherit;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            color: #0052b0;
            margin: 2rem 0 1.5rem 0;

            &::after {
              border-style: solid;
              border-width: 0.25em 0.25em 0 0;
              content: "";
              display: inline-block;
              height: 0.45em;
              position: relative;
              top: 0.15em;
              vertical-align: top;
              width: 0.45em;
              left: 0;
              transform: rotate(45deg);
            }
          }
          h2 {
            color: #01285e;
            margin-bottom: 1rem;
            @media screen and (max-width: 1023px) {
              font-size: 24px;
              line-height: 32px;
              padding-top: 32px;
            }
            @media (min-width: 1280px) and (max-width: 1919px) {
              font-size: 40px;
              line-height: 40px;
              padding-top: 60px;
            }
            @media (min-width: 1920px) {
              font-size: 64px;
              line-height: 60px;
              padding-top: 60px;
            }
          }
        }
      }
      @media (max-width: 640px) {
        width: 100% !important;
      }
    }
    .field-item.even:first-child {
      background: #40cff0;
    }
    .field-item.odd:last-child {
      background: #40cff0;
    }
    .field-item.even:last-child {
      background: #f8f8f8 !important;
    }
    .field-item.odd:first-child {
      background: #f8f8f8 !important;
    }
  }
}

/* Image and content section */

.paragraphs-items-field-sections-full {
  .field-name-field-sections {
    .field-items {
      .field-item {
        flex-direction: row-reverse !important;
        background-color: transparent !important;
        padding: 0px 60px !important;

        .activity-section-text {
          .activity-section-text-inner {
            padding: 0px !important;
            p {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #070707;
              text-align: left;

              @media screen and (max-width: 1279px) {
                font-size: 16px;
                line-height: 24px;
              }

              @media (min-width: 1280px) {
                font-size: 18px;
                line-height: 32px;
              }

              a.inline-link {
                color: #015cda;
                text-decoration: underline;
                font-weight: 400;
                display: inline-block;
                width: fit-content;
                font-size: 16px;
              }
            }
            p:first-child {
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 15px;
              letter-spacing: 1.2px;
              text-transform: uppercase;
              color: #02295d;
            }
            h2 {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;
              letter-spacing: -0.01em;
              color: #02295d;
              text-align: left;
              margin: 0;
              max-width: 100%;
              @media screen and (max-width: 1279px) {
                font-size: 24px;
                line-height: 32px;
                max-width: 100%;
              }
              @media (min-width: 1280px) {
                font-size: 32px;
                line-height: 36px;
              }
              @media (min-width: 1920px) {
                font-size: 48px;
                line-height: 52px;
              }
            }
            a:last-child {
              font-style: normal;
              width: 100%;
              text-align: left;
              font-weight: 700;
              font-size: 18px;
              line-height: 18px;
              color: #023c91;
              background-color: transparent;
              box-shadow: none;
              padding-left: 0;

              &:hover {
                text-decoration: underline;
              }
            }
            @media (max-width: 767px) {
              padding: 0;
              width: 100%;
              max-width: 100%;
            }
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            width: 42%;
            margin-left: auto;
          }
          @media (max-width: 767px) {
            padding: 0;
            width: 100%;
            max-width: 100%;
          }
        }
        .activity-section-image {
          align-self: auto !important;
          @media (max-width: 767px) {
            width: unset;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            width: 50%;
          }
        }
        @media (max-width: 579px) {
          padding: 0 !important;
        }
        @media (max-width: 767px) {
          max-width: 100% !important;
          width: 100%;
          display: flex;
          flex-direction: column-reverse !important;
        }
        @media (max-width: 1023px) {
          max-width: 95%;
          padding: 0;
          margin: auto;
          align-items: center;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
          max-width: 100% !important;
          padding: 0px 30px !important;
          justify-content: space-between !important;
        }
        @media (min-width: 768px) and (max-width: 1439px) {
          padding: 0 !important;
        }
      }
      .field-item.odd {
        flex-direction: row !important;
        @media (min-width: 1440px) {
          padding: 0 80px;
        }
        @media (min-width: 1920px) {
          padding: 0 120px !important;
        }
        @media (max-width: 767px) {
          display: flex;
          flex-direction: column-reverse !important;
        }
        .activity-section-img {
          @media (max-width: 767px) {
            margin: 0 32px;
          }
        }
        .activity-section-text {
          @media (max-width: 767px) {
            width: 100%;
            max-width: 100% !important;
          }
          .activity-section-text-inner {
            @media (max-width: 767px) {
              padding: 0;
              width: 100%;
              max-width: 100%;
            }
            @media (min-width: 1220px) and (max-width: 1919px) {
              padding: 0px 60px !important;
            }
            @media (min-width: 1920px) {
              padding: 0 10px 0 152px !important;
            }
            @media (min-width: 768px) and (max-width: 1439px) {
              padding-right: 32px !important;
              padding-left: 32px !important;
            }
            @media (min-width: 1440px) and (max-width: 1919px) {
              padding-right: 80px;
            }
          }
        }
      }
      .field-item.even {
        @media (min-width: 1440px) {
          padding: 0 80px;
        }
        @media (min-width: 1920px) {
          padding: 0 170px !important;
        }
        .activity-section-img {
          @media (max-width: 767px) {
            margin: 0 32px;
          }
        }
        .activity-section-text {
          @media (max-width: 767px) {
            max-width: 100%;
            width: 100%;
          }
          .activity-section-text-inner {
            padding-left: 0;
            @media (max-width: 767px) {
              max-width: 100%;
              width: 100%;
            }
            @media (min-width: 768px) and (max-width: 1439px) {
              padding-left: 32px !important;
            }
            @media (min-width: 1440px) and (max-width: 1919px) {
              padding-left: 80px !important;
            }
            @media (min-width: 1920px) {
              padding-left: 120px !important;
            }
          }
        }
      }
    }
  }
}

.img-starts-right {
  margin-top: 2rem;
}
.img-starts-right
  .paragraphs-items
  .field-name-field-sections
  .field-items
  .field-item.even {
  flex-direction: row !important;
}
.img-starts-right
  .paragraphs-items
  .field-name-field-sections
  .field-items
  .field-item.even
  .activity-section-text
  .activity-section-text-inner {
  padding-left: 80px !important;
}
.img-starts-right
  .paragraphs-items
  .field-name-field-sections
  .field-items
  .field-item.odd
  .activity-section-text
  .activity-section-text-inner {
  padding-left: 0px !important;
  padding-right: 80px !important;
}
@media (max-width: 767px) {
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.odd
    .activity-section-text
    .activity-section-text-inner {
    padding: 0 !important;
  }
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.even
    .activity-section-text
    .activity-section-text-inner {
    padding: 0 !important;
  }
}
@media (min-width: 1920px) {
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.odd
    .activity-section-text
    .activity-section-text-inner {
    padding: 0 150px 0 120px !important;
  }
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.even
    .activity-section-text
    .activity-section-text-inner {
    padding: 0 120px 0 150px !important;
  }
  .auskick-video-section h2 {
    font-size: 48px;
    line-height: 52px;
  }
  .auskick-video-section p {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (min-width: 768px) and (max-width: 1439px) {
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.odd
    .activity-section-text
    .activity-section-text-inner {
    padding: 10px 32px 10px 0px !important;
  }
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.even
    .activity-section-text
    .activity-section-text-inner {
    padding: 10px 10px 10px 32px !important;
  }
}
.img-starts-right
  .paragraphs-items
  .field-name-field-sections
  .field-items
  .field-item.odd {
  flex-direction: row-reverse !important;
}
@media (max-width: 767px) {
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.even {
    flex-direction: column-reverse !important;
  }
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.odd {
    flex-direction: column-reverse !important;
  }
}

.auskick-video-section h2 {
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  font-weight: 700;
}
.auskick-video-section p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.auskick-activity-list-section hr.overline {
  display: none;
}

.auskick-faq-section {
  .content {
    h1 {
      font-family: "filson-soft";
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #02295d;
    }
  }
}
.field-name-field-3wide-content-sections {
  .field-label {
    display: none;
  }
  .field-items {
    max-width: 100% !important;
    justify-content: space-between;
    .field-item {
      background: #f8f8f8;
      margin: 10px 0px;
      .activity-section-text {
        padding: 32px;
        .activity-section-text-inner {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 32px;
          text-align: center;
          color: #070707;
          h2 {
            font-family: "filson-soft";
            font-style: normal;
            font-weight: 800;
            font-size: 32px;
            line-height: 36px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #01285e;

            @media (max-width: 1023px) {
              font-size: 24px;
            }
          }
          @media (max-width: 1023px) {
            font-size: 16px;
          }
        }
      }
      @media (max-width: 639px) {
        width: 100%;
      }
    }
    @media (max-width: 639px) {
      flex-direction: column;
    }
  }
}

.auskick-overview-hero {
  background-color: transparent;
  .left-center-text {
    @media (max-width: 639px) {
      text-align: center;
    }
    h1 {
      font-family: "filson-soft";
      color: #fff !important;
      text-transform: unset !important;
      @media (max-width: 639px) {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
    }
    p {
      color: #fff !important;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
.auskick-program-page
  .field-type-text-with-summary
  .field-items
  .field-item
  p.rtecenter:first-child {
  font-weight: 800;
  font-size: 32px !important;
  line-height: 24px;
  text-align: center;
  color: #01285e !important;
  margin: 0 auto 1rem auto;
  padding: 0;
}
.field-type-text-with-summary .field-items .field-item p.overview-sm-text {
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  color: #01285e !important;
}
.faq-container .accordion .accordion__item:last-child {
  border-bottom: none;
}
.auskick-columns-section {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (max-width: 639px) {
  .auskick-columns-section {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
}

@media (max-width: 1439px) {
  .content-video-container {
    max-width: 100%;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .content-video-container {
    max-width: 100% !important;
    margin: 1rem 80px !important;
  }
}
@media (min-width: 1920px) {
  .content-video-container {
    max-width: 100% !important;
    margin: 1rem 120px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.odd
    .activity-section-text
    .activity-section-text-inner {
    padding-right: 80px !important;
  }
  .img-starts-right
    .paragraphs-items
    .field-name-field-sections
    .field-items
    .field-item.even
    .activity-section-text
    .activity-section-text-inner {
    padding-left: 80px !important;
  }
}
@media (min-width: 1920px) {
  .field-type-text-with-summary .field-items .field-item p.overview-sm-text {
    font-size: 18px !important;
  }
}
@media (max-width: 767px) {
  .auskick-program-page
    .field-type-text-with-summary
    .field-items
    .field-item
    p.rtecenter {
    font-size: 20px !important;
  }
  .auskick-video-section h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .auskick-video-section p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1919px) {
  .auskick-video-section h2 {
    font-size: 32px;
    line-height: 36px;
  }
  .auskick-video-section p {
    font-size: 18px;
    line-height: 32px;
  }
}
.faq-container .accordion .accordion__item .accordion__trigger.expanded:after {
  content: "-";
  float: left;
  margin-right: 14px;
  margin-top: 2px;
  width: 30px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  color: #02295d;
}
