/* remove default styling on inputs etc */
input,
select,
textarea {
  outline: none;
  border-radius: 0;
  -webkit-appearance: none;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.custom-error {
  text-align: center;
}

.messages.error,
.custom-error {
  color: $red;
  margin: 10px 0;
}

.messages.error ul {
  margin: 10px 0;
  padding: 0;
}

.messages.error li {
  color: $red;
  list-style-type: none;
  margin: 10px 0;
}

/* Mac like toggle switch*/
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

/* Floating Labels

.floating-labels {
  label {
    position: absolute;
    -webkit-transform: translate(0px, 15px);
    transform: translate(0px, 15px);
    font-size: 16px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.26);
    text-overflow: clip;
    cursor: text;
    pointer-events: none;
  }

  input:focus ~ label,
  textarea:focus ~ label {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    font-size: 12px;
    line-height: 15px;
    text-overflow: ellipsis;
  }

  input:not(:focus).mui--is-not-empty ~ label, input:not(:focus)[value]:not([value=""]):not(.mui--is-empty):not(.mui--is-not-empty) ~ label,
  input:not(:focus):not(:empty):not(.mui--is-empty):not(.mui--is-not-empty) ~ label,
  textarea:not(:focus).mui--is-not-empty ~ label,
  textarea:not(:focus)[value]:not([value=""]):not(.mui--is-empty):not(.mui--is-not-empty) ~ label,
  textarea:not(:focus):not(:empty):not(.mui--is-empty):not(.mui--is-not-empty) ~ label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    line-height: 15px;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    text-overflow: ellipsis;
  }

} */

.webform-client-form {
  margin: 40px auto 60px;
  max-width: 550px;

  label,
  .description {
    display: block;
    color: #9b9b9b;
  }

  .description {
    font-size: 12px;
    margin: 1px 0 6px;
  }

  label {
    margin: 16px 0 0;
  }

  label.option {
    display: inline-block;
    margin: 0 0 0 6px;
  }

  input,
  select {
    height: 32px;
    margin: 4px 0 4px;
    font-size: 16px;
    padding-left: 6px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #d7d7d7;
    box-sizing: border-box;
  }

  textarea {
    margin: 4px 0 4px;
    width: 100%;
    height: 60px;
    padding: 6px;
    border: none;
    border-bottom: 1px solid #d7d7d7;
    box-sizing: border-box;
  }

  input.form-checkbox,
  input.form-radio {
    vertical-align: middle;
    width: 10px;
  }

  input.button-primary {
    background-color: rgb(0, 82, 156);
    color: #ffffff;
    opacity: 1;
    border: none;
    height: 50px;
    line-height: 50px;
    padding: 0;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 18px;
  }

  input.button-primary:hover {
    opacity: 0.8;
  }

  .form-radios {
    display: flex;

    .form-type-radio {
      flex: 1;
    }
  }

  .webform-datepicker {
    display: flex;
    .form-type-select {
      flex: 1;
    }
  }

  @media all and (min-width: 640px) {
    .form-actions {
      text-align: center;
    }
    input.button-primary {
      width: 350px;
    }
  }
}

/* sales force form */

.salesforce-section {
  padding-top: 50px;

  .field {
    .field-items {
      .field-item {
        form {
          width: 50%;
          margin: auto;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          padding: 50px;
          p {
            width: 100%;
            display: flex;
            flex-direction: column;
            label {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 32px;
              color: #02295d;
              margin-bottom: 1rem;

              @media (min-width: 1440px) and (max-width: 1919px) {
                font-size: 1.2rem;
              }
              @media (min-width: 1920px) {
                font-size: 1.4rem;
              }
            }
            input {
              display: block;
              width: 100%;
              height: calc(1.5em + 0.75rem + 2px);
              padding: 0.375rem 0.75rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: #495057;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
              @media (min-width: 1440px) and (max-width: 1919px) {
                font-size: 1.4rem;
              }
              @media (min-width: 1920px) {
                font-size: 1.6rem;
              }
            }
            &:last-child {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 30px 0;
              input {
                width: fit-content;
                height: fit-content;
                padding: 14px 24px;
                gap: 4px;
                background: #e41e31;
                box-shadow: 0px 2px 8px rgba(232, 33, 56, 0.25);
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 20px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #ffffff;
                border: none;

                @media (min-width: 1440px) and (max-width: 1919px) {
                  font-size: 1.4rem;
                }
                @media (min-width: 1920px) {
                  font-size: 1.6rem;
                }
              }
            }
          }
          @media all and (max-width: 1023px) {
            width: 80%;
            padding: 30px;
          }
        }
      }
    }
  }
}
