/**
Styles for the notification strip at the top of most pages
*/
.notification-strip{
    background-color: #0052B0;
    color: white;
    height: 70px;
    overflow: hidden;
    position: fixed;
    max-height: 70px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: transform 0.25s;
    .notification-strip-inner{
        
        padding: 0 40px;
        position: relative;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center;
        text-align: center;
        p {
            max-width: 360px;
            margin-left: auto;
            margin-right: auto;
        }
        p, a{
            color: white;
            font-size: 13px;
        }
        a {
            text-decoration: underline;
        }
    }
    .close-notification-bar{
        position: absolute;
        right: 10px;
        height: 14px;
        width: 14px;
        top: 10px;
    }   
}

body.logged-in .notification-strip{
    top: 40px;
}

.notification-strip.scrolled, .notification-strip.remove{
    transform: translateY(-80px);
}

.has-notification-bar .menu-spacer{ 
    padding-top: 120px;
}

.has-notification-bar .fixed-header{
    top: 70px;
}

.has-notification-bar {
    .programs-menu-container.active, .main-menu-container.active{
        top: 70px;
    }

    .hero {
        margin-top: 120px;
    }
}

body.logged-in .has-notification-bar .fixed-header {
    top: 110px;
}


.has-program-menu.has-notification-bar .hero, 
.has-program-menu.has-notification-bar .half-image-hero{
    margin-top: 240px;
}

.has-program-menu.has-notification-bar .menu-spacer{
    padding-top: 240px;
}

@media all and (min-width: 540px) {
    .notification-strip{
        .notification-strip-inner{
            max-width: 500px;
            margin: 0 auto;
            padding: 0 10px;
            
        }
    }
}


@media all and (min-width: 640px) {
    .notification-strip{
        height: 50px;
        opacity: 1;
        .notification-strip-inner{
            max-width:1920px;
            p {
                max-width: none;
            }
            .notification-logo-auskick{
                width:100px;
                height: auto;
            }
        
        
        }

        .close-notification-bar{
            height: 18px;
            width: 18px;
            top: 16px;
            right: 10px;
        }
    }

    .has-notification-bar{
        margin-top: 50px;
    }

    .has-notification-bar .menu-spacer{ 
        padding-top: 50px;
    }

    body.logged-in .notification-strip{
        top: 84px;
    }

    .has-notification-bar .fixed-header{
        top: 50px;
    }  

    .has-notification-bar {
        .programs-menu-container.active, .main-menu-container.active{
            top: 100px;
        }
        .hero {
            margin-top: 50px;
        }
    }

    body.logged-in .has-notification-bar .fixed-header{
        top: 134px;
    }

    body.logged-in .has-notification-bar {
        .programs-menu-container, .main-menu-container{
            top: 184px;
        }
    }
    
    .has-program-menu.has-notification-bar .hero, 
    .has-program-menu.has-notification-bar .half-image-hero{
        margin-top: 220px;
    }

    .has-program-menu.has-notification-bar .menu-spacer {
        padding-top: 170px;
    }
    
}


@media all and (min-width: 1024px) {
    .has-program-menu.has-notification-bar .hero, 
    .has-program-menu.has-notification-bar .half-image-hero{
        margin-top: 170px;
    }

    .has-program-menu.has-notification-bar .menu-spacer {
        padding-top: 120px;
    }
}



.fixed-header.scrolled {
    margin-top: 0!important;
}

@media all and (max-width: 639px) {
    .has-notification-bar{
        margin-top: 70px;
    }
} 