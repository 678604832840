
/**
Styles relating to modals
*/
/* The Overlay (background) */
.modal-overlay.ReactModal__Overlay, .modal-overlay-black.ReactModal__Overlay{
    opacity: 0;
    position: fixed; 
    z-index: 80; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgba(155, 155, 155); /* Fallback color */
    background-color: rgba(155, 155, 155, 0.9); /* Black w/ opacity */
    transition: opacity 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-overlay.show,
.modal-overlay.ReactModal__Overlay--after-open{
  z-index: 80;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.transition-modal,
.share-modal,
.download-modal{
  display:none;
}

.transition-modal.show,
.transition-modal.ReactModal__Content--after-open,
.share-modal.show,
.download-modal.ReactModal__Content--after-open{
  display:flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content/Box */
.modal {
    opacity: 1;
    z-index: 85; /* Sit on top */
    background-color: #ffffff;
    margin: 0; /* 15% from the top and centered */
    padding: 15px;
    width: 100%; /* Could be more or less, depending on screen size */
    height: 100vh;
    transition: opacity 0.5s ease;
}

.ambassador-modal, .clinic-time-modal{
  margin: 0;
  padding: 15px;
  border: 1px solid #999;
  width: 80%;
  max-width: 760px;
  background-color: white;
}

.modal-overlay.show .modal{
  opacity: 0.95;
  transition: opacity 0.5s ease;
}

.close-modal-button{
  position: absolute;
  top: 10px;
  right: 10px;
}

.transition-modal, .download-modal{
  position: relative;
  
  .logo-container{
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    .play-logo-blue, .modal-logo-inner{
      display: inline-block;
      width: 120px;
      height: 50px;
      background: no-repeat url(../../images/logos/play-afl-blue.svg) 50% 50%/100% auto;
    }
    .right-arrow{
      display: inline-block;
      width: 40px;
      height: 50px;
      background: no-repeat url(../../images/svg/arrow-forward.svg) 50% 50%/30px 30px;
    }
    .modal-logo-inner.gameday{
      width: 150px;
      background: no-repeat url(../../images/logos/game-day-pos-small.jpg) 50% 50%/100% auto;
    }
    .modal-logo-inner.playhq{
      width: 90px;
      background: no-repeat url(../../images/logos/playhq.jpg) 50% 50%/100% auto;
    }
  }
  .modal-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    max-width: 450px;
  }
  .cancel-link{
    color: #7E7E7E;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    margin-top: 18px;
  }
}

.modal .close-modal {
  margin: 10px 10px;
  z-index: 100;
}

/* flex video modal */
.modal-overlay-black.ReactModal__Overlay{
    background-color: rgba(0, 0, 0); 
    background-color: rgba(0, 0, 0, 0.9); 
    transition: opacity 0.5s ease;
}

.modal-overlay-black.show,
.modal-overlay-black.ReactModal__Overlay--after-open{
  z-index: 80;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.modal-overlay-black .video-modal{
  max-width: 1280px;
  margin: 0 auto;
}

.modal-overlay-black .action-close-modal-video{
  padding:  20px;
  float:  right;
}
.flex-video.widescreen {
    padding-bottom: 56.25%;
}

.flex-video {
    position: relative;
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
    clear: both;
    box-shadow: 0 0 16px 2px #000;

}
.flex-video iframe, .flex-video object, 
.flex-video embed, .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

p.modal-small-text{
  font-size: 18px;
  font-weight: normal;
  color: $body_text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: none;
}

@media all and (max-width: 640px) {
  .modal {
      margin: 0;
      width: 100%;
      height: 100%;
      padding: 0;
  }
  .modal-overlay-black .video-modal{
    padding: 0;
  }

  .transition-modal, .download-modal {
    height: 100%;
    width: 100%;
    border:none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-content{
      display: flex;
      height: 100vh;
    }
  }

  .modal-content {
    background-color: #ffffff;
    position: relative;
  }

  .video-modal{
    .flex-video{
      width: 100vw;
    }
  }

}

@media all and (min-width: 640px) {
  .modal {
      max-width: 620px;
      max-height: 400px;
      box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
  }

  .ambassador-modal {
    border: none;
    background-color: white;
    margin: 10% auto;
  }

  .transition-modal, .download-modal{
    height: 400px;
    text-align: center;
    .modal-text{
      width: 100%;
      font-size: 24px;
      line-height: 1.17;
    }
    .cancel-link{
      font-size: 18px;
      margin-top: 24px;
    }
    .modal-small-text{
      margin: 16px 0 12px;
    }
    .logo-container{
      .play-logo-blue, .modal-logo-inner{
        width: 150px;
        height: 60px;
        background-size: auto 60px;
      }
      .right-arrow{
        width: 60px;
        height: 60px;
      }
      .modal-logo-inner.gameday{
        width: 210px;
        background-size: auto 40px;
      }
      .modal-logo-inner.playhq{
        width: 140px;
        background-size: auto 50px;
      }
    }
  }

  .video-modal{
    .flex-video{
      width: 700px;
    }
  }
}

@media all and (min-width: 832px) {
  .modal {
      max-width: 660px;
      max-height: 400px;
  }
  .modal.full{
    max-width: 80%;
    max-height:  none;
    margin: 5% auto;
  }
}

@media all and (min-width: 1024px) {
  .video-modal{
    .flex-video{
      width: 940px;
    }
  }
}

@media all and (min-width: 1480px) {
  .video-modal{
    .flex-video{
      width: 1200px;
    }
  }
}
