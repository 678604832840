
.hero-video-link{
  background-image: url(../../images/play-video.png) at-2x;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 70px;
  height: 70px;
  display: block;
  margin: auto;
}

.centered-home{
  position: absolute;
  top: 60px;
  padding: 0 60px;
}

.respond-vid{
  position: relative;
  padding-bottom: 56.25%;
}
.respond-vid .r16-9{
  padding-bottom: 56.25%;
}
.respond-vid iframe{
  position: absolute;
  width: 100%;
  height:  100%;
  top:  0px;
  left:  0px;
}
.hero-video-overlay{
  width:  100%;
  height:  100vh;
  background-size: cover;
  background-position: top center;
}
.play-afl-hero-video{
  height: 100vh;
   background-color: #000;
}


.end-buttons a.btn{
  font-style: italic;
  padding-left: 0px;
  padding-right: 0px;
  width: 230px;
  margin-top: 30px;
}

/* video.js skin */
.play-afl-hero-video{
  position: relative;
  width: 100%;
}
.play-afl-hero-video:after{
  content:' ';
  display: block;
  padding-top:  56.25%;
}
.play-afl-hero-video .video-js{
  position: absolute;
  left: 0px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.vjs-pafl-skin .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    font-size: 0.3em;
    height: 0.5em;
    top: -0.5em;
    font-size: 1em;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.video-js.vjs-pafl-skin .vjs-slider{
  margin:  0;
  height:  10px;
  background-color: #000;
}

.video-js.vjs-pafl-skin .vjs-slider .vjs-load-progress{
  height: 7px;
}
.video-js.vjs-pafl-skin .vjs-slider .vjs-play-progress{
  height: 7px;
  background-color: #86B4DD;
}
.video-js.vjs-pafl-skin .vjs-slider .vjs-slider-bar{

}
.video-js.vjs-pafl-skin .vjs-volume-bar {
    margin: 0.7em .45em;
}
.video-js.vjs-pafl-skin .vjs-mouse-display:after, .video-js.vjs-pafl-skin .vjs-play-progress:after, .video-js.vjs-pafl-skin .vjs-time-tooltip {

}
.video-js.vjs-pafl-skin .vjs-mouse-display:before, .video-js.vjs-pafl-skin .vjs-play-progress:before{
  width:  5px;
  height:  7px;
  background-color: #fff;
  top:  0;
  right:  0;
}
.video-js.vjs-pafl-skin .vjs-mouse-display:before, .video-js.vjs-pafl-skin .vjs-play-progress:before{
}
.video-js.vjs-pafl-skin .vjs-play-progress:before{
  content: "";
  width:  0px;
  right: 0px;
  height:  7px;
  background-color: #fff;
  transition: width .2s, right .2s;
}
.video-js.vjs-pafl-skin .vjs-big-play-button{
  display:  none;
}
.video-js.vjs-pafl-skin .vjs-progress-control.vjs-control:hover .vjs-play-progress:before{
  width:  12px;
  right: -6px;
}
.video-js.vjs-pafl-skin .vjs-control{
  color:  rgba(256, 256, 256, 0.5);
}

.video-js.vjs-pafl-skin .vjs-fullscreen-control,
.video-js.vjs-pafl-skin .vjs-fullscreen-control:before{
  position: absolute;
  right: 0px;
}
.video-js.vjs-pafl-skin .vjs-resolution-button:before,
.video-js.vjs-pafl-skin .vjs-resolution-button{
  position: absolute;
  right: 30px;
}
.video-js.vjs-pafl-skin .vjs-control:hover{
  color:  rgba(256, 256, 256, 1);
}
.video-js.vjs-pafl-skin .vjs-control-bar,
.video-js.vjs-pafl-skin .vjs-big-play-button,
.video-js.vjs-pafl-skin .vjs-menu-button .vjs-menu-content{
  background-color: #000;
}
.video-js.vjs-pafl-skin .vjs-load-progress{
/*   background-color: rgba(255,94,69,0.3);
 */  background-color: rgba(255,255,255,0.3);
}
.video-js.vjs-pafl-skin .vjs-load-progress div{
  background-color: transparent;
}
.video-js .vjs-control:focus, .video-js .vjs-control:focus:before, .video-js .vjs-control:hover:before{
  text-shadow: none;
}

.field-type-video-embed-field, .embedded-video{
  .field-label{display: none;}
  .player{
    position: relative;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.embedded-video{
  margin: 20px 0;
}



@media all and (min-width: 640px){
  .hero-video-link{
    width: 40px;
    height: 40px;
    margin-bottom: 0px;
  }
  .hero-video-overlay{
    width:  100%;
    height:  100%;
    background-image: none !important;
  }
  .play-afl-hero-video{
     height: 100%;
     background-color: transparent;
  }
  .play-afl-hero-video .video-js{
    position: relative;
    top:  0px;
    transform: none;
    height:0;
  }
  .centered-home{
    position: absolute;
    bottom:  auto;
    top:  36%;
    padding: 0;
  }
}

@media all and (min-width: 1024px) {
  .hero-video-link{
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  .centered-home{
    position: absolute;
    top:  43%;
  }
  .hero-video-link:hover{
    background-image: url(../../images/play-video-hover.png) at-2x;
  }
}

@media all and (min-width: 1280px) {
  .hero-video-link{
    width: 80px;
    height: 80px;
    margin-bottom: 50px;
  }

}



@media all and (min-width: 1800px) {
  .centered-home{
    position: absolute;
    top:  48%;
  }

  .hero-video-link{
    width: 92px;
    height: 92px;
    margin-bottom: 100px;
  }
}
