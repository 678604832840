/**
Contains CSS for FAQ pages and enables dropdown selectors for FAQ sections
*/

.faq-section-value {
  color: $blue_light;
  padding-right: 30px;
  background: url(../../images/arrow-down.svg) no-repeat right center;
  cursor: pointer; 
  cursor: hand;
}


.close-faq-section-selector{
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.faq-section-selector-values{
  width:100vw;
  height:100vh;
  z-index:10;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  text-align: center;
  padding: 16px 0 16px;
  transform: translateY(100vh);
  transition: transform 0.25s ease-in;
}

.faq-section-selector-values.open{
  transform: translateY(-0);
  transition: transform 0.25s ease-in;
}

.faq-section-selector-values__link {
  font-size: 24px;
  font-weight: 800;
  line-height: 1.88;
  color: #898989;
}

.skills .faq-section-selector-values__link {
  font-size: 18px;
  line-height: 2.22;
}

.faq-section-selector-values__link:hover,
.faq-section-selector-values__link.selected {
  color: #3b7adb;
}

@media all and (min-width: 640px){
  .faq-section-selector-values{
    position: absolute;
    top: 56px;
    max-height: 380px;
    overflow: visible;
    right: -47px;
    background-color: #ffffff;
    width: 110px;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.41);
	  border: solid 1px #e4e4e4;
    padding-bottom: 10px;
    z-index: 10;
    display: none;
    transform: none;
    transition: none;
  }

  .faq-section-selector-values__link {
    font-size: 22px;
  }
  
  .faq-section-selector-values.wide{
    width: 320px;
  }
  .faq-section-selector-values.short{
    max-height: 180px;
  }

  .faq-section-selector-values.open{
    display: block;
    transform: none;
    transition: none;
  }

  .faq-section-selector-values:after,
  .faq-section-selector-values:before {
  	bottom: 100%;
  	left: 50%;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  }

  .faq-section-selector-values:after {
  	border-color: rgba(255, 255, 255, 0);
  	border-bottom-color: #ffffff;
  	border-width: 10px;
  	margin-left: -10px;
  }

  .faq-section-selector-values:before {
  	border-color: rgba(216, 216, 216, 0);
  	border-bottom-color: #d8d8d8;
  	border-width: 11px;
  	margin-left: -11px;
  }

  .faq-section-selector-values.wide{
    width: 260px;
    text-align: left;
    padding-left: 20px;
    max-height: 340px;
  }

  .faq-section-selector-values.wide:after,
  .faq-section-selector-values.wide:before {
    left: 78%;
  }

}
