

/*
Useful for debugging overflow states

* {
    outline: 1px solid red;
}
*/

/** Try to hide apple ios detection */
a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

/****  UNIVERSAL ****/
.element-invisible{
  display:none !important;
}

.responsive-img {
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.node-unpublished{
    background-color: #ffffff;
    border: 4px solid red;
    box-sizing: border-box;
}

@media only screen
and (max-width: 640px)
and (orientation : portrait) {
  .no-overflow-mobile {
    overflow: hidden;
  }
}

div[data-entity-embed-display="image"] {
  width: 200px;
  height: auto;
}

div[data-entity-embed-display="image"].align-right{
  margin-left: 20px;
}

div[data-entity-embed-display="image"] img{
  width: 100%;
  height: 100%;
}

@media (min-width: 1380px) {
  .xl-hide { display: none !important }
  .xl-inline { display: inline !important }
  .xl-block { display: block !important }
}

@media (min-width: 833px) and (max-width: 1023px){
  /**
  md-hide and lg-col seem to clash
  */
  .x-md-hide{ display:none !important }
}

@media (min-width: 1025px) and (max-width: 1380px){
  /**
  only hide between these values
  */
  .x-lg-hide{ display:none !important }
}

@media (max-width: 639px) {
  .xxs-hide { display: none !important }
}

hr {
  border: solid 1px #979797;
  margin: 20px 0 40px 0;
}

hr.thick-grey {
  border: solid 2px #e1e3e8;
  margin: 10px 0 10px 0;
}

.blocklink {
  width: 100%;
  height: 100%;
  z-index: 9;
  position: absolute;
  display: block;
  cursor: pointer; cursor: hand;
}

ul.clean {
    list-style: none; /* Remove list bullets */
    list-style-image: none;
    padding: 10px 0;
    margin: 0;
}


ul.clean > li {
  padding-left: 0;
}

ul.clean> li:before {
  content: none;
  padding-left: 0;
}

.right-arrow-blue{
  vertical-align: bottom;
  height: 18px;
}

.right-arrow-link {
  padding-right: 8px;
  margin-right: 8px;
  background: url(../../images/svg/RightArrowRed.svg) no-repeat right 60%;
}

.down-arrow-link {
  padding-right: 14px;
  margin-right: 8px;
  background: url(../../images/svg/DownArrowRed.svg) no-repeat right 50%/12px 12px;
}

.up-arrow-link {
  padding-right: 16px;
  margin-right: 8px;
  background: url(../../images/svg/UpArrowRed.svg) no-repeat right 50%/12px 12px;
}

.download-icon{
  vertical-align: -15%;
}

.center-mobile-only{
  text-align: center;
}

.body-wrapper-grey{
  background-color: #f5f5f5;
  height: 100%;
}

/******    MENU    ******/

.view-program-type-menu .view-content{
  clear: both;
}

.program-type-menu{
  background-color: #ffffff;
  margin:6px 3px 0 3px;
  padding:4px;
  box-sizing: border-box;
  border-bottom: 2px solid #dedede;
  height: 140px;
}

.find-club-link{
  bottom: 0;
}


/** MAP PROMO */

.map-promo {
  padding: 20px 20px 40px;
  background-color: #023C91;
  text-align: center;
  margin: 0 -20px;
  
  h2 {
    color: white;
    max-width: 36ch;
    margin: 20px auto 10px;
    font-family: 'PODIUMSharp-211', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1;
  }
}

/******  MOBILE FOOTER MENU ******/
.mobile-footer-menu li{
  padding: 26px 10px 24px 10px;
  position: relative;
  background: url(../../images/mobile-arrow-right.png) no-repeat 95% 50%;
  background-size: 10px;
}

.mobile-footer-menu li::after {
  content : "";
  position: absolute;
  left    : 2%;
  bottom  : 0;
  height  : 1px;
  width   : 94%;
  border-bottom: 1px solid #DEDEDE;
}

/******  DESKTOP FOOTER  ******/

.footer-social img {
  height:26px;
  width:auto;
  margin: 26px 10px 0 20px;
}

.content-header{
  text-align: center;
  margin: 46px 0 30px 0;
  padding:10px 16px 30px 16px;
  background-color: #edf5fe;
}

/******  HOME PAGE  ******/

 /*** SLIDER ***/
 #slides{
    perspective: 1000px;
     position: relative;
     padding: 0px;
     margin: 0px;
     list-style-type: none;
     z-index: 1;
 }

 .slide {
     position: absolute;
     left: 0px;
     top: 0px;
     width: 100%;
     height: 100%;
     opacity: 0;
     z-index: 1;
     -webkit-transition: opacity 1s;
     -moz-transition: opacity 1s;
     -o-transition: opacity 1s;
     transition: opacity 1s;
 }

 .slide img{
   width: 100%;
   height: auto;
 }

 .showing {
     opacity: 1;
     z-index: 2;
 }

.program-type-block, .assist-block{
  color: #ffffff;
  position: relative;
  .text-container{
    position: absolute;
    bottom: 10px;
    top: auto;
    .text-inner {
      margin-left: 20px;
      h3 {
        margin-top: 56px;
      }
    }
  }
  .btn-play-video{
    height: 60px;
    width: 60px;
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
  }
}

.program-type-block::before,
.assist-block::before{
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 99.96%);
}

.text-container {
  z-index: 11;
}

.more-programs-heading {
  text-align: center;
  margin: 30px auto;
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
}


.promo-content{
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.promo-inner-left-text{
    position: absolute;
    opacity: 0.99;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 34%;
    margin-left: 0;
    text-align: center;
    z-index: 9;
}


/********* CLUB FINDER **********/


.map-container{
  height: 400px;
}

.map-results{
  position:relative;
}


.club-teaser {
  position: relative;
  padding: 0 10px 10px 10px;
}

.club-teaser::after {
  content : "";
  position: absolute;
  left    : 2%;
  bottom  : 0;
  height  : 1px;
  width   : 94%;
  border-bottom: 1px solid #DEDEDE;
}

.club-description{
  margin-top: 14px;
}


.club-audience{
  margin:8px 0 8px;
}

.club-name h4{
  width: 96%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-club-description {
  font-weight: bold;
  text-transform: uppercase;
}

h4.map-club-name{
  margin:4px 0 6px;
}


a.view-club-details{
  cursor: pointer; cursor: hand;
}

.botton-aligned-container{
  display: flex;
  align-items: flex-end;
}


.more-clubs-container{
  position: relative;
  padding:16px 0 20px;
}

.more-clubs-container::after {
  content : "";
  position: absolute;
  left    : 2%;
  bottom  : 0;
  height  : 1px;
  width   : 94%;
  border-bottom: 1px solid #DEDEDE;
}

.club-details-block{
  font-weight: bold;
  text-align: center;
  margin-top:10px;
}

a.suburbLink {
  padding-right:36px;
}

.program-detail{
  background-color: #edf5fe;
  color: #ffffff;
  position:relative;
}

.program-detail::before{
  content:'';
  opacity: 0;
  width:100%;
  height:100%;
  top:0;
  position:absolute;
  background-color:#000;
  transition: opacity 0.25s;
}

.program-detail:hover::before{
    opacity:0.5;
    transition: opacity 0.25s;
}

.program-detail .text-container{
  height:50%;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.28) 100%);
  overflow: hidden;
}

.program-detail.afl-active{
  background: url(../../images/headers/Active_Header_M.jpg);
  background-size: cover;
}

.program-detail.afl-9s{
  background: url(../../images/headers/9s_Header_M.jpg) no-repeat top left;
  background-size: cover;
}

.program-detail.female-football{
  background: url(../../images/headers/Female_Header_M.jpg) no-repeat top left;
  background-size: cover;
}

.program-detail.club-football{
  background: url(../../images/headers/Club_Header_M.jpg) no-repeat top left;
  background-size: cover;
}

.program-detail.junior-football, .program-detail.juniorfootball{
  background: url(../../images/headers/Junior_Header_M.jpg) no-repeat top left;
  background-size: cover;
}

.program-detail.auskick{
  background: url(../../images/headers/Auskick_Header_M.jpg) no-repeat top left;
  background-size: cover;
}

/*****  PROGRAM FINDER PAGE  *****/
.program-finder {
  background-color: #edf5fe;
  padding:10px;
}

.program-finder h3{
  margin: 20px;
}
.program-finder-intro-text{
  padding:10px;
}


/***  FOOTER ASSIST LINKS  ***/
.assist-links .square{
  position: relative;
  background-color: #edf5fe;
  color: #ffffff;
  border-top: none;
}

.assist-links .text-container{
  overflow: hidden;
}

/********** afl9s rules **********/
.white-bg{
  background-color: #fff;
}

.grey-bg{
  background-color: #F2F2F2;
}

.grey-background {
  background-color: #f7f7f7;
}



.auskick-landing-footer-tickets{
    width: 140px;
    height: auto;
    margin: 20px 0;

    @media all and (min-width: 1024px){
      width: 160px;
      margin: 30px 0;
    }

    @media all and (min-width: 1480px){
      width: 200px;
      margin: 44px 0;
    }
}



@media all and (min-width: 640px){

  .pack-image.align-right {
    margin-left: 10px;
  }

  .right-arrow {
    height:10px;
    width: auto;
  }

  .audience-block{
    margin: 18px auto 10px auto;
    border-top: 1px solid #979797;
    padding: 20px 0 30px 0;
    border-bottom: 1px solid #979797;
  }

  .promo-app-store{
    width: 80px;
    height: auto;
    margin-right: 10px;
    margin-bottom: 4px;
  }

  .map-promo {
    padding: 40px 0 50px;
    h2 {
      font-size: 28px;
      margin-bottom: 16px;
    }
  }

  .center-mobile-only{
    text-align: inherit;
  }

  .aflw-promo .absolute{
    padding: 12px 0 10px 0px;
  }

  p.classics-dates {
    margin: 4px 0;
  }

  .aflw-promo img.nab-logo {
    width: 80px;
    right: 10px;
    bottom: 10px;
  }

}

@media all and (min-width: 832px){

  .program-type-block{
    border-top: none;
  }

  .aflw-promo {
    border-top: none;
  }

  .aflw-promo .absolute{
    padding: 24px 0 10px 0px;
  }

  p.classics-hover-text {
    margin: 0 0 12px;
  }

  p.classics-dates {
    margin: 4px 0 8px;
  }

  .home-promo{
    width: 100%;
    position: relative;
    height: 100%;
    border-top: none;
  }

  .promo-app-store{
    width: 100px;
    margin-right: 16px;
    margin-bottom: 10px;
  }

  .aflw-promo img.nab-logo {
    width: 120px;
    right: 10px;
    bottom: 10px;
  }

  .in-text-logo {
    height: 15px;
    vertical-align: middle;
    margin: 0 2px 0 1px;
  }

  .in-text-logo.foxtel {
    height: 16px;
    margin: 0 3px 0 2px;
  }

}

@media all and (min-width: 1024px){

  .nab-logo-link {
    right: 20px;
  }

  .promo-app-store{
    width: 120px;
    margin-right: 16px;
    margin-bottom: 10px;
  }

  .map-promo {
    padding: 60px 0;
    h2 {
      font-size: 42px;
      margin-bottom: 26px;
    }
  }

  p.classics-hover-text {
    margin: 0 0 14px;
  }

  .in-text-logo {
    height: 16px;
    vertical-align: middle;
  }
  .in-text-logo.foxtel {
    height: 18px;
  }

  .aflw-promo img.nab-logo {
    width: 160px;
    right: 16px;
    bottom: 16px;
  }

  .promo-inner-left-text{
    margin-left: 10px;
  }

  .dont-miss-a-moment-lockup {
    margin-left: 4px;
  }
}

@media all and (min-width: 1380px){

  .promo-app-store{
    width: 150px;
    margin-right: 16px;
    margin-bottom: 20px;
  }

  .map-promo {
    padding: 80px 0;
    h2 {
      font-size: 52px;
    }
  }

  .in-text-logo {
    height: 22px;
    margin: 0 3px 0 2px;
  }

  .in-text-logo.foxtel {
    height: 24px;
    margin: 0 4px 0 2px;
  }

  .aflw-promo img.nab-logo {
    width: 210px;
    right: 20px;
    bottom: 20px;
  }

  .promo-inner-left-text{
    margin-left: 20px;
  }
}

@media all and (max-width: 640px) {
  .audience-block.mobile-side-padding{
    margin: 18px 20px;
  }

  .pack-image.align-right {
    float: none;
    width: 100%;
  }

  .body-wrapper-grey{
    background-color: #ffffff; /* we want a white background for mobile */
  }
}

@media all and (max-width: 400px) {


  .nav-items-container {
    padding-top: 8px;
  }
/*   .aflw-promo {
    padding: 10px 10px;
  } */

  .aflw-promo img {

    height: auto;
    position: relative;

  }
  .aflw-promo .nab-logo-link {
    right: 40px;
  }

  .map-results {
    height: 100%;
    overflow-x: scroll;
  }

  .aflw-promo img.nab-logo {
    width: 100px;
    height: auto;
    right: 6px;
    bottom: 6px;
  }

}

.blue{
  color: $blue;
}

.red {
  color: $red;
}
